<div class="modal-header bg-white">
  <h4 class="pull-left line-height-20 font-14 secondary-font color-primary">Broadcast</h4>
</div>
<div class="modal-body event-info-bdy-layout pr-3">
  <div class="container">
    <div class="radio-button-wrapper1 col-lg-12 col-md-12 col-sm-12">
      <p class="">
        Give access to your camera and mic
      </p>
    </div>
  </div>
</div>
