<!-- After Login -->
<app-video-footer-bar *ngIf="(!currentRoute && isLoggedIn$ | async) as isLoggedIn; else beforeLoading">
</app-video-footer-bar>
<!-- End -->

<ng-template #beforeLoading>
  <!-- Before Login -->
  <div class="footer-layout">
    <div class="fotter-group">
      <div class="footerBottom-right">
        <a (click)="cookiesurl()">Cookies</a>
        <a (click)="privacyurl()">Privacy Statement</a>
        <a (click)="termsconditionsurl()">Terms Of Service</a>
        <a (click)="securityurl()">Security</a>
      </div>
      <div class="footerBottom-left">
        <p>Copyright © Discussion Box 2021, All Rights Reserved.</p>
      </div>
      <div class="button-first-logo">
        <ul>
          <li class="social-icons-grup justify-content-center">
            <a target="_blank" href="https://www.facebook.com/discussionboxlive">
              <img src="assets/images/facebook.png" alt="facebook" width="8" height="15" class="img-fluid" />
            </a>
            <a target="_blank" href="https://twitter.com/discussionbox">
              <img src="assets/images/twitter.png" alt="twitter" width="15" height="12" class="img-fluid" />
            </a>
            <a target="_blank" href="https://www.linkedin.com/groups/12209251/">
              <img src="assets/images/linkedin.png" alt="linkedin" width="15" height="15" class="img-fluid" />
            </a>
            <a target="_blank" href="mailto:info@discussionbox.tv;">
              <img src="assets/images/mail.png" alt="mail" width="15" height="12" class="img-fluid" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End -->
</ng-template>
