import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { SideNavDirection } from '../../../../shared/enums';
import { HideShowService } from '../../../../shared/services';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavRightComponent implements OnInit {
  showSideNav: Observable<boolean>;

  @Input() sidenavTemplateRef: any;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = 0;
  @Input() direction: SideNavDirection = SideNavDirection.Right;

  constructor(private hideShowService: HideShowService) { }


  ngOnInit(): void {
    this.showSideNav = this.hideShowService.getShowNav();
  }

  onSidebarClose() {
    this.hideShowService.setShowNav(false);
    this.hideShowService.setNavStyle(false);
  }



  getSideNavBarStyle(showNav: boolean) {
    if (showNav) {
      // this.hideShowService.setShowNav(true);
      // this.hideShowService.setNavStyle(true);
      let navBarStyle: any = {};
      navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
      navBarStyle.width = this.navWidth + 'px';
      navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';
      return navBarStyle;
    } else {
      this.onSidebarClose();
    }
    // let navBarStyle: any = {};
    // navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    // navBarStyle.width = this.navWidth + 'px';
    // navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';
    // return navBarStyle;
  }

}
