import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
export class AddonsComponent implements OnInit {
  @Output() settingsdialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  stepTwo: boolean = false;
  stepone: boolean = true;
  stepthree: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  savestepone() {
    this.stepTwo = true;
    this.stepone = false;
  }

  savesteptwo() {
    this.stepthree = true;
    this.stepTwo = false;
  }

  cancel() {
    this.settingsdialogClose.emit();
  }
  cancelsteptwo() {
    this.stepTwo = false;
  }

}
