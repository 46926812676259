import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';
import { RolesEnums } from 'src/app/core/enums';

@Component({
  selector: 'modal-audio-video-settings-dialog',
  templateUrl: './audio-video-settings-dialog.component.html',
  styleUrls: ['./audio-video-settings-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class AudioVideoSettingsDialogComponent implements OnInit {
  @Output() notifyToRedirect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChildren('audio') audioElms :ElementRef[];
  publisher: OT.Publisher;
  audiooutputs: any;
  audioInputs: any;
  videoinputs: any;
  currentvid: any = '';
  currentProgress: any;
  logLevel: number;
  open: boolean = false;
  isCameraSelected = false;
  isAudioPlaying: boolean = false;
  audio: HTMLAudioElement;
  playButtonText: string;

  constructor(
    public utilityService: UtilityService,
    public generalService: GeneralService,
  ) { }

  ngOnInit() {
    this.audiooutput()
    this.getaudioinput()
    this.getvideosource()
    // this.utilityService.requestCamera(true,true)
  }


  toRedirectToWaitingPage() {
    this.notifyToRedirect.emit();
  }

  //For getting input devices connected to system
  getaudioinput() {
    let currentIndex = 0;
    OT.getDevices((err, devices) => {
      this.audioInputs = devices.filter((device) => device.kind === 'audioInput');
      // Find the right starting index for cycleMicrophone
      this.audioInputs.forEach((device, idx) => {
        if (device.label === this.audioInputs[idx].label) {
          currentIndex = idx;
        }
      });
    });

  }

  //For getting all the camera connected to system
  getvideosource() {
    let currentIndex = 0;
    OT.getDevices((err, devices) => {
      this.videoinputs = devices.filter((device) => device.kind === 'videoInput');
      // Find the right starting index for cycleMicrophone
      this.videoinputs.forEach((device, idx) => {
        if (device.label === this.videoinputs[idx].label) {
          currentIndex = idx;
        }
        this.currentvid = currentIndex
      });
    });
  }


  //Preview on popup
  displaypreview() {
    this.generalService.isUserCredentialsExits$.subscribe((isUserCredentials: any) => {
      if (isUserCredentials && this.utilityService.isObjectNotEmpty(isUserCredentials)) {
        if (isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
          this.utilityService.requestCamera(true, true)
          this.utilityService.joinEventVideoScreen(isUserCredentials, "preview-aud-video1");
          this.isCameraSelected = true;
        }
      }
    });
  }


  //For input devices connected to system
  async audiooutput() {
    this.audiooutputs = await this.utilityService.getaudiooutput()
  }


  setupaudiometer() {
    const audioLevel = document.getElementById('audio-meter');
    const meter = document.getElementById('meter');
    audioLevel.style.display = 'block';
    let movingAvg = null;
    this.utilityService.publisher.on('audioLevelUpdated', (event) => {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      this.logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
      this.logLevel = Math.min(Math.max(this.logLevel, 0), 1);
    });  
  }
  

  onPlayClick(audio: HTMLAudioElement) {
    if (!this.isAudioPlaying) {
     audio.play();
     this.isAudioPlaying = true;
    } else {
     audio.pause();
     this.isAudioPlaying = false;
    }
   }

    onPause() {
     this.isAudioPlaying = false;
    }
    
    onPlay() {
      this.playButtonText = 'Pause';
    }
    onEnded() {
      this.playButtonText = 'Test Speaker';
    }

}
