import { Inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/shared/services";
import { Attachments } from "../enums";
import * as OT from '@opentok/client';
import * as $ from 'jquery';
import { UserVideoScreenComponent } from "src/app/components/pages/connected-users/user-video-screen/user-video-screen.component";
import { DOCUMENT } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CoreHttpService } from "../services";
import * as _ from 'lodash';
import Swal from 'sweetalert2';


import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class UtilityService {

  node2: any;
  session: OT.Session;
  connectionIdForOthersUsers: string;
  CONNECT: string;
  broadcast: any = { status: 'waiting', streams: 1, rtmp: false };
  boxid: any = 1;
  val = 100;
  broadCastStartdate?: Date | any;
  insertOptions = {
    width: '100%',
    height: '100%',
    showControls: true,
    buttonDisplayMode: true,
    audioVolume: this.val,
    style: { nameDisplayMode: "off" }
  };
  subscriptionData: any;
  totalViewer: number = 0;
  count: number = 0;
  menus: HTMLCollectionOf<HTMLElement>;
  newmenus: HTMLCollectionOf<HTMLElement>
  isScreenShare: boolean;
  screenshare: boolean = false;
  IsHorizontal: boolean;
  layoutType: any;
  IDD: any;
  publisher: OT.Publisher;
  IsChat: boolean = false;
  streamMessageList: any[] = [];
  userVideoScreenComponent: UserVideoScreenComponent;
  element: HTMLElement;
  event: any;
  totaltimediferrence: any;
  isonLine: boolean = false;
  data: any;
  audioOutputs: any;
  IsfullScreen: boolean = false;
  public messageList$: BehaviorSubject<any> = new BehaviorSubject(null);


  // role: string = this.generalService.isRole;
  node4: any;
  adhocSpeaker: boolean = false;
  availableClasses: string[] = ["user_1", "user_2", "user_3", "user_4", "user_5", "user_6", "user_7", "user_8", "user_9"];
  currentClassIdx: number = 0;
  bodyClass: string;
  coreEventUrl = `${environment.apiUrl}Event`;
  node: HTMLElement;
  coreUrl = `${environment.apiUrl}Video`;



  constructor(
    private router: Router,
    public generalService: GeneralService,
    @Inject(DOCUMENT) private doc: any,
    private coreHttpService: CoreHttpService,
    public activeRoute: ActivatedRoute,
  ) { }

  scrollToTop() {
    // window.scroll(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  convertDateToNumberFormat(d) {
    const formattedTime =
      (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) +
      "" +
      (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());
    return +formattedTime;
  }

  timeToObject(startTime, endTime) {
    return {
      open: this.convertDateToNumberFormat(startTime),
      close: this.convertDateToNumberFormat(endTime)
    };
  }

  convertHMS(value: any) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
  }

  convertNumberToDateFormat(time_in_number) {
    const finalTime = new Date();
    const hourAndMinute = time_in_number.toString().match(/.{1,2}/g);
    finalTime.setHours(+hourAndMinute[0]);
    finalTime.setMinutes(+hourAndMinute[1]);
    return finalTime;
  }

  arrayOfStringsToArrayOfObjects(arr: any[]) {
    const newArray = [];
    arr.forEach(element => {
      newArray.push({
        label: element,
        value: element
      });
    });
    return newArray;
  }

  arrayOfObjectToArrayOfStrings(obj: []) {
    const newArray = [];
    obj.forEach(element => {
      newArray.push(element["value"]);
    });
    return newArray;
  }

  checkAndParseToNumberIfString(str) {
    if (typeof str === "string") {
      return this.stringToNumber(str);
    } else {
      return str;
    }
  }
  stringToNumber(str: string) {
    return +str;
  }

  validateEmail(controls) {
    const regExp = new RegExp(
      // tslint:disable-next-line: max-line-length
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (regExp.test(controls.value)) {
      return null;
    } else {
      return { validateEmail: true };
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  numberOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && charCode !== 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkLengthOfEveryArrayInObject(obj) {
    if (obj) {
      for (const val of Object.values(obj)) {
        if (val["length"] === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  openLinkInSameTab(link: string) {
    let url = "";
    if (!/^http[s]?:\/\//.test(link)) {
      url += "http://";
    }
    url += link;
    window.location.href = url;
    // window.open(url, "_blank");
  }

  openLinkInNewTab(url: string) {
    window.open(url, "_blank");
  }

  isObjectEmpty(obj: any) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  isObjectNotEmpty(obj: any) {
    return Object.entries(obj).length > 0 && obj.constructor === Object;
  }

  pickPartialObject(properties: string[], objectToFilter) {
    const partailObject = properties.reduce((o, k) => {
      o[k] = objectToFilter[k];
      return o;
    }, {});

    return partailObject;
  }

  // generate 4 digit randome number
  getRandomId() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  async getAttachments(attachments: string, imageUrl: string) {
    var promise = new Promise((resolve, reject) => {
      let url: string = '';
      if (attachments === Attachments.Standard || attachments === Attachments.Thumbnail || attachments === Attachments.Icon) {
        url = imageUrl ? imageUrl.replace(Attachments.Files, attachments) : '';
      } else {
        url = imageUrl;
      }
      resolve(url);
    });
    return promise;
  }

  /**=========================================== OT functions ===========================================*/

  async requestCamera(isAudio: any, isVideo: any) {
    var constraints: any = {
      audio: isAudio,
      video: isVideo
    }
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      /* use the stream */
      console.log("getUserMedia", stream);
    }).catch(function (err) {
      /* handle the error */
      var error = console.log("getUserMedia err", err);
      if (isAudio == true || isVideo == true) {
        Swal.fire({
          title: 'Camera and microphone are blocked',
          // text:`Discussion Box requires access to your camera and microphone. Click the camera blocked icon <img src="${'assets/images/blocked-camera-icon.svg'}">  in your browsers address bar.`,
          html: `Discussion Box requires access to your camera and microphone. Click the camera blocked icon <img src="${'assets/images/blocked-camera-icon.svg'}">  in your browsers address bar.`
        })
      }
    })
  }


  joinEventVideoScreen(credentials: { ApiKey: string; SessionId: string; }, BoxID: string) {
    this.session = OT.initSession(credentials.ApiKey, credentials.SessionId);
    // var BoxID = "joinspeaker-layout";
    this.initPublisherForOthers(BoxID);
  };

  initPublisherForOthers = (BoxId: string) => {
    let insertOptions = {
      width: '100%',
      height: '100%',
      // showControls: true,
      style: { nameDisplayMode: "off", buttonDisplayMode: 'off' },
    };
    let element = BoxId;
    this.IDD = element
    var properties: any = Object.assign({ name: element, insertMode: 'append', }, insertOptions);
    this.publisher = OT.initPublisher(element, properties);
    return this.publisher;
  };

  setHeight(element: HTMLElement, count: number) {
    this.menus = document.getElementsByClassName('grid') as HTMLCollectionOf<HTMLElement>;
    if (this.isScreenShare == false) {
      if (!!this.menus) {
        if (this.count < 2) {
          this.menus[0].style.width = "100%";
          this.menus[0].style.height = '100%';
          $(this.menus[1]).addClass("box-profile");
        } else if (this.count == 2) {
          this.menus[1].style.width = "100%";
          this.menus[1].style.height = '100%';
          $(this.menus[0]).addClass("box-profile");
        } else {
          $(this.menus[0]).removeClass("box-profile");
          let i;
          for (i = 0; i < this.menus.length; i++) {
            if (this.count == 3) {
              this.menus[i].style.width = ((100 / 3) - 0.3) + '%';
            } else if (this.count == 4) {
              this.menus[i].style.width = ((100 / 2) - 0.3) + '%';
            } else if (this.count <= 6 && this.count > 4) {
              this.menus[i].style.width = ((100 / 3) - 0.3) + '%';
            } else if (this.count <= 8 && this.count > 6) {
              this.menus[i].style.width = ((100 / 4) - 0.3) + '%';
            } else if (this.count > 8) {
              this.menus[i].style.width = ((100 / 5) - 0.3) + '%';
            }
            if (this.count <= 3) {
              this.menus[i].style.height = '100%';
            } else if (this.count > 3 && this.count <= 10) {
              this.menus[i].style.height = (100 / 2) + '%';
            } else if (this.count > 10 && this.count <= 15) {
              this.menus[i].style.height = (100 / 3) + '%';
            } else if (this.count > 15) {
              this.menus[i].style.height = (100 / 3.5) + '%';
            }
          }
        }

      }
    } else {
      const grid = document.getElementById('grid');
      let i;
      $(this.menus[0]).removeClass("box-profile");
      for (i = 0; i < this.menus.length; i++) {
        if (this.IsHorizontal == true) {
          this.menus[i].style.width = "300px";
          this.menus[i].style.height = "100%";
        } else {
          this.menus[i].style.width = "300px";
          this.menus[i].style.height = "20%";
          this.menus[i].style.display = "flex";
        }

      }
    }

    if (!!this.layoutType && this.count > 3) {
      this.layouts(this.layoutType);
    }
  }

  layouts(event: any) {
    if (!!this.menus) {
      switch (event) {
        case 'single': {
          if (this.count == 2) {
            this.menus[1].style.width = "100%";
            this.menus[1].style.height = '100%';
            $(this.menus[0]).addClass("float-user1");
            this.layoutType = event;
          }
          break;
        }
        case 'doubleHor': {
          if (this.count == 2) {
            this.menus[1].style.width = "100%";
            this.menus[1].style.height = '50%';
            this.menus[0].style.width = "100%";
            this.menus[0].style.height = '50%';
            $(this.menus[0]).removeClass("float-user1");
            $('.grid-item').css("display", "list-item");
            this.layoutType = event;
          }
          break;
        }
        case 'fourLayout': {
          let i;
          $('.grid-item').css("display", "inline-block");
          for (i = 0; i < this.menus.length; i++) {
            this.menus[0].style.float = "unset";
            this.menus[i].style.float = "unset";
            if (this.count <= 4) {
              this.menus[i].style.width = ((100 / 2) - 0.3) + '%';
            } else if (this.count <= 6 && this.count > 4) {
              this.menus[i].style.width = ((100 / 3) - 0.3) + '%';
            } else if (this.count <= 8 && this.count > 6) {
              this.menus[i].style.width = ((100 / 4) - 0.3) + '%';
            } else if (this.count > 8) {
              this.menus[i].style.width = ((100 / 5) - 0.3) + '%';
            }
            if (this.count <= 2) {
              this.menus[i].style.height = '100%';
            } else if (this.count > 2 && this.count <= 10) {
              this.menus[i].style.height = (100 / 2) + '%';
            } else if (this.count > 10 && this.count <= 15) {
              this.menus[i].style.height = (100 / 3) + '%';
            } else if (this.count > 15) {
              this.menus[i].style.height = (100 / 3.5) + '%';
            }
          }
          this.layoutType = event;
          break;
        }
        case 'doubleVer': {
          $('.grid-item').css("display", "inline-block");
          if (this.count == 2) {
            this.menus[1].style.width = "49.5%";
            this.menus[1].style.height = '100%';
            this.menus[0].style.width = "49.5%";
            this.menus[0].style.height = '100%';
            $(this.menus[0]).removeClass('float-user1');
            this.layoutType = event;
          }
          break;
        }
        case 'fivehor': {
          $('.grid-item').css("display", "inline-block");
          for (let i = 1; i < this.menus.length; i++) {
            this.menus[0].style.float = "unset";
            this.menus[i].style.float = "unset";
            if (this.count <= 4) {
              this.menus[i].style.width = ((100 / 3) - 0.3) + '%';
            } else if (this.count <= 6 && this.count > 4) {
              this.menus[i].style.width = ((100 / 4) - 0.3) + '%';
            } else if (this.count <= 8 && this.count > 6) {
              this.menus[i].style.width = ((100 / 5) - 0.3) + '%';
            } else if (this.count > 8) {
              this.menus[i].style.width = ((100 / 6) - 0.3) + '%';
            }
            if (this.count <= 2) {
              this.menus[i].style.height = '100%';
            } else if (this.count > 2 && this.count <= 10) {
              this.menus[i].style.height = (100 / 4) + '%';
            } else if (this.count > 10 && this.count <= 15) {
              this.menus[i].style.height = (100 / 4) + '%';
            } else if (this.count > 15) {
              this.menus[i].style.height = (100 / 4) + '%';
            }
          }
          this.menus[0].style.width = "100%";
          this.menus[0].style.height = '80%';
          this.layoutType = event;
          break;
        }
        case 'fivever': {
          $('.grid-item').css("display", "inline-block");
          for (let i = 1; i < this.menus.length; i++) {
            this.menus[i].style.float = "right";
            if (this.count <= 4) {
              this.menus[i].style.width = ((100 / 6.25)) + '%';
            } else if (this.count <= 6 && this.count > 4) {
              this.menus[i].style.width = ((100 / 6.25)) + '%';
            } else if (this.count <= 8 && this.count > 6) {
              this.menus[i].style.width = ((100 / 6.25)) + '%';
            } else if (this.count > 8) {
              this.menus[i].style.width = ((100 / 6.25)) + '%';
            }
            if (this.count <= 2) {
              this.menus[i].style.height = '100%';
            } else if (this.count > 2 && this.count <= 10) {
              this.menus[i].style.height = (100 / 2.5) + '%';
            } else if (this.count > 10 && this.count <= 15) {
              this.menus[i].style.height = (100 / 3) + '%';
            } else if (this.count > 15) {
              this.menus[i].style.height = (100 / 3) + '%';
            }
          }
          this.menus[0].style.width = "50%";
          this.menus[0].style.height = '100%';
          this.menus[0].style.float = "right";
          this.layoutType = event;
          break;
        }
        default: {
          this.layoutType = 'single';
          break;
        }
      }
    }
  }

  // To place Video and Audio Button , Mike , Sound
  addPublisherControls = (publisher: OT.Publisher) => {
    var publisherContainer = document.getElementById(publisher.element.id);
    var el = document.createElement('div');
    var controls = [
      `<div class="publisher-controls-container">`,
      '<div id="publishVideo" class="control video-control"></div>',
      '<div id="publishAudio" class="control audio-control"></div>',
      '</div>',
    ].join('\n');
    el.innerHTML = controls;
    publisherContainer.appendChild(el.firstChild);
  };

  messagePublishAndSubscribe = (session, publisher) => {
    session.on('signal:msg', (event) => {
      console.log("=-=-=-=-", event);
      this.IsChat = true;
      var message = event.data;
      var messageData = JSON.parse(message);
      messageData.date = event.from.creationTime;
      // this.streamMessageList.push(messageData);
      this.messageList$.next(messageData);
    });
  };

  async innerHTMLForAllBoxes(BoxID: any) {
    if (BoxID == 'appDragg-Box') {
      this.subscribeeventadhoc(BoxID);
    } else {
      var promise = new Promise(async (resolve, reject) => {
        await this.getEventSpeakers(this.generalService.isEventID).subscribe(res => {
          var Speakers = res;
          this.setUsersDetails(Speakers, BoxID);
        });
        const node1 = document.createElement('div');
        $(node1).addClass("box-profile");
        $(node1).attr("id", `box-profile_${BoxID}`);
        document.getElementById(`grid`).appendChild(node1);
        this.node2 = document.createElement('div');
        let Role = null;
        if (this.generalService.isRole === 'speaker') {
          Role = 'Speaker';
        } else if (this.generalService.isRole === 'moderator') {
          Role = 'Moderator';
        } else if (this.generalService.isRole === 'audience') {
          Role = 'Audience';
        } else if (this.generalService.isRole === 'admin') {
          Role = 'Admin';
        }

        // const node2 = document.createElement('div');
        // $(node2).addClass("d-flex justify-content-between align-items-center top-box-layout");
        // node2.innerHTML = `
        // <div class="box-tooltip">
        //   <a href="#" class="text-decoration-none" data-toggle="tooltip" title="Moderator">
        //     <span><img src="assets/images/m-icon.svg" alt="Icon" class="img-fluid"></span>
        //   </a>
        // </div>
        // <div class="dropdown-layout user-layout-drp">
        //     <div class="dropdown">
        //       <span><img src="assets/images/menu-white.svg" alt="Menu" class="rounded-0" /></span>
        //       <div class="dropdown-content">
        //         <p ngbDropdownItem>Unmute Audio
        //           <img src="assets/images/micro-black.png" alt="video" class="ml-3 img-fluid w-auto" />
        //         </p>
        //         <p ngbDropdownItem>Unmute Video
        //           <img src="assets/images/video-back.png" alt="video" class="ml-3 img-fluid w-auto" />
        //         </p>
        //         <p ngbDropdownItem>Make Moderator</p>
        //         <p ngbDropdownItem style="color: #F44336;">Remove Speaker</p>
        //       </div>
        //     </div>
        //   </div>`;

        const node3 = document.createElement('div');
        $(node3).addClass("customer-profile-img");
        $(node3).attr("id", `${BoxID}`);
        document.getElementById(`box-profile_${BoxID}`).appendChild(node3);


        // this.subscriptionData.subscribeToVideo(true);
        const node5 = document.createElement('div');
        $(node5).addClass("image-profile");
        $(node5).attr("id", `image-profile_box-profile_${BoxID}`);
        node5.style.display = 'none';

        document.getElementById(`${BoxID}`).appendChild(node5);

        // let idforwidget=document.getElementsByClassName('OT_widget-container');
        // $(idforwidget).attr('id','OT_widget-container');
        // document.getElementById(`OT_widget-container`).appendChild(node5)

        // const node4 = document.createElement('div');
        // $(node4).addClass("custom-user-caption pb-2 pt-2 p-4 d-flex justify-content-between align-items-center");
        // node4.innerHTML = `
        // <div>
        //   <p class="font-14 grotesk-medium color-white mb-0">Sam Smith</p>
        //   <p class="font-12 gibson-book-font color-white mb-0">Marketing Manager</p>
        //   <p class="font-12 gibson-book-font color-white mb-0">Apple Co.</p>
        // </div>
        // <div><img src="assets/images/logo-user.png" class="img-fluid" alt="logo"></div>`;

        // document.getElementById(`box-profile_${BoxID}`).appendChild(node2);
        // document.getElementById(`box-profile_${BoxID}`).appendChild(node4);

        this.bodyClass = this.availableClasses[this.currentClassIdx];
        if ($('#grid').hasClass(this.bodyClass)) {
          await this.changeBodyClass();
        } else {
          $('#grid').addClass(this.bodyClass);
        }
        resolve(document.getElementById('grid'));
      });
    }


    return promise;
  }

  async changeBodyClass() {
    var promise = new Promise((resolve, reject) => {
      // get html body element
      const bodyElement = document.getElementById('grid');

      if (bodyElement) {
        this.currentClassIdx = this.getNextClassIdx();
        const nextClass = this.availableClasses[this.currentClassIdx];
        const activeClass = this.availableClasses[this.getPrevClassIdx()];

        // remove existing class (needed if theme is being changed)
        bodyElement.classList.remove(activeClass);
        // add next theme class
        bodyElement.classList.add(nextClass);

        this.bodyClass = nextClass;

        $('#grid').removeClass(activeClass);
        $('#grid').addClass(this.bodyClass);
        resolve('');
      }

    });
    return promise;
  }

  async changeBoxesAfterLeaveEvent() {
    var promise = new Promise((resolve, reject) => {
      const bodyelement = document.getElementById('grid');

      if (bodyelement) {
        this.currentClassIdx = this.getPrevClassIdx();
        const nextClass = this.availableClasses[this.currentClassIdx];
        const activeClass = this.availableClasses[this.getNextClassIdx()];

        bodyelement.classList.remove(nextClass);
        this.bodyClass = nextClass;

        $('#grid').removeClass(activeClass);
        $('#grid').addClass(this.bodyClass);
        resolve('');
      }
    })
  }

  getPrevClassIdx(): number {
    return this.currentClassIdx === 0 ? this.availableClasses.length - 1 : this.currentClassIdx - 1;
  }

  getNextClassIdx(): number {
    return this.currentClassIdx === this.availableClasses.length - 1 ? 0 : this.currentClassIdx + 1;
  }

  async subscribeEvent(session: any, stream: any, isStart: any) {
    if (isStart == true) {
      // Generate Dynamic ID

      var BoxID = stream.videoType === 'screen' ? 'screen-preview' : stream.name;
      let grid: any;
      if (stream.videoType === 'screen') {
        this.screenshare = true;
        this.IsHorizontal = true;
        grid = document.getElementById('grid');
        // grid.style.position = "absolute";
        grid.style.height = "100%";
        grid.style.overflow = "hidden";
        grid.style.display = "flex";
        $(grid).addClass('screenlayout');
        // const node = document.getElementsByClassName('streams');
        // $(node).addClass("screen-pos");
        let node1 = document.createElement('div');
        $(node1).addClass('screen-share');
        $(node1).attr('id', 'screen-share');
        document.getElementById(`pgrid`).appendChild(node1);
        let node2 = document.createElement('div');
        $(node2).addClass('screen-preview');
        $(node2).attr('id', 'screen-preview');
        document.getElementById(`screen-share`).appendChild(node2);

        const screenShare = document.getElementsByClassName('screen-share');
        $(screenShare).addClass("hor-layout");
        $(this.menus[0]).removeClass("float-user1");
        let i;
        for (i = 0; i < this.menus.length; i++) {
          this.menus[i].style.width = "300px";
          this.menus[i].style.height = "100%";
        }
      } else {
        if (BoxID == "appDragg-Box") {
          this.subscribeeventadhoc(BoxID);
        } else {
          this.count++;
          grid = await this.innerHTMLForAllBoxes(BoxID);
        }

      }

      var properties = Object.assign({ name: BoxID, insertMode: 'append' }, this.insertOptions);
      this.setHeight(grid, this.count);
      this.subscriptionData = session.subscribe(stream, BoxID, properties, (error) => {
        if (error) { }
        // this.val = this.subscriptionData.getAudioVolume();
      });
      if (stream.videoType === 'screen') {
        $('#screen-preview .OT_subscriber').width($('#screen-preview').width());
        $('#screen-preview .OT_subscriber').height($('#screen-preview').height());
        // console.log(   $('.OT_subscriber').width($('#screen-preview').width()));
      }
    } else {
      var streamname = stream.name;
      if (stream.videoType === 'screen') {
        this.isScreenShare = false;
        this.screenshare = false;
        const grid = document.getElementById('grid');
        // $(grid).removeAttr('style');
        const node = document.getElementsByClassName('streams');
        const screenShare = document.getElementsByClassName('screen-share');
        $(grid).removeClass('screenlayout');
        $(screenShare).removeClass('screen-share');
        $(screenShare).removeClass("hor-layout");
        $(screenShare).removeClass("ver-layout");
        $(node).removeClass("vertical-pos");
        this.setHeight(grid, this.count);
        this.setHeight(document.getElementById('grid'), this.count);
      } else {
        this.bodyClass = this.availableClasses[this.currentClassIdx];
        var children = document.getElementById("grid").children;
        for (var i = 0; i < children.length; i++) {
          if (children[i].innerHTML.includes(streamname)) {
            console.log("Yes")
            document.getElementById('grid').removeChild(children[i]);
            this.count--;
            this.changeBoxesAfterLeaveEvent();
            this.setHeight(document.getElementById('grid'), this.count);
          }
        }
      }
    }
  };

  sendSignalToSpeaker(session: OT.Session) {
    session.signal({ data: "sessionStop", type: "sessionStoped" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    }
    );
  }

  broadCastStartSignalForSM(session: OT.Session) {
    session.signal({ data: "Broadcast Started", type: "startedBroadcast" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("Broadcast Started signal sent.");
      }
    }
    );
  }

  stopbroadcast(session: OT.Session) {
    session.signal({ data: "Broadcast Ended", type: "endedBroadcast" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("Broadcast Ended signal sent.");
      }
    }
    );
  }


  signal = (session: OT.Session, status: string, to?: any) => {
    var signalData = Object.assign({}, { type: 'broadcast', data: status }, to ? { to } : {});
    session.signal(signalData, (error) => {
      if (error) {
        // console.log(['signal error (', error.code, '): ', error.message].join(''));
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log('signal sent');
      }
    });
  };

  //set user details of speaker/moderator
  async setUsersDetails(Speakers, BoxID?: any) {
    let sessiondata: any = await this.getSessionConnection()
    var publisherprop = this.publisher;
    var suberdara = this.subscriptionData;
    if (suberdara) {

      let x = suberdara.element.classList
      x.forEach(element => {
        if (element == 'OT_audio-only') {

          let x = document.getElementById(`image-profile_box-profile_${BoxID}`);
          // x.style.display = 'block';
        }
      });
    }
    if (BoxID == 'appDragg-Box') {
      this.subscribeeventadhoc(BoxID);
    } else {
      let getRole = this.generalService.isRole;
      $('.box-profile').each(function (i, obj) {
        var boxid = obj.id;
        var speakers = Speakers;
        if (boxid != '') { var connId = boxid.split("User_")[1]; }
        let userDetails = Speakers.find(element => element.id == connId);
        const node2 = document.createElement('div');
        if (getRole == 'admin' || getRole == 'moderator') {
          $(node2).addClass("d-flex justify-content-between align-items-center top-box-layout md-label");
          node2.innerHTML = `
          <div class="dropdown-layout user-layout-drp md-label22">
          <div class="dropdown">
            <span><img src="assets/images/menu-white.svg" alt="Menu" class="rounded-0" /></span>
            <div class="dropdown-content">
              <p ngbDropdownItem class="makeModerator" id="makemoderator_${boxid}" [value]="${boxid}">Make Moderator</p>
              <p ngbDropdownItem class="removespeaker" id="removespeaker_${boxid}"  style="color: #F44336;">Remove Speaker</p>
            </div>
          </div>
        </div>`;
        }
        //   <p ngbDropdownItem class="unmuteaudio" id="unmuteaudio_${boxid}" [value]="${boxid}">Unmute Audio
        //   <img src="assets/images/micro-black.svg" alt="video" class="ml-3 img-fluid w-auto" />
        // </p>
        // <p ngbDropdownItem class="unmutevideo" id="unmutevideo_${boxid}" [value]="${boxid}">Unmute Video
        //   <img src="assets/images/video-back.svg" alt="video" class="ml-3 img-fluid w-auto" />
        // </p>
        let x = document.getElementById(`image-profile_${boxid}`);
        x.innerHTML = `
        <div>
        <div class="speaker-profile-img"><img src="${userDetails.Profile ? userDetails.Profile : 'assets/images/default-user.png'}" class="img-fluid" alt="logo"></div>
        </div>
        <div>
          <p class="font-14 grotesk-medium color-white mb-0 text-center">${userDetails.firstname + " " + userDetails.lastname}</p>
          <p class="font-12 gibson-book-font color-white mb-0 text-center">${userDetails.JobTitle}</p>
          <p class="font-12 gibson-book-font color-white mb-0 text-center">${userDetails.companyname}</p>
        </div>`
        //to get video off of another user
        // let x = document.getElementsByClassName('OT_video-poster');
        // $(x).removeClass();
        //
        // if (publisherprop.stream.hasVideo) {
        //   let x = document.getElementById(`image-profile_${boxid}`);
        //   x.style.display = 'block';
        // }

        const node3 = document.createElement('div')
        if (userDetails.type == 2 || userDetails.Type == 2) {
          $(node3).addClass("d-flex justify-content-between align-items-center top-box-layout");
          node3.innerHTML = `
            <div class="box-tooltip">
            <a class="text-decoration-none" data-toggle="tooltip" title="Moderator">
              <span><img src="assets/images/m-icon.svg" alt="Icon" class="img-fluid"></span>
            </a>
          </div>`;
        }

        const node4 = document.createElement('div');
        $(node4).addClass("custom-user-caption  pb-2 pt-2 p-4 d-flex justify-content-between align-items-center");
        $(node4).attr("id", 'custom-user-caption');
        node4.innerHTML = `
            <div>
              <p class="font-14 grotesk-medium color-white mb-0">${userDetails.firstname + " " + userDetails.lastname}</p>
              <p class="font-12 gibson-book-font color-white mb-0">${userDetails.JobTitle}</p>
              <p class="font-12 gibson-book-font color-white mb-0">${userDetails.companyname}</p>
            </div>
            <div>
              <div class="speaker-logo-img">
                <img src="${userDetails && userDetails.Company_Logo ? userDetails.Company_Logo : 'assets/images/default-company.png'}" class="img-fluid" alt="logo">
              </div>
            </div>`;
        //
        // let x = document.getElementsByClassName('OT_video-poster');
        // $(x).attr("id", `abc_${boxid}`);
        // let y = document.getElementById(`abc_${boxid}`);
        // if (!publisherprop.stream.hasVideo) {
        //   let node = document.createElement('div');
        //   node.style.background = '#252525';
        //   node.style.position = 'absolute';
        //   $(node).addClass('image-details')
        //   node.innerHTML = `
        //   <div>
        //     <p class="font-14 grotesk-medium color-white mb-0">'Sam Smith'</p>
        //     <p class="font-12 gibson-book-font color-white mb-0">'Google'</p>
        //     <p class="font-12 gibson-book-font color-white mb-0">'ABC</p>
        //   </div>
        //   `
        //   document.getElementById(`abc_${boxid}`).appendChild(node);
        // }


        // let x = document.getElementsByClassName('OT_video-poster');
        // $(x).attr("id", `abc_${boxid}`);
        // let node5=document.createElement('div');
        // $(node5).addClass('image-details');
        // $(x).attr("id", `image_${boxid}`);
        // let x = document.getElementsByClassName('OT_video-poster');
        // $(x).attr("id", `abc_${boxid}`);


        document.getElementById(`${boxid}`).appendChild(node2);
        document.getElementById(`${boxid}`).appendChild(node3);
        document.getElementById(`${boxid}`).appendChild(node4);
        // document.getElementById(`abc_${boxid}`).appendChild(node5);

        //Three Dots menu
        $('.makeModerator').click(function (event) {
          let makeModeratorBoxId = event.delegateTarget.id;
          let makeModeratorUserId = makeModeratorBoxId.split('User_')[1];
          if (connId == makeModeratorUserId) {
            BecomespeakerToModerator(sessiondata, boxid)
            return false;
          }
        });

        $('.unmutevideo').click(function (event) {
          let unmuteVideoBoxId = event.delegateTarget.id;
          let unmuteVideoUserId = unmuteVideoBoxId.split('User_')[1];
          if (connId == unmuteVideoUserId) {
            muteVideo(sessiondata, boxid);
            return false;
          }
        });
        $('.removespeaker').click(function (event) {
          let unmuteAudioBoxId = event.delegateTarget.id;
          let unmuteAudioUserId = unmuteAudioBoxId.split('User_')[1];
          if (connId == unmuteAudioUserId) {
            remove(sessiondata, boxid);
            return false;
          }
        });
        $('.unmuteaudio').click(function (event) {
          let unmuteAudioBoxId = event.delegateTarget.id;
          let unmuteAudioUserId = unmuteAudioBoxId.split('User_')[1];
          if (connId == unmuteAudioUserId) {
            muteAudio(sessiondata, boxid);
            return false;
          }
        });
      });
      setTimeout(() => {
        let x = document.getElementById('custom-user-caption');
        // x.setAttribute("style", "display: none !important");
        // $(x).addClass('custom-user-caption').removeClass('custom-user-caption');
      }, 1000);
    }
  }
  //Get all speakers
  getEventSpeakers(EventID) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.coreEventUrl + `/GetEventSpeakers?` + 'eventID=' + EventID;
    return this.coreHttpService.httpGetRequest(url, headers);
  }
  sendNetworkRequestSignal(session: OT.Session, audience: any) {
    var signalData = Object.assign({}, { data: audience, type: "networkRequest" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log(['signal error (', error.name, '): ', error.message].join(''));
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Screenshare Signal
  sendSignalToScreenshare(session: OT.Session, userdetails: any) {
    var signalData: any = Object.assign({}, { data: userdetails, type: "screenshare" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }
  //Permissionsignal accept/decline Admin
  acceptscreensharesignal(session: OT.Session, userId) {
    session.signal({ type: 'acceptscreenshare', data: userId }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

  sendSignalForScreenshareDecline(session: OT.Session, userId: any) {
    session.signal({ type: 'declineScreenshare', data: userId }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }
  /**
   * Get Users credentials (comman function) to get session connection
  */
  // getSessionConnection(): any {
  //   var promise = new Promise((resolve, reject) => {
  //     this.generalService.isUserCredentialsExits$.subscribe(async (isUserCredentials: any) => {
  //       if (isUserCredentials && this.isObjectNotEmpty(isUserCredentials)) {
  //         if (this.session === undefined) {
  //           this.session = OT.initSession(isUserCredentials.ApiKey, isUserCredentials.SessionId);
  //           this.session.connect(isUserCredentials.Token, async (error) => {
  //             if (error) {
  //               if (error.name === "OT_NOT_CONNECTED") {
  //                 alert("You are not connected to the internet. Check your network connection.");
  //               }
  //             } else {
  //               resolve(this.session);
  //             }
  //           });
  //         } else {
  //           resolve(this.session);
  //         }
  //       }
  //     });
  //   });
  //   return promise;
  // }

  //Get session connection
  getSessionConnection() {
    var promise = new Promise((resolve, reject) => {
      this.generalService.isUserCredentialsExits$.subscribe(async (isUserCredentials: any) => {
        if (isUserCredentials && this.isObjectNotEmpty(isUserCredentials)) {
          this.session = OT.initSession(isUserCredentials.ApiKey, isUserCredentials.SessionId);
        }
      });
      resolve(this.session);
    });
    return promise;
  }

  // questions sent by audience to admin
  sendSignalToHost(session: OT.Session) {
    session.signal({ data: "audienceAskQuestion", type: "question" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //User details
  async sendSignalForUserDetails(session: OT.Session, uDetails: any) {
    // let okData = { st: stream, ud: uDetails };
    var signalData: any = Object.assign({}, { data: uDetails, type: "userDetailsSignal" });
    session.signal(signalData, async (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //New poll creation
  sendsignalofPollCreateOrDelete(session: OT.Session, polldetails: any, pollCount, UserId) {
    var signalData: any = Object.assign({}, { data: { polldetails, UserId, pollCount }, type: "pollCreatedSignal" });
    session.signal(signalData, async (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Edit Poll Signal
  sendsignalofPollEdit(session: OT.Session, polldetails: any, pollCount) {
    var signalData: any = Object.assign({}, { data: { polldetails, pollCount }, type: "pollEditedSignal" });
    session.signal(signalData, async (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Settings data
  sendsignalofSettingdata(session: OT.Session, settingDetails: any) {
    var signalData: any = Object.assign({}, { data: settingDetails, type: "settingsData" });
    session.signal(signalData, async (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Remove speaker from session by admin
  sendSignalToRemove(session: OT.Session, boxId: any) {
    var signalData: any = Object.assign({}, { data: boxId, type: "removespeaker" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

  //audience user details
  createSignalForAudienceUserDetails(session: OT.Session, uDetails: any) {
    var signalData: any = Object.assign({}, { data: uDetails, type: "audienceDetailsSignal" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Signal sent to be a speaker
  sendSignalTobeSpeaker(session: OT.Session, uDetails: any) {
    var signalData: any = Object.assign({}, { data: uDetails, type: "bespeaker" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

  //Signal sent to Audience to be a speaker
  sendSignalForAudienceToSpeaker(session: OT.Session, audiencedetails: any) {
    var signalData: any = Object.assign({}, { data: audiencedetails, type: "adhocSpeaker" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

  //audience user details
  audienceLeftEvent(session: OT.Session, audDetails: any) {
    var signalData: any = Object.assign({}, { data: audDetails, type: "leaveAudienceSignal" });
    session.signal(signalData, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //Signal sent to Audience to be a speaker
  sendSignalForAdhocSpeakerLeft(session: OT.Session) {
    session.signal({ data: "adhocSpeakerLeft", type: "adhocSpeakerLeft" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    });
  }

  //For getting output devices connected to system
  async getaudiooutput() {
    var promise = new Promise(async (resolve, reject) => {
      let audioOutputs = await OT.getAudioOutputDevices();
      resolve(audioOutputs)
    })
    return promise;
  }


  //screenshare function for speaker side
  async screenShare() {
    this.screenshare = true;
    let role = this.generalService.isRole;
    var session: any = await this.getSessionConnection();
    if (this.screenshare) {
      this.IsHorizontal = true;
      this.screenshare = true;
      const users = document.getElementById('grid');
      $(users).addClass('screenlayout');
      let node1 = document.createElement('div');
      $(node1).addClass('screen-share');
      $(node1).attr('id', 'screen-share');
      document.getElementById(`pgrid`).appendChild(node1);
      let node2 = document.createElement('div');
      $(node2).addClass('screen-preview');
      $(node2).attr('id', 'screen-preview');
      document.getElementById(`screen-share`).appendChild(node2);
      // users.style.position = "absolute";
      users.style.height = "100%";
      users.style.width = "100%";
      users.style.overflow = "hidden";
      users.style.display = "flex";
      // const node = document.getElementsByClassName('streams');
      // $(node).addClass("screen-pos");
      const screenShare = document.getElementsByClassName('screen-share');
      $(screenShare).addClass("hor-layout");
      $(screenShare).removeClass("float-user1");
      let i;
      for (i = 0; i < this.menus.length; i++) {
        this.menus[i].style.width = "100%";
        this.menus[i].style.height = "100%";
        //  this.menus[i].style.height = "20%";
      }
      OT.checkScreenSharingCapability(function (response) {
        if (!response.supported || response.extensionRegistered === false) {
          alert('This browser does not support screen sharing.');
        } else if (response.extensionInstalled === false) {
          alert('Please! Prompt to install the extension.');
        } else {
          // Screen sharing is available. Publish the screen.
          var publishOptions: any = {
            name: 'Screen',
            mirror: false,
            audioSource: null,
            videoSource: 'screen',
            maxResolution: { width: 1280, height: 720 }, // max resolution to encode screen in
            width: 1280, // width of preview
            height: 720, // height of preview
          };
          // publishOptions.maxResolution = { width: 1920, height: 1080 };
          // publishOptions.videoSource = 'screen';

          var publisher = OT.initPublisher('screen-preview',
            publishOptions,
            function (error) {
              if (error) {
                console.log(error);
              } else {
                session.publish(publisher, function (error) {
                  if (error) {
                    console.log(error);
                  }
                });
              }
            }
          );
        }
        session.on('streamCreated', function (event) {
          if (event.stream.videoType = 'screen') {
            session.subscribe(event.stream, 'screen-preview', publishOptions)
          }
        })
        publisher.on('mediaStopped', function (event) {
          console.log('stopped')
          this.screenshare = false;
          const users = document.getElementById('grid');
          const screenShare = document.getElementsByClassName('screen-share');
          // const grid = document.getElementById('grid');
          $(users).removeClass('screenlayout');

          publisher.on('streamDestroyed', function (event) {
            this.screenshare = false;
            if (event.reason === 'mediaStopped') {
              console.log('stop sharing');
              $(screenShare).removeClass('screen-share');
              $(screenShare).removeClass("screen-preview");
              $(screenShare).removeClass("ver-layout");
              $(screenShare).removeClass("vertical-pos");
            } else if (event.reason === 'forceUnpublished') {
              // A moderator forced the user to stop sharing.
            }
          })

        })


      });
    }
  }


  initPublisherForAdhoc = (BoxId: string) => {
    let insertOptions = {
      width: '100%',
      height: '165px',
      style: { buttonDisplayMode: 'off', nameDisplayMode: "off" },
    };
    // let element = BoxId;
    var properties: any = Object.assign({ name: BoxId, insertMode: 'append', }, insertOptions);
    this.publisher = OT.initPublisher(BoxId, properties);
    return this.publisher;
  };

  subscribeeventadhoc(boxid) {
    document.getElementById(boxid);
    let node = document.getElementById("appDragg-layout");
    $(node).attr("id", "drag-active-box");
  }

  //Video off by respective speaker show User details
  async videooff(boxId, userId) {
    let sessiondata: any = await this.getSessionConnection();
    sessiondata.signal({ data: { boxId, userId }, type: "imagedetails" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

  //Video On by respective speaker show User details
  async videoOn(boxId, userId) {
    let sessiondata: any = await this.getSessionConnection();
    sessiondata.signal({ data: { boxId, userId }, type: "imagedetailsOff" }, (error) => {
      if (error) {
        console.log("signal error (" + error.name + "): " + error.message);
      } else {
        console.log("signal sent.");
      }
    })
  }

}

//remove speaker/moderator from admin side
function remove(session: OT.Session, boxid: any) {
  session.signal({ data: boxid, type: "removeSpeaker" }, (error) => {
    if (error) {
      console.log("signal error (" + error.name + "): " + error.message);
    } else {
      console.log("signal sent.");
    }
  });
}

//mute Audio speaker/moderator from admin side
function muteAudio(session: OT.Session, boxid: any) {
  var signalData = Object.assign({ data: boxid, type: "muteAudio" });
  session.signal(signalData, (error) => {
    if (error) {
      console.log("signal error (" + error.name + "): " + error.message);
    } else {
      console.log("signal sent.");
    }
  });
}

//mute Video speaker/moderator from admin side
function muteVideo(session: OT.Session, boxid: any) {
  var signalData = Object.assign({ data: boxid, type: "muteVideo" });
  session.signal(signalData, (error) => {
    if (error) {
      console.log("signal error (" + error.name + "): " + error.message);
    } else {
      console.log("signal sent.");
    }
  });
}

//make speaker to moderator
function makeModerator(session: OT.Session, boxid: any) {
  var signalData = Object.assign({ data: boxid, type: "makemoderator" });
  session.signal(signalData, (error) => {
    if (error) {
      console.log("signal error (" + error.name + "): " + error.message);
    } else {
      console.log("signal sent.");
    }
  })
}

async function BecomespeakerToModerator(sessiondata, boxID) {
  let coreUrl = `${environment.apiUrl}Video`;
  let data = {
    userID : boxID.split('User_')[1],
    eventId: JSON.parse(sessionStorage.getItem('eventID')),
    channelID: JSON.parse(sessionStorage.getItem('channelID'))
  }
  $.ajax({
    url: coreUrl + `/BecomespeakerToModerator?userid=${data.userID}&EventId=${data.eventId}&channelID=${data.channelID}`,
    method: "POST",
    data: JSON.stringify(data),
    dataType: 'json',
    contentType: "application/json",
    success: function (result, status, jqXHR) {
      console.log(result);
      if (result.Data) {
        makeModerator(sessiondata, boxID)
      }

    },
    error(jqXHR, textStatus, errorThrown) {
      alert("api failed");
    }
  });
}

