import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule } from './components/dialog/dialog.module';
import { AvatarPhotoModule } from './components/avatar-photo/avatar-photo.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    DialogModule,
    AvatarPhotoModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    DialogModule,
    AvatarPhotoModule
  ]
})
export class SharedModule { }
