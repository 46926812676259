import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, DoCheck, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UtilityService } from '../../../core/utilities';
import { AuthService, GeneralService, HideShowService } from '../../../shared/services';
import * as OT from '@opentok/client';
import { Subscription, interval } from 'rxjs';
import { CommanService } from 'src/app/shared/services/comman.service';
import { environment } from 'src/environments/environment';
import { RolesEnums } from 'src/app/core/enums';
@Component({
  selector: 'app-video-header-bar',
  templateUrl: './video-header-bar.component.html',
  styleUrls: ['./video-header-bar.component.scss']
})
export class VideoHeaderBarComponent implements OnInit {
  redirectsiteurl = environment.siteurl;
  private unsubscribe$ = new Subject<void>();
  currentEvent: any;
  isAdmin: boolean;
  session: OT.Session;

  notificationsDialog: any;
  settingsDialog: boolean = false;

  // Calculation of days, hours, minutes, seconds
  milliSecondsInASecond = 1000;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  hoursInADay = 24;

  // Get Actual Time Difference
  totalTimeDifference: any;
  finalTotalTimeDifference: any;

  // progress time difference
  timeDifference: any;
  secondsToDday: any;
  minutesToDday: any;
  hoursToDday: any;
  daysToDday: any;
  minutesConverted: any;

  // progress bar value
  progressTimeDiff: any;
  progressSpent: any;
  progressBarValue = 100;
  progressValue: any;

  fullscreen: boolean = false;
  closeSessionByAdminDialog: boolean = false;
  elem: any;
  showTimeLeft: number;
  sessionLive: boolean = false;
  runTimeValue: number = 0;
  runTime: any;
  showRunTime: boolean = false;
  isStartSession: boolean = false;
  runTimeSubscription!: Subscription;
  timeSubscription!: Subscription;
  onLine: any;
  isAudienceOrSpeaker: boolean = false;
  broadCaststartTime: any;
  timebroadcaststarted: any;
  isModerator: boolean;
  broadcastStarted: any;
  // isShowNavRightBarSetting: boolean=false;
  isShowNavRightBar: boolean = false;

  constructor(
    private hideShowService: HideShowService,
    public generalService: GeneralService,
    public utilityService: UtilityService,
    public router: Router,
    public authService: AuthService,
    public commanService: CommanService,
    @Inject(DOCUMENT) private document: any
  ) { }


  ngOnInit() {
    this.generalService.isEventExits$.subscribe(isEvent => {
      if (isEvent) {
        combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(([items]) => {
            this.currentEvent = items.Data;
            this.elem = document.documentElement;
          });
      }
    });
    this.isAdmin = this.generalService.isUserAdmin;
    this.isAudienceOrSpeaker = this.generalService.isUserAudienceOrSpeaker;
    this.isModerator = this.generalService.isUserModerator;
    // // Event Date & start and end date
    const eventDate = this.currentEvent.EventDate;
    const eStartTime = this.currentEvent.Event_StartTime;
    const eEndTime = this.currentEvent.Event_StartTime;

    // Converting time and date to fullDate
    const startTimeE = eventDate + ' ' + eStartTime;
    const endTimeE = eventDate + ' ' + eEndTime;
    const newStartTimeE = new Date(startTimeE);
    const newEndTimeE = new Date(endTimeE);

    // on start broadcast show progressBar and left Time


    // show RunTime to speaker/moderator/audience on connection establish of them
    this.runTimeSubscription = interval(5000).subscribe(datas => {
      this.generalService.GetBroadcastStartTime(this.generalService.isEventID)
        .subscribe((data: any) => {
          this.onLine = false;
          this.broadcastStarted = data.IsBroadcastStart;
          if (data.IsBroadcastStart) {
            this.onLine = true;
            this.broadcastStarted = data.IsBroadcastStart;
            this.showTimeLeft = data.timeelapsed;
            this.broadCaststartTime = data.BroadcastStartOn;
            this.timebroadcaststarted = (this.broadCaststartTime).split("T")[1]  //12:40:38.937+02:00
            this.sessionLive = true;
            this.generalService.brodcasstart.next(this.broadcastStarted);
            this.getTimeDifference(newStartTimeE, newEndTimeE);
            this.startRunTimer();
          }
        })
    });
    this.generalService.isBroadcastStopped.subscribe(res => {
      if (res) {
        setTimeout(() => {
          this.broadcastStarted = false;
          this.runTimeSubscription.unsubscribe();
        }, 2000);
      }
    })
  }

  getTimeDifference(sTime: any, eTime: any) {
    this.totalTimeDifference = eTime.getTime() - sTime.getTime();
    this.allocateTimeUnits(this.showTimeLeft, this.utilityService.totaltimediferrence);
  }

  allocateTimeUnits(timeDifference: any, totalTimeDifference: any) {
    // this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    // this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    // this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    // this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    this.progressTimeDiff = Math.floor(timeDifference / 60)
    this.minutesConverted = Math.floor(timeDifference / 60);

    this.finalTotalTimeDifference = Math.floor(totalTimeDifference / 60) + 1

    this.progressSpent = Math.floor(this.finalTotalTimeDifference - this.progressTimeDiff);
    this.progressValue = this.progressSpent - Math.floor(this.progressSpent / this.finalTotalTimeDifference) * 100;

    this.progressBarValue = 100 - this.progressValue;

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.timeSubscription.unsubscribe();
    this.runTimeSubscription.unsubscribe();
  }

  closeSessionByAdmin() {
    this.generalService.isEventSessionExits$.subscribe(async resp => {
      const sessionId: any = await this.utilityService.getSessionConnection();
      this.generalService.toStopEventSession(this.generalService.isEventID, sessionId.sessionId).subscribe((res: any) => {
        if (res) {
          // this.utilityService.signal(sessionId, 'ended');
          // this.authService.logout();
          if (this.generalService.isRole === RolesEnums.Roles.Admin) {
            this.utilityService.openLinkInSameTab(this.redirectsiteurl);
            sessionId.disconnect();
          }
          else if (this.generalService.isRole === RolesEnums.Roles.Moderator) {
            this.utilityService.openLinkInSameTab(this.redirectsiteurl);
            sessionId.disconnect();
          }
          else {
            this.utilityService.sendSignalToSpeaker(sessionId);
            this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, this.generalService.isRole)
          }
        }
      });
    });
  }

  fullScreenVideoPlatForm() {
    if (!this.fullscreen) {
      this.fullscreen = true;
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        this.elem.msRequestFullscreen();
      }
    } else {
      this.fullscreen = false;
      if (this.fullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }

  closeSessionDialog() {
    this.closeSessionByAdminDialog = false;
  }

  // time to show for audience/speaker/moderator
  startRunTimer() {
    if (this.runTimeValue === 0) {
      this.runTimeValue++;
    } else {
      this.runTimeValue++;
    }
    this.runTime = this.transform(this.showTimeLeft);
  }
  // transform(value: number): string {
  //   var minutes: any = Math.floor(value/60);
  //   // var minutes:any = Math.floor((value*60)%60);
  //   if (minutes <= 9) {
  //    minutes = '0' + minutes
  //   }
  //   var hours: any = Math.floor(minutes / 60);
  //   if (hours <= 9) {
  //     hours = '0' + hours
  //   } else {
  //     hours
  //   }
  //   var sec: any = value - minutes * 60;
  //   sec = sec.toFixed(0);
  //   if (sec <= 9) {
  //     sec = '0' + sec
  //   } else {
  //     sec
  //   }
  //   return hours + ":" + minutes + ':' + sec;
  // }
  // transform(value: number): string {
  //   var hours: any = Math.floor(value / 3600);
  //   if (hours <= 9) {
  //     hours = '0' + hours
  //   } else {
  //     hours
  //   }
  //   var minutes: any = Math.floor((value - (+hours * 3600)) / 60);
  //   if (minutes <= 9) {
  //     minutes = '0' + minutes
  //   }
  //   var seconds: any = value - (+hours * 3600) - (minutes * 60);
  //   seconds = seconds.toFixed(0);
  //   if (seconds <= 9) {
  //     seconds = '0' + seconds
  //   } else {
  //     seconds
  //   }
  //   return hours + ':' + minutes + ':' + seconds;
  // }
  transform(value: number): string {
    // if (typeof value !== 'number' || value < 0){
    //   return 'Invalid Input';
    // }
    let hours: any = Math.floor(value / 3600);
    let minutes: any = Math.floor((value - (hours * 3600)) / 60);
    let seconds: any = Math.floor(value - (hours * 3600) - (minutes * 60));

    // format hours
    hours = (hours < 10) ? ('0' + hours) : hours;

    // format minutes
    minutes = (minutes < 10) ? ('0' + minutes) : minutes;

    // format sec
    seconds = (seconds < 10) ? ('0' + seconds) : seconds;

    return hours + ':' + minutes + ':' + seconds;
  }

  clickMenu() {
    this.isShowNavRightBar = !this.isShowNavRightBar;
    this.hideShowService.setShowNav(this.isShowNavRightBar);
    this.hideShowService.setNavStyle(this.isShowNavRightBar);
    this.hideShowService.setShowNavSetting(this.isShowNavRightBar);
  }

  settingsdialogclose() {
    this.settingsDialog = false;
  }

  // end

}
