import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];

  constructor() { }

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }

}
