import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectedUsersRoutingModule } from './connected-users-routing.module';
import { ConnectedUsersComponent } from './connected-users.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UserVideoScreenComponent } from './user-video-screen/user-video-screen.component';
import { NavRightComponent } from './nav-right/nav-right.component';
import { MaterialModule } from 'src/app/material-module';
import { SharedModule } from '../../../shared/shared.module';
import { PopupsModule } from '../pop-ups/popups.module';
import { RemoveSpeakerComponent } from '../pop-ups/remove-speaker-dialog/remove-speaker-dialog.component';
import { DraggableDirective } from './draggable.directive';
import { SettingsUsersComponent } from './settings-users/settings-users.component';
import { WaitingScreenModule } from '../waiting-screen/waiting-screen.module';
import { AddonsComponent } from './add-ons-menu/addons.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    ConnectedUsersComponent,
    UserVideoScreenComponent,
    // RemoveSpeakerComponent,
    NavRightComponent,
    DraggableDirective,
    SettingsUsersComponent,
    AddonsComponent
  ],
  exports: [
    ConnectedUsersComponent,
    UserVideoScreenComponent,
    NavRightComponent,
    DraggableDirective,
    SettingsUsersComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  imports: [
    CommonModule,
    ConnectedUsersRoutingModule,
    NgbDropdownModule,
    MaterialModule,
    PopupsModule,
    SharedModule,
    // RemoveSpeakerComponent
    WaitingScreenModule,
    PickerModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ConnectedUsersModule { }
