<div class="modal-header">
  <h4 class="pull-left">Leave Event</h4>
</div>
<div class="modal-body text-center">
  <div class="leave-modal-layout">
    <h4 class="font-14 line-height-20 grotesk-medium color-black">
      Are you sure you want to leave the event?
    </h4>
    <p class="font-14 line-height-20 gibson-book-font color-black">
      You will be shown a feedback form once you have exited. Please fill out the form to receive your digital goodies.
    </p>
  </div>
  <div class="col-12 text-center mt-4 mb-2">
    <button type="button" class="btn-border-outline font-14 line-height-24 gibson_medium" (click)="closeFeedBackDialogC()">Go Back</button>
    &nbsp;
    <button type="button" class="btn-common font-14 line-height-24 gibson_medium"
      (click)="leaveEvent()">
      Leave Event
    </button>
  </div>
</div>

<app-dialog [(visible)]="openFeedbackDialog" [dialogClass]="'popup-width-516'">
  <app-feedback-content-dialog (closeFeedBackDialog)="closeFeedBackDialog()"></app-feedback-content-dialog>
</app-dialog>
