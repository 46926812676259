<div class="modal-header">
  <h4 class="pull-center">Broadcast</h4>
</div>
<div class="modal-body">
  <div class="radio-button-wrapper1 col-lg-12 col-md-12 col-sm-12">
    <p class="">
      Just a second, broadcast is about to start
    </p>
    <div class="connecting-layout">
      <img src="assets/images/connected-loader.gif" alt="Connect">
      <span>Connecting...</span>
    </div>
  </div>
</div>