<!-- <div class="modal-header">
  <h4 class="pull-left">
    Share Screen
  </h4>
</div>
<div class="modal-body text-center">

  <h4 class="mb-2 grotesk-medium font-14 line-height-20 color-black">{{speakername}} has Requested to share screen</h4>
  <p class="gibson-book-font font-14 line-height-20 opacity-0-8 color-black mb-3">
    You will be able to share your screen once moderator gives you permission
  </p>
  <button class="btn-screen-layout mb-2 gibson_medium font-14 line-height-24 color-primary" (click)="declined()">
    Deny
  </button>
  <button class="btn-screen-layout mb-2 gibson_medium font-14 line-height-24 color-white mr-3 bg-primary" (click)="accepted()">
    Accept
  </button>
</div> -->

<div class="modal-header">
  <h4 class="pull-left">Speaker Share Screen Request</h4>
</div>
<div class="modal-body px-4">
  <div class="request-user-wrapper">
    <ng-container *ngFor="let item of speakerdata;let i=index">
      <div class="row mb-3">
        <div class="col-lg-6">
          <div class="d-flex justify-flex-start align-items-center">
            <!-- <div class="req-user-image mr-2">
            <img src="${item?.ProfilePicture}" />
          </div> -->
            <div class="user-name-caption-req">
              <app-avatar-photo *ngIf="item !== undefined" [photoUrl]="item?.ProfilePicture" [name]="item?.Name"
                round="false" class="avtar-listing-icon">
              </app-avatar-photo>
            </div>
            <div class="mx-3 grotesk-medium font-14 line-height-20 color-black">
              {{item?.Name}}
              <span class="circle-dot"></span>
            </div>
            <div class="req-user-position d-flex align-items-center">
              <span class="chat-dot"></span>
              <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">{{item?.CompanyName}}</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 text-right p-0 d-flex justify-content-end align-items-center">
          <button type="button" class="btn-border-outline mr-2 font-12 line-height-24 gibson_medium"
            (click)="declined(i)">Decline</button>
          &nbsp;
          <button type="button" class="btn-common font-12 line-height-24 gibson_medium" style="margin-right: 8px;"
            (click)="accepted(i)">Accept</button>
          <!-- Remove Button Condition Wise Manage -->
          <!-- <a href="#" class="font-14 line-height-14 red-light gibson_medium remove-btn">Remove</a> -->
          <!-- End -->
        </div>
      </div>
    </ng-container>
    <div class="no-speaker-req" *ngIf="speakerdata.length==0">
      No share screen requests
    </div>

  </div>
