import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService, HideShowService } from 'src/app/shared/services';


@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrls: ['./settings-users.component.scss']
})
export class SettingsUsersComponent implements OnInit {
  navStyleSetting: Observable<boolean>;
  isWidthForVideoSetting: string = '100%';
  isWidthForNavRightScreenSetting: string = '0';
  checked: boolean;
  x: any[] = [];
  isWidthForVideoScreenSetting: string;
  data: { id: number; name: string; }[];
  selectedData: any;
  formGroup: FormGroup;
  eventdata: any;
  @Output() settingsdialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  isShowLoader: boolean = false;
  showSuccessMsg: boolean = false;
  constructor(
    public hideShowService: HideShowService,
    public generalService: GeneralService,
    formBuilder: FormBuilder,
    public utilityService: UtilityService
  ) {
    this.formGroup = formBuilder.group({
      EventID: this.generalService.isEventID,
      isLiveChatEnabled: '',
      isAudienceCountEnabled: '',
      isSponsorsEnabled: '',
      isQAEnabled: '',
      isSpeakersEnabled: '',
      isModeratorsEnabled: '',
      isPartnersEnabled: '',
    });
  }

  ngOnInit() {
    this.isShowLoader=true;
    this.generalService.getEventDetailsById(this.generalService.isEventID).subscribe((data: any) => {
      this.isShowLoader=false;
      this.eventdata = data.Data;
    });
  }


  async onsave() {
    // this.isShowLoader = true;
    let sessiondata: any = await this.utilityService.getSessionConnection();
    let SavedSettings = this.formGroup.value

    this.generalService.eventSettingChange(this.formGroup.value).subscribe((res) => {
      console.log(res);
    })
    let data = {
      AudienceChat: SavedSettings.isLiveChatEnabled,
      DisplayPeopleAttendingCount: SavedSettings.isAudienceCountEnabled,
      DisplayViewersto_Speakers: SavedSettings.isSpeakersEnabled,
      DisplayViewersto_Moderator: SavedSettings.isModeratorsEnabled,
      CanAudienceAskQuestion: SavedSettings.isQAEnabled,
      DisplayPartners: SavedSettings.isPartnersEnabled,
      DisplaySponsors: SavedSettings.isSponsorsEnabled,
      DisplayDocumentsAndNotes:SavedSettings.isDocumentsAndNotesEnabled
    }
    Object.assign(SavedSettings, data);
    this.generalService.settingsdata$.next(SavedSettings);
    this.utilityService.sendsignalofSettingdata(sessiondata, SavedSettings)
    // this.isShowLoader = false;
    // this.generalService.getEventDetailsById(this.generalService.isEventID)
    // this.showSuccessMsg=true;
    this.showSuccessMsg=true;
     // this.formGroup.setValue(SavedSettings)
    setTimeout(() => {
      this.showSuccessMsg=false;
      this.settingsdialogClose.emit();
    }, 2000);
  }


  cancel() {
    this.settingsdialogClose.emit();
  }

}
