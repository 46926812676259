<div class="modal-header">
    <h4 class="pull-left">
        Admit Speakers (3)
    </h4>
</div>
<div class="modal-body">
    <div class="admit-speaker-info text-center">
        <img src="assets/images/admit-speaker-grp.png" alt="Speaker" class="mb-2">
        <h4 class="font-16 line-height-24 color-black grotesk-regular font-w"><span class="grotesk-medium">Joseph Atada </span>wants to join this Discussion </h4>
        <p class="gibson-book-font font-14 line-height-20 color-black caption-text-admit">As the Host / Moderator, you can remove this speaker
            at anytime you want.</p>

        <div class="col-12 text-center mt-4 mb-1">
            <button type="button" class="btn-border-outline gibson_medium font-14 line-height-24">Deny</button>
            &nbsp;
            <button type="button" class="btn-common gibson_medium font-14 line-height-24">Admit</button>
        </div>
    </div>
</div>