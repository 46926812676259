<div class="modal-header">
  <h4 class="pull-left">Reported Users</h4>
</div>
<div class="modal-body px-4">
  <div class="request-user-wrapper mb-3">
    <!-- <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex justify-flex-start align-items-center">
                <div class="req-user-image mr-2">
                    <img src="/assets/images/event-join2.png" />
                </div>
                <div class="grotesk-medium font-14 line-height-20 color-black">
                    Greg S.
                </div>
                <div class="req-user-position d-flex align-items-center">
                    <span class="chat-dot"></span>
                    <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Maxwell Insurance
                    </div>
                </div>
            </div>
            <a  class="font-14 line-height-14 red-light gibson_medium remove-btn">Remove</a>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex justify-flex-start align-items-center">
                <div class="req-user-image mr-2">
                    <img src="/assets/images/event-join2.png" />
                </div>
                <div class="grotesk-medium font-14 line-height-20 color-black">
                    Arthur Stephan
                </div>
                <div class="req-user-position d-flex align-items-center">
                    <span class="chat-dot"></span>
                    <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Samsung
                    </div>
                </div>
            </div>
            <a  class="font-14 line-height-14 red-light gibson_medium remove-btn">Remove</a>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex justify-flex-start align-items-center">
                <div class="req-user-image mr-2">
                    <img src="/assets/images/event-join2.png" />
                </div>
                <div class="grotesk-medium font-14 line-height-20 color-black">
                    Martin Garix
                </div>
                <div class="req-user-position d-flex align-items-center">
                    <span class="chat-dot"></span>
                    <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Discussion Box
                    </div>
                </div>
            </div>
            <a  class="font-14 line-height-14 red-light gibson_medium remove-btn">Remove</a>
        </div> -->
    <div class="module-progress">
      This module is in progress
    </div>

  </div>
</div>
