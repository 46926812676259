import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreHttpService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { UtilityService } from '../../core/utilities';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  coreUrl = `${environment.apiUrl}Video`;
  coreEventUrl = `${environment.apiUrl}Event`;

  isUserExits$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private coreHttpService: CoreHttpService,
    public utilityService: UtilityService
  ) { }

  /**
   * Login  of auth service
   */
  login = (user: { EventId: number; UserId: number; AccessCode: string; EmailAddress: string; }): any => {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = this.coreUrl + `/CheckUserAndAuthenticate`;
    return this.coreHttpService.httpPostRequest(url, user, headers);
  };

  /**
   * Sets items into local storage
   * @returns
   */
  async setItemsIntoLocalStorage(isUser: boolean, eventID?: number, role?: string, userID?: number, userDetails?: any) {
    var promise = new Promise(async (resolve, reject) => {
      sessionStorage.setItem('isUser', JSON.stringify(isUser));
      sessionStorage.setItem('eventID', JSON.stringify(eventID));
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('userID', JSON.stringify(userID));
      if (userDetails) {
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
      }
      this.isUserExits$.next(JSON.parse(sessionStorage.getItem('isUser')));
      resolve('');
    });
    return promise;
  }

  /**
   * Gets whether is logged in or not
   */
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('isUser'));
    return (user !== null && user) ? true : false;
  }

  /**
   * Gets whether is logged in or not
   */
  get isLoggedInOrNot() {
    return this.isUserExits$.asObservable();
  }

  /**
   * Logout of auth service
   */
  logout = () => {
    sessionStorage.setItem('isUser', JSON.stringify(false));
    sessionStorage.removeItem('userDetails');
    this.isUserExits$.next(false);
    this.utilityService.requestCamera(false, false);
    window.location.reload();
  }


}
