import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-event-not-started-dialog',
  templateUrl: './event-not-started-dialog.component.html',
  styleUrls: ['./event-not-started-dialog.component.scss']
})
export class EventNotStartedDialogComponent implements OnInit {
  @Output() closeventsessiondialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

}
