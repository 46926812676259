import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-session-confirm-dialog',
  templateUrl: './session-confirm-dialog.component.html',
  styleUrls: ['./session-confirm-dialog.component.scss']
})
export class SessionConfirmDialogComponent implements OnInit {

  @Output() closeSessionByAdminDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callSessionAPIByAdminDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeSessionDialog() {
    this.closeSessionByAdminDialog.emit();
  }

  callAPIForCloseSession() {
    this.callSessionAPIByAdminDialog.emit();
  }
}
