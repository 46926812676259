import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';

@Component({
  selector: 'app-screensharepermission',
  templateUrl: './screensharepermission.component.html',
  styleUrls: ['./screensharepermission.component.scss']
})
export class ScreensharepermissionComponent implements OnInit {
  speakerdata: any[] = [];
  requestSendById: any;
  speakername: any;
  @Output() screensharepermissionClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  sendbyIDuserId: any;


  constructor(public utilityservice: UtilityService,
    public generalService: GeneralService) { }

  ngOnInit(): void {
    this.generalService.listOfAudience(this.generalService.isEventID).subscribe((data: any) => {
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].TypeofRequest == "ScreenShare" && data[i].Status == "Pending") {
            this.speakerdata.push(data[i]);
          }
        }
      }

    })
    this.generalService.boxIdDetails$.subscribe(data => {
      if (data) {
        data['Name']=data.userName;
        data['CompanyName']=data.userCompanyName;
        data['ProfilePicture']=data.userProfilePicture;
        this.requestSendById = data.userID;
        this.speakername = data.userName;
      }
      this.speakerdata.push(data)
    })
    this.speakerdata.splice(0,1);
  }
  async accepted(id) {
    for (let i = 0; i < this.speakerdata.length; i++) {
      if (i == id) {
        this.sendbyIDuserId = this.speakerdata[i].RequestSendById;
        if(!this.sendbyIDuserId){
          this.sendbyIDuserId = this.speakerdata[i].userID;
        }
        let data = {
          Status: 1,
          userId: this.sendbyIDuserId,
          eventID: this.generalService.isEventID,
          typeOfRequest: 1
        }
        this.generalService.saveAudienceRequest(data).subscribe(async response => {
          const sessiondata: any = await this.utilityservice.getSessionConnection();
          this.utilityservice.acceptscreensharesignal(sessiondata, this.sendbyIDuserId);
          this.screensharepermissionClose.emit();
        })
      }
    }
    this.screensharepermissionClose.emit();
  }

  async declined(id) {
    for (let i = 0; i < this.speakerdata.length; i++) {
      if (i == id) {
        console.log(this.speakerdata[i]);
        let userId = this.speakerdata[i].RequestSendById;
        let data = {
          Status: 2,
          userId: userId,
          eventID: this.generalService.isEventID,
          typeOfRequest: 1
        }
        this.generalService.saveAudienceRequest(data).subscribe(async response => {
          const sessiondata: any = await this.utilityservice.getSessionConnection();
          this.utilityservice.sendSignalForScreenshareDecline(sessiondata, userId);
          this.screensharepermissionClose.emit();
        })
      }
    }
    this.screensharepermissionClose.emit();



  }
}
