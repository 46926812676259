<form [formGroup]="askQuestionForm">

  <div class="modal-header">
    <h4 class="pull-left">
      Ask a Question
    </h4>
  </div>

  <div class="modal-body text-center">

    <!-- <h4 class="font-14 line-height-20 color-black grotesk-medium">Ask a Question</h4> -->
    <p class="modal-title mb-3 col-11 mx-auto font-14 line-height-20 color-black text-center gibson-book-font">
      Ask a question to the moderator here. Answering selected
      questions is as per the discretion of the moderator. Kindly keep the
      questions short and to the point.
    </p>

    <div class="col-11 mx-auto mb-3 texteatra-wrapper">
      <textarea class="form-control" [(ngModel)]="questionasked" placeholder="Type your question here..." rows="5" formControlName="OpenQuestion"></textarea>
    </div>
    <div class="text-danger" *ngIf="submitted && askQuestion.OpenQuestion.errors?.required">
      <sup>*</sup>{{ 'This field is required' }}.
    </div>

    <button *ngIf="!isSubmittedQues" class="btn-common mb-3 font-14 line-height-24 gibson_medium" (click)="OnSubmit()">
      Submit
    </button>

    <div class="sucess-alert" *ngIf="isSubmittedQues">
      <img src="assets/images/right-tick.png" alt="Right Click" class="img-fluid mr-3">
      <p class="font-14 gibson-book-font line-height-24 letter-spacing-0-2 mb-0">Question Submitted</p>
    </div>

  </div>

</form>