<div class="modal-header">
  <h4 class="pull-left">
    Share Your Screen
  </h4>
</div>
<div class="modal-body text-center">
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <p class="gibson-book-font font-14 line-height-20 opacity-0-8 color-black mb-1">
    <b>Share Screen Request Declined</b>
  </p>
  <p>Your request to share your screen has declined by<br>
    the event host</p>

</div>