<!-- After Login -->
<app-video-header-bar *ngIf="(!currentRoute && isLoggedIn$ | async) as isLoggedIn; else beforeLoading"></app-video-header-bar>
<!-- End -->

<ng-template #beforeLoading>
  <!-- Before Login -->
  <div class="header-layout">
    <div class="brand-logo"><a><img src="assets/images/black-logo.png" alt="Logo" class="img-fluid"></a></div>
  </div>
  <!-- End -->
</ng-template>
