<section class="waiting-screen width-100">
  <div class="row wiating-event-wrapper d-flex justify-between height-full no-gutters">
    <div class="waiting-title col-lg-6 d-flex align-items-center">
      <div>
        <h5 class="heading-waiting mb-2">Hey {{fullName}}!</h5>
        <h2 class="main-color">
          Welcome to the discussion.<br /> The host will let you in soon...
        </h2>
        <div class="text-left">
          <img src="../../../../assets/images/logodiscussionbox.svg" />
        </div>
      </div>
    </div>
    <div class="col-lg-6 ml-auto">
      <div class="waiting-user-wrapper mt-3">
        <div class="waiting-main speakers-waiting">
          <h5 class="primary-fg mb-5 grotesk-regular font-16 line-height-22">Speakers in this discussion</h5>
          <div *ngIf="currentEvent" class="waiting-right">
            <div *ngFor="let item of currentEvent?.SpeakersList" class="col-right waiting-box-layout">
              <div class="waiting-userImg">
                <!-- <img class="rounded-img" style="height: 100px; width: 100px;" [src]=item?.SpeakersImage /> -->
                <app-avatar-photo [photoUrl]="item?.SpeakersImage" [name]="item?.SpeakersName" round="false"
                  class="avtar-listing-icon"></app-avatar-photo>
              </div>
              <div class="waiting-caption-layout">
                <p class="speaker-name-caption main-color mb-0 grotesk-medium font-10 mb-1 line-height-9 color-white">
                  {{item?.SpeakersName}}
                </p>
                <p class="speaker-caption mb-0 f-weight-300 line-height-9 mt-1 color-white gibson-book-font font-9">
                  {{item?.SpeakersJobTitle}} <br> <span class="grotesk-regular"> {{item?.SpeakersCompanyName}} </span>
                </p>
              </div>
            </div>
            <div *ngFor="let item of currentEvent?.ModeratorList" class="col-right waiting-box-layout">
              <div class="waiting-userImg">
                <!-- <img class="rounded-img" style="height: 100px; width: 100px;" [src]=item?.SpeakersImage /> -->
                <app-avatar-photo [photoUrl]="item?.SpeakersImage" [name]="item?.SpeakersName" round="false"
                  class="avtar-listing-icon"></app-avatar-photo>
              </div>
              <div class="waiting-caption-layout">
                <p class="speaker-name-caption main-color mb-0 grotesk-medium font-10 mb-1 line-height-9 color-white">
                  {{item?.SpeakersName}}
                </p>
                <p class="speaker-caption mb-0 f-weight-300 line-height-9 mt-1 color-white gibson-book-font font-9">
                  {{item?.SpeakersJobTitle}} <br> <span class="grotesk-regular"> {{item?.SpeakersCompanyName}} </span>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
