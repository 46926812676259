<div class="modal-header">
  <h4 class="pull-left">
    Join Speakers
  </h4>
</div>
<div class="modal-body text-center">

  <!-- <p class="modal-title mt-2 mb-4">
      How do you want to join the event?
    </p> -->
  <h4 class="mb-2 grotesk-medium font-14 line-height-20 color-black">How do you want to join the speakers?</h4>
  <p class="gibson-book-font font-14 line-height-20 opacity-0-8 color-black mb-3">
    Allowing audience to join the speakers is as per the discretion
    of the moderator. Kindly wait for them to allow you after
    you request to join the event.
  </p>
  <button class="btn-join-layout mb-2 gibson_medium font-14 line-height-24 color-white">
    Join using computer audio
    <span class="ml-3">
      <img src="../../../../../assets/images/micro.svg" alt="Microphone">
    </span>
  </button> <br />
  <button class="btn-join-layout-outline gibson_medium font-14 line-height-24 mt-1 color-primary">
    Join using audio and video
    <span class="icon-size-join ml-3 mr-2">
      <svg viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.25283 12.0863H7.12575C7.91938 12.0855 8.68028 11.7703 9.24146 11.2097C9.80264 10.6491 10.1182 9.88909 10.119 9.09635V0.575536L9.54283 0H3.78102L3.20483 0.575536V9.04168C3.2056 9.84892 3.52697 10.6229 4.09841 11.1937C4.66986 11.7645 5.44469 12.0855 6.25283 12.0863ZM4.3572 1.15107H8.96665V9.09635C8.96626 9.58393 8.77219 10.0514 8.42704 10.3962C8.08189 10.741 7.61387 10.9348 7.12575 10.9352H6.25283C5.7502 10.9348 5.26825 10.7352 4.91284 10.3802C4.55742 10.0252 4.35758 9.54375 4.3572 9.04168V1.15107Z"
          fill="#1A09F4" />
        <path
          d="M11.8476 8.05762V9.63171C11.8468 10.5496 11.4815 11.4297 10.8317 12.0788C10.1819 12.7278 9.3008 13.0928 8.38187 13.0936H5.57443C4.4879 13.0924 3.4462 12.6608 2.6779 11.8933C1.90961 11.1259 1.47748 10.0854 1.47634 9.00006V8.05762H0.323975V9.00006C0.3255 10.3905 0.87916 11.7236 1.86348 12.7069C2.8478 13.6901 4.18239 14.2431 5.57443 14.2446H6.08579V20H7.23815V14.2446H8.38187C9.60631 14.2435 10.7803 13.7571 11.6461 12.8923C12.5119 12.0274 12.9988 10.8548 13 9.63171V8.05762H11.8476Z"
          fill="#1A09F4" />
      </svg>
    </span>
    <span class="pl-2 icon-size-join-video ">
      <svg viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.9951 0.56092L19.5124 4.532H18.2318V0.941728L17.6576 0.364746H0.574231L0 0.941728V13.0584L0.574231 13.6353H17.6576L18.2318 13.0584V9.94121H19.531L23.0166 13.5026L24 13.0987V0.941728L22.9951 0.56092ZM17.0834 12.4814H1.14846V1.51871H17.0834V12.4814ZM22.8515 11.688L20.1814 8.96034L19.7722 8.78724H18.2663V5.68596H19.7722L20.2029 5.49123L22.8515 2.46929V11.688Z"
          fill="#1A09F4" />
      </svg>
    </span>
  </button>
</div>