import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services';
import { RolesEnums } from "../enums";
@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    setTimeout(() => {
      if (next && next.queryParams &&
        (next.queryParams.role !== RolesEnums.Roles.Admin || next.queryParams.role !== RolesEnums.Roles.Audience)) {
        if (this.auth.isLoggedIn !== true) {
          // window.alert('Access Denied, Login is Required to Access This Page!')
          // this.router.navigate(['login'])
          // this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
          this.router.navigate(
            ['/login'],
            {
              queryParams: {
                role: next.queryParams.role,
                userId: parseInt(next.queryParams.userId),
                eventId: parseInt(next.queryParams.eventId)
              }
            }
          );
        }
      }
    }, 1000);
    return true;
  }
}
