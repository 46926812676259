import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';

@Component({
  selector: 'app-option-vote-list-dialogue',
  templateUrl: './option-vote-list-dialogue.component.html',
  styleUrls: ['./option-vote-list-dialogue.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
})
export class OptionVoteListDialogueComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any;
  userData: any;

  constructor(
    public generalService: GeneralService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<OptionVoteListDialogueComponent>,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.generalService.pollVoteDetails$.subscribe((polldata) => {
      this.list = polldata;
      let optionid = this.list.ID
      this.generalService.GetPollOptionUserVoteList(optionid).subscribe(res => {
        if (res) {
          this.userData = res;
        }
      })
    })
  }




  //close dialogue
  cancelData() {
    this.dialogRef.close();
  }

}
