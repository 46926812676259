import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/utilities';
import { CommanService } from 'src/app/shared/services/comman.service';
import { RolesEnums } from '../../../core/enums';
import { AuthService, GeneralService, HideShowService } from '../../../shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-footer-bar',
  templateUrl: './video-footer-bar.component.html',
  styleUrls: ['./video-footer-bar.component.scss'],
  providers: [NgbDropdownConfig],
  encapsulation: ViewEncapsulation.None

})
export class VideoFooterBarComponent implements OnInit {
  redirectsiteurl = environment.siteurl;
  showEventInfoDialog: boolean = false;
  askQuestionDialog: any;
  leaveEventDialog: any;
  connectDialog: any;
  joinRequestDialog: any;
  openFeedbackDialog: any;
  openReportUserDialog: any;
  openreportuserlist: any;
  openJoinSpeakerDialog: any;
  digitalGoodiesDialog: any;
  admitSpeakerDialog: any;
  removeSpeakerDialog: any;
  id: any;

  shareyourscreen: any;
  audioOutputs: any;
  private unsubscribe$ = new Subject<void>();
  currentEvent: any;
  isAdmin: boolean = false;
  isAdminOrAudience: boolean = false;
  isAdminOrSpeaker: boolean = false;
  isAudienceOrSpeaker: boolean = false;
  isModeratorOrSpeakerOrAdmin: boolean = false;
  // isUserAudience:boolean = false;
  isMuteOrUnmuteAudio: boolean = true;
  isMuteOrUnmuteVideo: boolean = true;
  isAudience: boolean = false;

  toCheck$: Observable<void>;
  isConnectingDialog: Observable<boolean>;

  isNeedToShowStartBroadCastBtn: boolean;
  isShowNavRightBar: boolean = false;
  isSpeaker: boolean = false;
  audience: any[] = [];
  audienceCount: number = 0;
  audiencetospeakerdetails: any[] = [];
  eventsetting: any;
  // settingsdata = this.generalService.settingsdata$.asObservable();
  isModerator: boolean = false;
  broadcaststarted: boolean = false;
  screensharepermission: boolean = false;
  currentIndex: number = 0;
  questioncount: number = 0;
  receivedQuestion: any;
  broadcaststop = this.generalService.isBroadcastStopped.asObservable();


  constructor(
    private hideShowService: HideShowService,
    public generalService: GeneralService,
    public authService: AuthService,
    public utilityService: UtilityService,
    public router: Router,
    public commanService: CommanService
  ) { }

  ngOnInit() {
    this.generalService.brodcasstart.subscribe(data => {
      this.broadcaststarted = data;
    })
    this.isConnectingDialog = this.hideShowService.getShowHideModal();
    this.isConnectingDialog.subscribe((isBroadCast): any => {
      if (isBroadCast) {
        this.isNeedToShowStartBroadCastBtn = false;
      } else {
        this.isNeedToShowStartBroadCastBtn = true;
      }
    });


    this.generalService.settingsdata$.subscribe(async data => {
      if (data) {
        this.eventsetting = await data;
        Object.assign(this.eventsetting, this.currentEvent.EventSettings)
      }
    })
    this.generalService.isEventExits$.subscribe(isEvent => {
      if (isEvent) {
        combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(([items]) => {
            this.currentEvent = items.Data;
            if (this.currentEvent) {
              this.eventsetting = {
                isAudienceCountEnabled: this.currentEvent.EventSettings.DisplayPeopleAttendingCount,
                isQAEnabled: this.currentEvent.EventSettings.CanAudienceAskQuestion
              }
              Object.assign(this.eventsetting, this.currentEvent.EventSettings);
            }
          });
      }
    });
    this.isAdmin = this.generalService.isUserAdmin;
    this.isAdminOrAudience = this.generalService.isUserAdminOrAudience;
    this.isSpeaker = this.generalService.isUserSpeaker
    this.isAdminOrSpeaker = this.generalService.isUserAdminOrSpeaker;
    this.isAudienceOrSpeaker = this.generalService.isUserAudienceOrSpeaker;
    this.isModeratorOrSpeakerOrAdmin = this.generalService.isUserModeratorOrSpeakerOrAdmin;
    this.isAudience = this.generalService.isUserAudience
    this.isModerator = this.generalService.isUserModerator;
    // this.commanService.isAudioMuteByAdmin.subscribe(isAudio => {
    //   if (isAudio) {
    //     this.muteAudio();
    //   } else {
    //     this.unMuteAudio();
    //   }
    // });
    // this.commanService.isvideoMute.subscribe(isVideo => {
    //   if (isVideo) {
    //     this.muteVideo();
    //   } else {
    //     this.unMuteVideo();
    //   }
    // });

    this.commanService.isVideoOffFromStart.subscribe(res => {
      if (res == true) {
        this.isMuteOrUnmuteVideo = false
      }
    })
    this.commanService.isAudioOffFromStart.subscribe(res => {
      if (res == true) {
        this.isMuteOrUnmuteAudio = false
      }
    })


    this.audiooutput();
    //Get Live audience count
    this.generalService.audienceDataSource$.subscribe((audiencenumber) => {
      this.generalService.ToGetAudienceCount(this.generalService.isEventID).subscribe((data: any) => {
        this.audienceCount = data.Data;
      });
    });

    this.generalService.audLeaveEvent.subscribe(dataAud => {
      this.generalService.ToGetAudienceCount(this.generalService.isEventID).subscribe((data: any) => {
        this.audienceCount = data.Data;
      });
    })

    this.generalService.isBroadcastStopped.subscribe(res => {
      if (res) {
        this.broadcaststarted = false;
        setTimeout(() => {
          $('.broadcast-btn').trigger('click');
        }, 5000);
      }
    })

    // this.broadcaststop.subscribe(res => {
    //   if (res) {
    //     this.broadcaststarted = false
    //   }
    // })

    //
    this.generalService.questionCount.subscribe(data => {
      if (data) {
        this.questioncount = data.length;;
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.generalService.audienceDataSource$.unsubscribe();
    this.generalService.settingsdata$.unsubscribe();
  }

  //NavBar of audience/chat/QA
  clickMenu() {
    this.isShowNavRightBar = !this.isShowNavRightBar;
    this.hideShowService.setShowNav(this.isShowNavRightBar);
    this.hideShowService.setNavStyle(this.isShowNavRightBar);
    this.generalService.settabindex.next(0)
  }

  /**
   * button click on start broadcast by admin
   */
  startBroadcastByAdmin() {
    this.broadcaststarted = true;
    this.hideShowService.setShowHideModal(true);
  }

  //Stop Broadcast
  async stopBroadcastByAdmin() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    this.generalService.isEventSessionExits$.subscribe(resp => {
      const sessionId = JSON.parse(resp);
      this.generalService.stopBroadCast(this.generalService.isEventID, sessiondata.id)
        .subscribe((broadCastData: any) => {
          this.hideShowService.setShowNav(false);
          this.utilityService.stopbroadcast(sessiondata);
          this.broadcaststarted = false;
        });
    });
  }

  // stopBroadcastDialog(){
  //   this.broadcastCompletedialog.emit()
  // }

  closeLeaveEventDialog() {
    // this.authService.logout();
    this.leaveEventDialog = false;
    if (this.generalService.isRole === RolesEnums.Roles.Admin) {
      this.utilityService.openLinkInSameTab(this.redirectsiteurl);
    } else {
      this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, this.generalService.isRole)
    }
    // if (this.generalService.isRole === RolesEnums.Roles.Audience) {
    //   this.utilityService.openLinkInSameTab(this.redirectsiteurl);
    // } else {
    //   this.utilityService.requestCamera(false, false);
    //   this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    // }
  }

  closeLeaveEventDialogClose() {
    this.leaveEventDialog = false;
  }

  // Mute Speaker/Moderator Audio
  muteAudio() {
    this.isMuteOrUnmuteAudio = false;
    this.utilityService.publisher?.publishAudio(this.isMuteOrUnmuteAudio);
    sessionStorage.setItem('isAudioOn', 'false');
  }

  unMuteAudio() {
    this.isMuteOrUnmuteAudio = true;
    this.utilityService.publisher?.publishAudio(this.isMuteOrUnmuteAudio);
    sessionStorage.setItem('isAudioOn', 'true');
  }

  // Mute Speaker/Moderator Video
  muteVideo() {
    if (this.utilityService.publisher && this.utilityService.publisher.stream) {
      let boxId: any = this.utilityService.publisher.stream.name;
      this.utilityService.publisher.accessAllowed == false
      let userid = parseInt(boxId.split("User_")[1]);
      this.isMuteOrUnmuteVideo = false;
      sessionStorage.setItem('isVideoOn', 'false');
      this.utilityService.publisher?.publishVideo(this.isMuteOrUnmuteVideo);
      if (this.utilityService.publisher.stream.hasVideo) {
        this.utilityService.videooff(boxId, userid);
        let x = document.getElementById(`image-profile_box-profile_${boxId}`);
        x.style.display = 'block';
      }
    }
  }
  unMuteVideo() {
    if (this.utilityService.publisher) {
      let boxId: any = this.utilityService.publisher.stream.name;
      let userid = parseInt(boxId.split("User_")[1]);
      this.isMuteOrUnmuteVideo = true;
      sessionStorage.setItem('isVideoOn', 'true');
      this.utilityService.publisher?.publishVideo(this.isMuteOrUnmuteVideo);
      let x = document.getElementById(`image-profile_box-profile_${boxId}`);
      x.style.display = 'none';
      this.utilityService.videoOn(boxId, userid);

    }
  }

  async audiooutput() {
    this.audioOutputs = await this.utilityService.getaudiooutput();
  }

  //To change output device according to dropdown
  cycleAudioOutput = async (selected) => {
    let deviceId = selected.deviceId;
    await OT.setAudioOutputDevice(deviceId);
  };

  async audiencetospeaker() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    this.isAdminOrAudience = this.generalService.isUserAdminOrAudience
    if (this.isAdminOrAudience) {
      let data = {
        Status: 0,
        userId: this.generalService.isUserID,
        eventID: this.generalService.isEventID,
        typeOfRequest: 2
      }
      this.generalService.cacheAudienceRequest(data).subscribe(response => {
        if (response) {
          this.utilityService.sendSignalTobeSpeaker(sessiondata, data);
        }
      })
    }
  }

  joinrequestdialog() {
    this.joinRequestDialog = true;
  }

  joinrequestdialogc() {
    this.joinRequestDialog = false;
  }

  sharescreenclose() {
    this.shareyourscreen = false
  }

  connectRequestClose() {
    this.connectDialog = false;
  }

  openaudiencetab(data) {
    // console.log(this.eventsetting);
    if (this.eventsetting.isLiveChatEnabled || this.currentEvent.EventSettings.AudienceChat || this.currentEvent.AudienceChat) {
      if (data == 1) {
        this.isShowNavRightBar = !this.isShowNavRightBar;
        this.hideShowService.setShowNav(this.isShowNavRightBar);
        this.hideShowService.setNavStyle(this.isShowNavRightBar);
        this.generalService.settabindex.next(data)
      } else if (data == 2) {
        this.isShowNavRightBar = !this.isShowNavRightBar;
        this.hideShowService.setShowNav(this.isShowNavRightBar);
        this.hideShowService.setNavStyle(this.isShowNavRightBar);
        this.generalService.settabindex.next(data)
      }
    } else {
      if (data == 1) {
        this.isShowNavRightBar = !this.isShowNavRightBar;
        this.hideShowService.setShowNav(this.isShowNavRightBar);
        this.hideShowService.setNavStyle(this.isShowNavRightBar);
        this.generalService.settabindex.next(0)
      } else if (data == 2) {
        this.isShowNavRightBar = !this.isShowNavRightBar;
        this.hideShowService.setShowNav(this.isShowNavRightBar);
        this.hideShowService.setNavStyle(this.isShowNavRightBar);
        this.generalService.settabindex.next(1)
      }
    }
  }

  askQuestiondialogClose() {
    this.askQuestionDialog = false;
  }

  closeSessionByAdmin() {
    this.generalService.isEventSessionExits$.subscribe(async resp => {
      const sessionId: any = await this.utilityService.getSessionConnection();
      this.utilityService.sendSignalToSpeaker(sessionId);
      this.generalService.toStopEventSession(this.generalService.isEventID, sessionId.sessionId).subscribe((res: any) => {
        if (res) {
          this.stopBroadcastByAdmin();
          sessionId.disconnect();
          // this.utilityService.signal(sessionId, 'ended');
          // this.authService.logout();
          if (this.generalService.isRole === RolesEnums.Roles.Admin) {
            this.utilityService.openLinkInSameTab(this.redirectsiteurl);
          } else {
            this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, this.generalService.isRole)
          }
        }
      });
    });
  }

}
