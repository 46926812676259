import { DOCUMENT } from '@angular/common';
import { Component, ComponentFactoryResolver, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import * as OT from '@opentok/client';
import * as _ from 'lodash';
import { combineLatest, Observable, Observer, Subject } from 'rxjs';
import { CommanService } from 'src/app/shared/services/comman.service';
import { RolesEnums } from '../../../../core/enums';
import { UtilityService } from '../../../../core/utilities';
import { AuthService, GeneralService, HideShowService } from '../../../../shared/services';
import { ConnectComponent } from '../../pop-ups/connect-dialog/connect-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-video-screen',
  templateUrl: './user-video-screen.component.html',
  styleUrls: ['./user-video-screen.component.scss'],
  providers: [NgbDropdownConfig],
  host: { 'window:beforeunload': 'doSomething' }
})
export class UserVideoScreenComponent implements OnInit {
  redirectsiteurl = environment.siteurl;
  @Input() isWidthForVideoScreen: string = '100%';
  count: number = 0;
  session: OT.Session;
  isUserCredentials: any;
  broadcast: any = { status: 'waiting', streams: 1, rtmp: false };
  subscriptionData: any;
  val: any;
  userValue: any;
  roleValue: string;
  broadCastStarted: boolean = false;
  sessionCompletedDialog: boolean = false;
  screensharepermission: boolean = false;
  receivedQuestion: Array<string> = [];
  connectDialog: any;
  userid: any;
  role: any;
  publisher: OT.Publisher;
  dataAudienceStore: any[] = []

  @ViewChild(ConnectComponent) connectingDialog: ConnectComponent;
  IsscreenShare: boolean = false;
  removeSpeaker: boolean;
  openRemoveSpeaker: Observable<boolean>;
  @ViewChild('removespeaker', { read: ViewContainerRef }) removespeaker: ViewContainerRef;
  @Output() questionList: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  userDetails: any;
  private unsubscribe$ = new Subject<void>();
  currentEvent: any
  isOk: boolean;
  uDetails: any;
  currentStream: any;
  totaltimediferrence: any;
  isShowNavRightBar: boolean;
  isAdminOrAudience: boolean;
  audienceData: any;
  isAudience: boolean;
  uDetailsconnection: any;
  speakerdetails: any;
  adhocSpeaker = false;
  joinRequestDialog: boolean = false
  IsChat: boolean;
  streamMessageList: any[] = [];
  publisherAdhoc: OT.Publisher;
  adhocOk: boolean = true;
  details: any;
  audio: boolean = true;
  video: boolean = true;
  openFeedbackDialog: boolean = false;
  streamaud: any;
  userDetailsimage: any;
  opendeclineScreenshare: boolean = false;
  alljoined: any[] = [];
  isAdhocUser: any[];

  constructor(
    public generalService: GeneralService,
    public utilityService: UtilityService,
    public authService: AuthService,
    @Inject(DOCUMENT) private doc: any,
    public hideShowService: HideShowService,
    public dialog: MatDialog,
    private componentFactoryresolver: ComponentFactoryResolver,
    public commanService: CommanService,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) { }


  // ngOnInit(): void {
  //   // To fetching the logged in user token
  //   this.generalService.isUserCredentialsExits$.subscribe(async (isUserCredentials: any) => {
  //     if (isUserCredentials && this.utilityService.isObjectNotEmpty(isUserCredentials)) {
  //       this.isUserCredentials = isUserCredentials;
  //       this.generalService.isUserShowDetails$.subscribe((isShowUser: any) => {
  //         if (isShowUser && this.utilityService.isObjectNotEmpty(isShowUser)) {
  //           this.userDetails = isShowUser;
  //           if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
  //             this.utilityService.requestCamera(true, true);
  //           }
  //         }
  //       });
  //       this.addUser(isUserCredentials);
  //     }
  //   });
  //   // this.getuserdetails()
  // }

  async ngOnInit(): Promise<void> {
    // To fetching the logged in user token
    if (sessionStorage.getItem('isUser') === "true") {
      this.generalService.isUserCredentialsExits$.subscribe(async (isUserCredentials: any) => {
        if (isUserCredentials && this.utilityService.isObjectNotEmpty(isUserCredentials)) {
          this.isUserCredentials = isUserCredentials;
          this.generalService.isUserShowDetails$.subscribe((isShowUser: any) => {
            if (isShowUser && this.utilityService.isObjectNotEmpty(isShowUser)) {
              this.userDetails = isShowUser;
              if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
                this.utilityService.requestCamera(true, true);
              }
            }
          });
          this.addUser(isUserCredentials);
        }
      });
    }
    this.getAllQuestion();
    this.generalService.listAllAudience(this.generalService.isEventID, this.generalService.isUserID).subscribe((res: any) => {
      this.alljoined = res.Data.EventMemberList;
      if (this.alljoined) {
        this.isAdhocUser = this.alljoined.filter(x => x.isAdhoc == true)
      }
    })
    // this.close();
  }

  // admin,speaker,moderator
  async addUser(credentials): Promise<any> {
    this.count++;
    let isVideoONOrOff = this.generalService.isVideoOn;
    let isAudioOnOrOff = this.generalService.isAudioOn;
    let isthisAdhocSpeaker: any[] = [];
    var BoxID;
    if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
      this.adhocOk = false
      if (this.isAdhocUser?.length > 0) {
        isthisAdhocSpeaker = this.isAdhocUser.filter(x => x.MemberID == this.generalService.isUserID)
        // this.utilityService.adhocSpeaker = true;
        // this.adhocSpeaker = true;
        if (this.isAdhocUser.length > 0 && isthisAdhocSpeaker.length > 0) {
          this.publisherAdhoc = this.utilityService.initPublisherForAdhoc("appDragg-Box");
          BoxID = "appDragg-Box"
          let node = document.getElementById("appDragg-layout");
          $(node).attr("id", "drag-active-box");
        }
      }

      if (BoxID == "appDragg-Box") {
        BoxID = "appDragg-Box"
      }
      else {
        BoxID = "Box_" + credentials.Token + "User_" + this.generalService.isUserID;
      }
      const usersGrid: any = await this.utilityService.innerHTMLForAllBoxes(BoxID);
      this.utilityService.setHeight(usersGrid, this.count);
      this.publisher = await this.utilityService.initPublisherForOthers(BoxID);
      if (!isVideoONOrOff) {
        this.utilityService.publisher.publishVideo(isVideoONOrOff);
        this.commanService.isVideoOffFromStart.next(true);
        this.utilityService.videooff(BoxID, this.generalService.isUserID)
      }
      if (!isAudioOnOrOff) {
        this.utilityService.publisher.publishAudio(isAudioOnOrOff);
        this.commanService.isAudioOffFromStart.next(true);
      }
      // if (!this.publisher.accessAllowed) {
      //   this.utilityService.publisher.publishVideo(isVideoONOrOff);
      //   this.commanService.isVideoOffFromStart.next(true);
      //   this.utilityService.videooff(BoxID, this.generalService.isUserID)
      // }
    }

    //Initialize Session
    this.session = OT.initSession(credentials.ApiKey, credentials.SessionId);
    this.session.connect(credentials.Token, async (error) => {
      if (error) {
        console.log("Failed to connect: ", error.message);
        if (error.name === "OT_NOT_CONNECTED") {
          alert("You are not connected to the internet. Check your network connection.");
        }
      } else {
        if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
          if (this.activeRoute.snapshot.queryParams.isAdhoc === 'true') {
            this.streamCreatedOfAdhoc(this.publisherAdhoc);
          }
          let data = {
            eventId: this.generalService.isEventID,
            userId: this.generalService.isUserID,
            connectionId: this.session.connection.connectionId
          }
          // To set connection ID in EventUserMapping table in database
          this.generalService.toSaveConnection(data).subscribe();

          this.publishAndSubscribe(this.session, this.publisher, BoxID);
          this.utilityService.messagePublishAndSubscribe(this.session, this.publisher);
        } else if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.Admin) {
          this.setEventListenersHost(this.session);
        } else if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.Audience) {
          this.isAdminOrAudience = this.generalService.isUserAdminOrAudience;
          if (this.isAdminOrAudience) {
            this.audienceData = this.userDetails;
            this.isAudience = this.generalService.isUserAudience;
            if (this.isAudience) {
              let data = {
                eventId: this.generalService.isEventID,
                userId: this.generalService.isUserID,
                connectionId: this.session.connection.connectionId
              }
              // To set connection ID in EventUserMapping table in database
              this.generalService.toSaveConnectionAudience(data).subscribe();
              if (this.utilityService.adhocSpeaker || this.adhocSpeaker) {
                this.streamCreatedOfAdhoc(this.publisherAdhoc)
              }
            }
            this.audience(this.session);
          }
        }
      }
    })
  }

  //Session of Admin and Their recieved Signals
  setEventListenersHost = (session: OT.Session) => {
    // Subscribe to new streams as they're published
    session.on('streamCreated', (event) => {
      var currentStreams = this.broadcast.streams;
      if (!event.stream.hasVideo) {
        let userid = parseInt(event.stream.name.split("User_")[1]);
        this.utilityService.videooff(event.stream.name, userid);

      }
      this.utilityService.subscribeEvent(session, event.stream, true);
      this.broadcast.streams++;
    })

    session.on('streamDestroyed', (event) => {
      console.log(event.stream);
      var currentStreams = this.broadcast.streams;
      this.utilityService.subscribeEvent(session, event.stream, false);
      this.broadcast.streams--;
    });

    session.on('signal:broadcast', (event) => {
      // if (event.data === 'status') {
      //   this.signal(session, this.broadcast.status, event.from);
      // }
    });

    session.on('connectionCreated', (event) => {
      this.generalService.GetBroadcastStartTime(this.generalService.isEventID)
        .subscribe((data: any) => {
          if (data.IsBroadcastStart) {
            this.utilityService.broadCastStartdate = new Date(data.BroadcastStartOn);
            this.utilityService.totaltimediferrence = data.timeelapsed;
          }
        });
    });

    session.on('signal:question', (event) => {
      this.getAllQuestion();
    });

    session.on('signal:audienceDetailsSignal', (event) => {
      // this.generalService.addNewAudience(event['data'])
      this.generalService.updateAudience(event['data'])
    })

    session.on('signal:screenshare', (event) => {
      console.log(event['data']);
      this.screensharepermission = true;
      // this.screenSharePermission();
      this.generalService.boxIdDetails$.next(event['data']);
    })

    session.on('signal:sessionStoped', (event) => {
      if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
        // IF session END
        // this.openSpeakerPopup();
        // this.doc.getElementById("openLeaveEventModel").click();
        // this.sessionCompletedDialog = true;
        // this.hideShowService.setShowNav(this.sessionCompletedDialog);
        // this.utilityService.requestCamera(false, false);
      }
    });

    session.on('signal:removeSpeaker', async (event) => {
      this.generalService.boxIdAdmin(event['data']);
      let userIdEvent = parseInt(event['data'].split("User_")[1]);
      this.details = await this.generalService.getUserDetail(userIdEvent, 'speaker');
      this.generalService.boxIdDetails$.next(this.details);
      if (session.connection.connectionId == event?.['from'].connectionId) {
        this.openRemoveSpeaker = this.hideShowService.getShowHideModal();
        this.openRemoveSpeaker.subscribe((isRemoveSpeker): any => {
          if (isRemoveSpeker) {
            this.removeSpeaker = false;
          } else {
            this.removeSpeaker = true;
          }
        });
      }
    });

    session.on('signal:pollCreatedSignal', (event) => {
      let userIdLocal = this.generalService.isUserID;
      if (userIdLocal == event['data'].UserId) {
         this.generalService.updatepoll(event['data']);
      } else {
        this.generalService.updatepoll(event['data']);
      }
      // this.generalService.addPollOptions(event['data']);
      // this.generalService.updatepoll(event['data']);
    })

    session.on('signal:pollEditedSignal', (event) => {
      // this.generalService.addPollOptions(event['data']);
      this.generalService.updatepoll(event['data']);
    })

    session.on('signal:bespeaker', (event) => {
      this.generalService.addNewAudienceList(event['data'])
      this.generalService.updateAudienceList(event['data'])
      this.joinRequestDialog = true;
    })

    session.on('signal:adhocSpeaker', async (event) => {
      this.adhocSpeaker = true;
      // this.generalService.deleteaudiencedata(event['data']);
      // this.querychange();
    })

    session.on('signal:imagedetails', async (event) => {
      // this.utilityService.publisher.setStyle('backgroundImageURI', event['data']);
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        // x.style.display = 'block';
        x.style.display = 'flex';
        x.style.position = 'absolute';
        x.style.bottom = '0';
        x.style.top = '0';
        x.style.width = '100%';
        x.style.zIndex = '99';
        x.style.alignItems = 'center';
        x.style.justifyContent = 'center';
        x.style.flexDirection = 'column';
        x.style.backgroundColor = '#252525';
        let XT = document.getElementsByClassName('OT_video-poster');
        $(XT).removeClass('OT_video-poster')
      }
    });

    session.on('signal:settingsData', (event) => {
      this.generalService.settingsdata$.next(event['data']);
    })

    session.on('signal:imagedetailsOff', async (event) => {
      // this.utilityService.publisher.setStyle('backgroundImageURI', event['data']);
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        x.style.display = 'none';
      }
    });

    session.on('signal:leaveAudienceSignal', (event) => {
      // console.log(event.reason);
      console.log('audience left event', event['data']);
      this.generalService.audLeaveEvent.next(true);
    });

    session.on('signal:endedBroadcast', (event) => {
      this.generalService.isBroadcastStopped.next(true)
    })

    session.on('signal:adhocSpeakerLeft', (event) => {
      let node = document.getElementById("drag-active-box");
      $(node).attr("id", "appDragg-layout");
    })

  };


  /**
   * For speaker, moderator, audience
   * @param session
   * @param publisher
   */
  publishAndSubscribe = (session: OT.Session, publisher: OT.Publisher, Boxid) => {
    this.receivedQuestion = [];
    let streams = 0;

    session.publish(publisher, function (error) {
      if (error) {
        console.log(error);
      } else {
        console.log('Publishing a stream.');
      }
    });

    // this.utilityService.addPublisherControls(publisher);

    session.on('streamCreated', async (event) => {
      var currentStreams = this.broadcast.streams;
      if (!event.stream.hasVideo) {
        let userid = parseInt(event.stream.name.split("User_")[1]);
        this.utilityService.videooff(event.stream.name, userid);
      }
      this.utilityService.subscribeEvent(session, event.stream, true);
      this.broadcast.streams++;
      // var suboptions={insertMode:'append'};
      // if (event.stream.videoType === 'screen') {
      //   this.utilityService.subscribeEvent(session, event.stream, true, this.uDetails);
      // }
    });


    session.on('streamDestroyed', (event) => {
      // console.log(event.reason);
      this.utilityService.subscribeEvent(session, event.stream, false);
      streams--;
    });

    // Signal the status of the broadcast when requested
    session.on('signal:broadcast', (event) => {
      if (event['data'] === 'status') {
        this.utilityService.signal(session, this.broadcast.status);
      }
      this.generalService.GetBroadcastStartTime(this.generalService.isEventID).subscribe((data: any) => {
        if (data.IsBroadcastStart) {
          this.utilityService.broadCastStartdate = new Date(data.BroadcastStartOn);
          this.utilityService.totaltimediferrence = data.timeelapsed;
        }
      });
    });

    //Speaker Connected...
    session.on('connectionCreated', (event) => {
      let connection: boolean = true;
      this.commanService.changeSession(connection);
      this.generalService.GetBroadcastStartTime(this.generalService.isEventID)
        .subscribe((data: any) => {
          if (data.IsBroadcastStart) {
            this.utilityService.broadCastStartdate = new Date(data.BroadcastStartOn);
            this.utilityService.totaltimediferrence = data.timeelapsed;
          }
        });

    });

    session.on('signal:question', (event) => {
      this.getAllQuestion();
    });

    session.on('signal:screenshare', (event) => {
      if (this.generalService.isRole == 'moderator') {
        this.screenSharePermission();
        this.generalService.boxIdDetails$.next(event['data']);
      }
    })

    session.on('signal:acceptscreenshare', (event) => {
      var audienceId: any = this.activeRoute.snapshot.queryParams.userId;
      let userId = this.generalService.isUserID;
      if (event['data'] == userId) {
        this.utilityService.screenShare();
      }
    })

    session.on('signal:declineScreenshare', (event) => {
      var audienceId: any = this.activeRoute.snapshot.queryParams.userId;
      let userId = this.generalService.isUserID;
      if (event['data'] == userId) {
        this.opendeclineScreenshare = true;
      }
    })

    session.on('signal:removeSpeaker', async (event) => {
      if (this.generalService.isRole === 'moderator') {
        let useridlocal: any = this.generalService.isUserID;
        this.generalService.boxIdAdmin(event['data']);
        let userIdEvent = parseInt(event['data'].split("User_")[1]);
        this.details = await this.generalService.getUserDetail(userIdEvent, 'speaker');
        this.generalService.boxIdDetails$.next(this.details);
        if (session.connection.connectionId == event?.['from'].connectionId && !(useridlocal == userIdEvent)) {
          this.openRemoveSpeaker = this.hideShowService.getShowHideModal();
          this.openRemoveSpeaker.subscribe((isRemoveSpeker): any => {
            if (isRemoveSpeker) {
              this.removeSpeaker = false;
            } else {
              this.removeSpeaker = true;
            }
          });
        }
      }
    });


    session.on('signal:join', (event) => {
      // this.totalViwer = event.data - this.broadcast.streams;
      // this.count = this.totalViwer;
    });

    session.on('signal:sessionStoped', (event) => {
      if (this.generalService.isRole === RolesEnums.Roles.Speaker || this.generalService.isRole === RolesEnums.Roles.Moderator) {
        // this.sessionCompletedDialog = true;
        this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, this.generalService.isRole)
        this.hideShowService.setShowNav(this.sessionCompletedDialog);
        this.utilityService.requestCamera(false, false);
      }
    });



    // to mute Audio of particular user
    session.on('signal:muteAudio', (event) => {
      let userIdEvent = parseInt(event['data'].split("User_")[1]);
      let userIdLocal = this.generalService.isUserID;
      if (userIdLocal == userIdEvent && this.audio) {
        publisher.publishAudio(false);
        this.commanService.muteAudioByAdmin(true);
        this.audio = false;
      } else if (userIdLocal == userIdEvent && !this.audio) {
        this.audio = true;
        publisher.publishAudio(true);
        this.commanService.muteAudioByAdmin(false);
      }
    });

    // to mute Video of particular user
    session.on('signal:muteVideo', (event) => {
      let userIdEvent = parseInt(event['data'].split("User_")[1]);
      let userIdLocal = this.generalService.isUserID;
      if (userIdLocal == userIdEvent && this.video) {
        this.video = false;
        publisher.publishVideo(false);
        this.commanService.muteVideoByAdmin(true);
      } else if (userIdLocal == userIdEvent && !this.video) {
        this.video = true;
        publisher.publishVideo(true);
        this.commanService.muteVideoByAdmin(false);
      }
    });

    session.on('signal:removespeaker', async (event) => {
      let userIdEvent = parseInt(event['data'].split("User_")[1]);
      let userIdLocal = this.generalService.isUserID;
      if (userIdLocal == userIdEvent) {
        this.authService.logout();
      }
    });

    session.on('signal:startedBroadcast', (event) => {
      this.utilityService.isonLine = true;
      this.broadCastStarted = true;
      setTimeout(() => {
        this.broadCastStarted = false;
      }, 10000);
      this.generalService.brodcasstart.next(true);
    });

    // Code commented as it is not needed for demo3

    session.on('signal:pollCreatedSignal', (event) => {
      let userIdLocal = this.generalService.isUserID;
      let Role = this.generalService.isRole;
      // if (Role == RolesEnums.Roles.Moderator && userIdLocal == event['data'].UserId) {
      //   this.generalService.updatepoll(event['data']);
      // } else if (Role == RolesEnums.Roles.Moderator && !(userIdLocal == event['data'].UserId)) {
      //   this.generalService.updatepoll(event['data']);
      // } else if (Role == RolesEnums.Roles.Speaker) {
      //   // this.generalService.addPollOptions(event['data']);
      //   this.generalService.updatepoll(event['data']);
      // }
      this.generalService.updatepoll(event['data']);
    })

    session.on('signal:pollEditedSignal', (event) => {
      let userIdLocal = this.generalService.isUserID;
      let Role = this.generalService.isRole;
      // if (Role == RolesEnums.Roles.Moderator && userIdLocal == event['data'].UserId) {
      //   this.generalService.updatepoll(event['data']);
      // } else if (Role == RolesEnums.Roles.Moderator && !(userIdLocal == event['data'].UserId)) {
      //   this.generalService.updatepoll(event['data']);
      // } else if (Role == RolesEnums.Roles.Speaker) {
      //   // this.generalService.addPollOptions(event['data']);
      //   this.generalService.updatepoll(event['data']);
      // }
      this.generalService.updatepoll(event['data']);
    })

    session.on('signal:adhocSpeaker', async (event) => {
      this.adhocSpeaker = true;
      // this.generalService.deleteaudiencedata(event['data']);
      console.log("audience to speaker signal");
      // this.querychange();
    })
    session.on('signal:makemoderator', (event) => {
      console.log(event);
      let userIdEvent = event['data'].split("User_")[1];
      let userIdEventInt = parseInt(userIdEvent);
      this.activeRoute.queryParams.subscribe(async (queryURL) => {
        let userID = parseInt(queryURL['userId']);
        if (userIdEventInt === userID) {
          let newrole = 'moderator';
          let currenturl = null;
          this.activeRoute.queryParams.subscribe(async (queryURL) => {
            let role = queryURL['role'];
            if (role === 'speaker') {
              role = newrole
            }
            this.router.navigate([], {
              queryParams: {
                role: newrole,
                userId: parseInt(queryURL.userId),
                eventId: parseInt(queryURL.eventId)
              },
            })
            window.location.reload();
          })
          console.log('signal of make moderator received.');
        }
      })

    })



    session.on('signal:imagedetails', async (event) => {
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        // x.style.display = 'block';
        x.style.display = 'flex';
        x.style.position = 'absolute';
        x.style.bottom = '0';
        x.style.top = '0';
        x.style.width = '100%';
        x.style.zIndex = '99';
        x.style.alignItems = 'center';
        x.style.justifyContent = 'center';
        x.style.flexDirection = 'column';
        x.style.backgroundColor = '#252525';
        let XT = document.getElementsByClassName('OT_video-poster');
        $(XT).removeClass('OT_video-poster')
      }

    })

    session.on('signal:imagedetailsOff', async (event) => {
      // this.utilityService.publisher.setStyle('backgroundImageURI', event['data']);
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        x.style.display = 'none';
      }
    });

    session.on('signal:settingsData', (event) => {
      this.generalService.settingsdata$.next(event['data']);
    })

    session.on('signal:endedBroadcast', (event) => {
      this.generalService.isBroadcastStopped.next(true)
    })

    session.on('signal:audienceDetailsSignal', (event) => {
      // this.generalService.addNewAudience(event['data'])
      this.generalService.updateAudience(event['data'])
    })

    session.on('signal:adhocSpeakerLeft', (event) => {
      let node = document.getElementById("appDragg-layout");
      $(node).attr("id", "drag-active-box");
    })

    session.on('signal:leaveAudienceSignal', (event) => {
      // console.log(event.reason);
      console.log('audience left event', event['data']);
      this.generalService.audLeaveEvent.next(true);
    });


  }

  //Audience stream
  audience = (session: OT.Session) => {
    let streams = 0;
    session.on('streamCreated', (streamEvent) => {
      this.streamaud = streamEvent
      console.log("stream for audience", streamEvent.stream);
      if (!streamEvent.stream.hasVideo) {
        let userid = parseInt(streamEvent.stream.name.split("User_")[1]);
        this.utilityService.videooff(streamEvent.stream.name, userid)
      }
      let aud: any;
      this.generalService.listAllAudience(this.generalService.isEventID, this.audienceData.userID).subscribe((audDetails: any) => {
        let audienceData = audDetails.Data.EventMemberList;
        for (let i = 0; i < audienceData?.length; i++) {
          let x = audienceData[i].FirstName + " " + audienceData[i].LastName;
          if (x == this.userDetails.userName) {
            aud = audienceData[i]
          }
        }
        // this.generalService.audienceDataSource$.next(aud);
        this.utilityService.createSignalForAudienceUserDetails(this.session, aud);
      })
      this.utilityService.subscribeEvent(session, streamEvent.stream, true);
      if (streamEvent.stream['channel']) {
        streamEvent.stream['channel'].forEach(ok => {
          if (!ok.active) {

            let x = document.getElementById(`image-profile_box-profile_${streamEvent.stream.name}`);
            x.style.display = 'block';
            let XT = document.getElementsByClassName('OT_video-poster');
            $(XT).removeClass('OT_video-poster')
          }
        })
      }
      // event['data'] = null;
    });


    session.on('signal:audienceDetailsSignal', async (event) => {
      // this.generalService.addNewAudience(event['data'])
      this.generalService.updateAudience(event['data'])
    })


    //Recieve signal on network request
    session.on('signal:networkRequest', (event) => {
      console.log("signal received::::::==>", event['data']);
      this.generalService.networkRequestList$.next(event['data']);
    });
    session.on('streamDestroyed', (event) => {
      // console.log(event.reason);
      this.utilityService.subscribeEvent(session, event.stream, false);
      streams--;
    });

    session.on('signal:leaveAudienceSignal', (event) => {
      // console.log(event.reason);
      console.log('audience left event', event['data']);
      this.generalService.audLeaveEvent.next(true);
    });


    session.on('signal:pollCreatedSignal', (event) => {
      // this.generalService.addnewpoll(event['data']);
      this.generalService.updatepoll(event['data']);
    })

    session.on('signal:pollEditedSignal', (event) => {
      // this.generalService.addnewpoll(event['data']);
      this.generalService.updatepoll(event['data']);
    })

    session.on('signal:adhocSpeaker', async (event) => {
      let userid = this.generalService.isUserID;
      console.log('==========================', event['data']);
      if (event['data'].RequestSendById == userid) {
        this.adhocSpeaker = true
        this.utilityService.adhocSpeaker = true;
        // this.generalService.deleteaudiencedata(event['data']);
        let newRole = 'speaker';
        this.activeRoute.queryParams.subscribe(async (params) => {
          let role = params['role']
          if (role === 'audience') {
            role = newRole
          }
          this.router.navigate([], {
            queryParams: {
              role: role,
              userId: parseInt(params.userId),
              eventId: parseInt(params.eventId),
              // isAdhoc: true
            },
          })
          if (this.activeRoute.snapshot.queryParams.role === 'speaker') {
            window.location.reload();
          }
        })
      }
    })

    // this.utilityService.messagePublishAndSubscribe(session,this.publisher)

    session.on('signal:sessionStoped', (event) => {
      if (this.generalService.isRole === RolesEnums.Roles.Audience) {
        // this.sessionCompletedDialog = true;
        this.hideShowService.setShowNav(this.sessionCompletedDialog);
        // this.utilityService.openLinkInSameTab(this.redirectsiteurl);
        this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, RolesEnums.Roles.Audience)
        this.utilityService.requestCamera(false, false);
      }
    });

    session.on('signal:settingsData', (event) => {
      this.generalService.settingsdata$.next(event['data']);
    })

    session.on('signal:startedBroadcast', (event) => {
      this.utilityService.isonLine = true;
      this.broadCastStarted = true;
      this.generalService.brodcasstart.next(true);
    });

    session.on('signal:endedBroadcast', (event) => {
      this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, RolesEnums.Roles.Audience)
      this.utilityService.subscribeEvent(session, this.streamaud.stream, false);
    })

    session.on('signal:imagedetails', async (event) => {
      // this.utilityService.publisher.setStyle('backgroundImageURI', event['data']);
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        // x.style.display = 'block';
        x.style.display = 'flex';
        x.style.position = 'absolute';
        x.style.bottom = '0';
        x.style.top = '0';
        x.style.width = '100%';
        x.style.zIndex = '99';
        x.style.alignItems = 'center';
        x.style.justifyContent = 'center';
        x.style.flexDirection = 'column';
        x.style.backgroundColor = '#252525';
        let XT = document.getElementsByClassName('OT_video-poster');
        $(XT).removeClass('OT_video-poster')
      }
    });

    session.on('signal:imagedetailsOff', async (event) => {

      // this.utilityService.publisher.setStyle('backgroundImageURI', event['data']);
      console.log('*******', event);
      let userIdEvent = parseInt(event['data'].boxId.split("User_")[1]);
      let userIdLocal = event['data'].userId;
      if (userIdEvent == userIdLocal) {
        let x = document.getElementById(`image-profile_box-profile_${event['data'].boxId}`);
        x.style.display = 'none';
      }
    });

    session.on('signal:question', (event) => {
      this.getAllQuestion();
    });

    session.on('signal:adhocSpeakerLeft', (event) => {
      let node = document.getElementById("drag-active-box");
      $(node).attr("id", "appDragg-layout");
    })

  }


  //=========================================================


  sessionCompleteDialog() {
    this.sessionCompletedDialog = false;
    this.hideShowService.setShowNav(this.sessionCompletedDialog);
  }

  screenSharePermission() {
    this.screensharepermission = true;
  }

  screenSharePermissionClose() {
    this.screensharepermission = false;
  }

  //Get List of questions asked by audience
  getAllQuestion() {
    this.generalService.getEventQuestionListById(this.generalService.isEventID).subscribe((data: any) => {
      this.generalService.questionList$.subscribe((question) => {
        question = data;
        if (question && question.length > 0) {
          this.questionList.emit(question);
          this.generalService.questionCount.next(question);
        }
      });
    })

  }

  requestdialog() {
    this.joinRequestDialog = true;
  }

  removeSpeakerCloseDialog() {
    this.removeSpeaker = false
  }

  //Stream of new adhoc Speaker
  async streamCreatedOfAdhoc(publisher) {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    sessiondata.publish(publisher, function (error) {
      if (error) {
        console.log(error);
      } else {
        console.log('Publishing a stream.');
      }
    });

  }

  doSomething(e) {
    e.preventDefault();
    e.returnValue = "Do you really want to close?";
    let pageReloaded = window.performance
      .getEntriesByType('navigation')
      .map((nav) => (nav as any).type)
      .includes('reload');
    if (!pageReloaded) {
      if (this.generalService.isRole == 'admin') {
        this.generalService.isEventSessionExits$.subscribe(async resp => {
          const sessionId: any = await this.utilityService.getSessionConnection();
          this.utilityService.sendSignalToSpeaker(sessionId);
          this.generalService.toStopEventSession(this.generalService.isEventID, sessionId.sessionId).subscribe((res: any) => {
            if (res) {
              sessionId.disconnect();
              this.utilityService.signal(sessionId, 'ended');
              this.authService.logout();
              sessionStorage.clear();
              this.utilityService.openLinkInSameTab(this.redirectsiteurl);
            }
          });
        });
        e.preventDefault();
      } else if (this.generalService.isRole == 'speaker') {
        sessionStorage.clear();
        this.utilityService.changeBoxesAfterLeaveEvent();
      } else if (this.generalService.isRole == 'audience') {
        sessionStorage.getItem('isUser') === "false"
        this.utilityService.openLinkInSameTab(this.redirectsiteurl);
        sessionStorage.clear();
      }
    }
    return 'Do you really want to close?';

  }

  async whenvideoOff() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    var subscriber = sessiondata.subscribe(event['stream'], 'subscriberElement', function (error) {
      if (error) {
        console.log(error.message)
        return;
      }
      if (subscriber.stream.hasVideo) {
        var imgData = subscriber.getImgData();
        subscriber.setStyle('backgroundImageURI', imgData);
      } else {
        subscriber.setStyle('backgroundImageURI',
          'https://tokbox.com/img/styleguide/tb-colors-cream.png'
        );
      }
    });
  }

}
