import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';

@Component({
  selector: 'app-join-speaker-dialog',
  templateUrl: './join-speaker-dialog.component.html',
  styleUrls: ['./join-speaker-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class HowToJoinComponent implements OnInit {

  title: string;
  closeBtnName: string;
  list: any[] = [];
  @Input() id: string;

  constructor() { }

  ngOnInit() {
    this.list.push('PROFIT!!!');
    console.log(this.id)
  }

}
