<div class="modal-header">
  <h4 class="pull-left">Your Feedback</h4>
</div>
<div class="modal-body">
  <div class="row">
    <form [formGroup]="feedbackForm">
      <div *ngIf="!openNextFeedbackDialog" class="col-md-12 col-sm-12  pb-2 text-center feedback-layout">
        <h4 class="mb-1 mt-2 mb-3 grotesk-medium font-14 line-height-20 color-black">
          Thank you for your virtual attendance at today's event.
        </h4>
        <p class="font-14 gibson-book-font line-height-20 color-black">
          Please take a few moments to provide input on your
          experience. The information you submit will help
          us conduct more efficient, insightful and
          effective events.
        </p>
        <div class="feedback-smiley d-flex mt-3 mb-3 justify-content-center" >
          <div *ngFor="let item of rateArray" class="count-number-box d-flex form-group">
            <div class="feedback-wrapper justify-content-center align-items-end" (click)="select(item)"
              [ngClass]="{'feedback-value-active': isActive(item)}">
              <h2 class="font-16 line-height-17 color-black gibson_medium" (click)="onClickRate(item)"> {{item}} </h2>
            </div>
          </div>
        </div>
        <div class="error" *ngIf="showRatingerror">
          Rating is required.
        </div>
        <div class="text-center col-sm-12">
          <button type="button" class="btn-common gibson-book-font font-14 line-height-24 mb-3"
            (click)="openDialogforNextFeedback()">
            Next
          </button>
        </div>
      </div>

      <div *ngIf="openNextFeedbackDialog" class="col-md-12 col-sm-12 question-ans">
        <div class="row">
          <div class="radio-button-wrapper1 col-lg-12 col-md-12 col-sm-12">
            <p class="grotesk-medium font-14 line-height-20 color-black">
              {{ feedbackQuestion[0].Question }}*
            </p>
            <div class="form-group" *ngFor="let answer of feedbackQuestion[0]?.['Answer']">
              <input type="checkbox" id="Answer{{answer.AnswerID}}" formControlName="AnswerID" [value]="answer.AnswerID"
                [checked]="answer.isSelected" (change)="isAllSelected(answer.AnswerID)" >
              <label class="gibson-book-font font-14 line-height-14 color-black opacity-0-8"
                for="Answer{{answer.AnswerID}}">
                {{ answer.Title }}
              </label>
            </div>
            <div *ngIf="submmited && feedbackFormControl.AnswerID.errors?.required" class="error">
              Checkbox is required.
            </div>
          </div>
          <div class="radio-button-wrapper1 col-lg-12 col-md-12 col-sm-12">
            <p class="mt-3 grotesk-medium font-14 line-height-20 color-black">
              Would you like to tell us anything?*
            </p>
            <div class="mx-auto texteatra-wrapper mb-3 form-group">
              <textarea class="form-control" formControlName="FeedbackComment" placeholder="Type your feedback here..."
                rows="5"></textarea>
              <div *ngIf="submmited && feedbackFormControl.FeedbackComment.errors" class="error">
                <div *ngIf="submmited && feedbackFormControl.FeedbackComment.errors">
                  Feedback is required.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center col-sm-12">
          <button type="button" class="btn-common gibson-book-font font-14 line-height-24 mb-3" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
