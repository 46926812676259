import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, GeneralService } from '../../../shared/services';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/core/utilities';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  isLoggedIn$: Observable<boolean>;
  currentRoute: any;
  cookiesUrl = environment.cookiesurl;
  privacyUrl = environment.privacyurl;
  termsconditionUrl = environment.termsconditionurl;
  securityUrl = environment.securityurl;


  constructor(
    public authService: AuthService,
    public generalService: GeneralService,
    private router: Router,
    public utilityService:UtilityService
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event && event.url) {
        this.currentRoute = event.url.includes("join-event");
      }
    });
    this.isLoggedIn$ = combineLatest([
      this.authService.isLoggedInOrNot,
      this.generalService.isEventExits$.asObservable()])
      .pipe(map(([isUser, event]) => ((isUser && event) ? isUser && event : false)));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  cookiesurl(){
    this.utilityService.openLinkInNewTab(this.cookiesUrl)
  }

  privacyurl(){
    this.utilityService.openLinkInNewTab(this.privacyUrl)
  }

  termsconditionsurl(){
    this.utilityService.openLinkInNewTab(this.termsconditionUrl)
  }

  securityurl(){
    this.utilityService.openLinkInNewTab(this.securityUrl)
  }

}
