import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { MaterialModule } from "src/app/material-module";
import { SharedModule } from "../../shared/shared.module";
import { PopupsModule } from "../pages/pop-ups/popups.module";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { VideoFooterBarComponent } from "./video-footer-bar/video-footer-bar.component";
import { VideoHeaderBarComponent } from "./video-header-bar/video-header-bar.component";
import { ConnectedUsersModule } from "../pages/connected-users/connected-users.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PagenotfoundComponent,
    VideoHeaderBarComponent,
    VideoFooterBarComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PagenotfoundComponent,
    VideoHeaderBarComponent,
    VideoFooterBarComponent
  ],
  imports: [
    SharedModule,
    PopupsModule,
    NgbDropdownModule,
    MaterialModule,
    ConnectedUsersModule,
    NgbModule
  ],
})
export class LayoutsModule { }
