import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WaitingScreenRoutingModule } from './waiting-screen-routing.module';
import { WaitingScreenComponent } from './waiting-screen.component';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [WaitingScreenComponent],
  imports: [
    CommonModule,
    WaitingScreenRoutingModule,
    SharedModule
  ],
})
export class WaitingScreenModule { }
