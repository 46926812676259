import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-camera-information-dialog',
  templateUrl: './camera-information-dialog.component.html',
  styleUrls: ['./camera-information-dialog.component.scss']
})
export class CameraInformationDialogComponent implements OnInit {
  @Output() closCameraInformationdialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

}
