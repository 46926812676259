import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RolesEnums } from 'src/app/core/enums';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
// import { pronouns } from 'src/app/core/enums/pronouns.enums';

@Component({
  selector: 'app-connect-dialog',
  templateUrl: './connect-dialog.component.html',
  styleUrls: ['./connect-dialog.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: false, noPause: false, showIndicators: false } }
  ],
})
export class ConnectComponent implements OnInit {

  title: string;
  closeBtnName: string;
  list: any[] = [];
  connectRequestList: any[] = [];
  selectedItemID: any;
  selectedItem: any;
  currentindex: number = 0;
  isUserCredentials: any;
  memberId: any;
  activeSlide: number = 0;
  @Output() connectRequestDialogc: EventEmitter<boolean> = new EventEmitter<boolean>();
  pronouns = [
    '',
    'She/Her',
    'He/Him',
    'They/Them',
    'Add Custom Pronoun'
  ]
  pronounOfUser: any;


  constructor(public generalService: GeneralService, public utilityService: UtilityService) { }

  ngOnInit() {
    this.generalService.isUserCredentialsExits$.subscribe((isUserCredentials: any) => {
      if (isUserCredentials && this.utilityService.isObjectNotEmpty(isUserCredentials)) {
        this.isUserCredentials = isUserCredentials;
        this.getConnectionRequest(isUserCredentials);
        this.pronouns.forEach(res => {
          this.connectRequestList.forEach(element => {
            if (element.pronouns == this.pronouns.indexOf(res)) {
              this.pronounOfUser = this.pronouns[element.pronouns];
              if (this.pronounOfUser == "Add Custom Pronoun") {
                this.pronounOfUser = element.otherpronoun;
              }
            }
          });
        })

      }
    });
    // this.acceptDeclineConcection(this.requestid,this.statusvalue)
  }

  getConnectionRequest(credentials) {
    // Get user id on basis of their role
    if (credentials.roleNumber === RolesEnums.RoleType.Audience ||
      credentials.roleNumber === RolesEnums.RoleType.Admin) {
      this.memberId = this.generalService.isUserID;
    } else if (credentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
      this.memberId = this.generalService.isUserDetails.ID;
    }
    let userData = {
      memberID: this.memberId,
      eventID: this.generalService.isEventID
    }
    this.generalService.networkRequestList$.subscribe((audienceReqList: any) => {
      this.generalService.getConnectRequestListById(userData.memberID, userData.eventID).subscribe((response: any) => {
        if (response && response.Data.length > 0) {
          audienceReqList = response.Data;
          this.connectRequestList = audienceReqList;
          this.pronouns.forEach(res => {
            this.connectRequestList.forEach(element => {
              if (element.pronouns == this.pronouns.indexOf(res)) {
                this.pronounOfUser = this.pronouns[element.pronouns];
                if (this.pronounOfUser == "Add Custom Pronoun") {
                  this.pronounOfUser = element.otherpronoun;
                }
              }
            });
          })
        }
      });
    })

  }

  acceptDeclineConcection(requestid: number, statusValue: number) {
    let data = {
      eventID: this.generalService.isEventID,
      status: statusValue,
      requestID: requestid
    }
    if (data.status == 1 || data.status == 2) {
      for (let i: any = 0; i <= this.connectRequestList.length; i++) {
        if (this.connectRequestList[i].id == requestid) {
          this.connectRequestList.splice(i, 1);
          break;
        }
      }
    }
    this.generalService.setConnectRequestStatus(data).subscribe();
    this.connectRequestDialogc.emit();
  }

  // Open links of requested audience
  onNavigate(link: any) {
    window.open("//" + link, '_blank');
  }


}
