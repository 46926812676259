import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { Observable } from 'rxjs';
import { RolesEnums } from 'src/app/core/enums';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService, HideShowService } from 'src/app/shared/services';

@Component({
  selector: 'join-event-request-dialog',
  templateUrl: './join-event-request-dialog.component.html',
  styleUrls: ['./join-event-request-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class JoinEventRequestComponent implements OnInit, OnDestroy {
  openRemoveSpeaker: Observable<boolean>;
  title: string;
  closeBtnName: string;
  audienceDetails: any;
  audiencetospeakerdetails: any[] = [];
  list: any[] = [];
  audience: any[] = [];
  audienceCount: any = 0;
  accepted: boolean = false
  newadhocSpeaker: any;
  display = 'block';
  closed: boolean = true;
  id: string;
  @Output() joinRequestDialogc: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public generalService: GeneralService,
    public utilityService: UtilityService,
    public hideShowService: HideShowService,
  ) { }


  async ngOnInit() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    this.generalService.audienceToSpeakerlist$.subscribe((audiencedata: any) => {
      this.generalService.listOfAudience(this.generalService.isEventID).subscribe((data: any) => {
        let ndata = data.filter(x => x.TypeofRequest == 'Speaker')
        this.audiencetospeakerdetails = ndata;
        if (audiencedata && this.utilityService.isObjectNotEmpty(audiencedata)) {
          let updatedData = audiencedata.filter(x => x.TypeofRequest == 'Speaker')
          this.audiencetospeakerdetails.push(updatedData);
        }
      })
    })

    this.generalService.audienceDataSource$.subscribe((audiencenumber) => {
      if (audiencenumber && this.utilityService.isObjectNotEmpty(audiencenumber)) {
        this.audience.push(audiencenumber);
        this.audienceCount = this.audience.length
      }
    })
  }

  ngOnDestroy() {
    this.generalService.adhocSpeaker$.unsubscribe();
  }

  async onacceptrequest(id) {
    this.accepted = true;
    for (let i = 0; i < this.audiencetospeakerdetails.length; i++) {
      if (i == id) {
        this.utilityService.adhocSpeaker = true;
        let adhocSpeakerDetails = this.audiencetospeakerdetails[i];
        let sessiondata: any = await this.utilityService.getSessionConnection();
        this.generalService.deleteaudiencedata(this.audiencetospeakerdetails, adhocSpeakerDetails)
        this.generalService.adhocSpeaker$.subscribe(adhocspeaker => {
          adhocspeaker = adhocSpeakerDetails;
        })
        this.generalService.adhocSpeaker$.next(adhocSpeakerDetails);
        this.generalService.isAdhocSpeaker({ UserID: adhocSpeakerDetails.RequestSendById, EventID: this.generalService.isEventID }).subscribe(res => {
          console.log(res);
          this.utilityService.sendSignalForAudienceToSpeaker(sessiondata, adhocSpeakerDetails);
        })
      }
    }
  }

  declineRequest() {
    this.joinRequestDialogc.emit();
  }

}
