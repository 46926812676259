<div class="modal-header">
    <h4 class="pull-left">Remove Speaker</h4>
</div>
<div class="modal-body">
    <div class="caption-body" *ngIf="details">
        <h4 class="grotesk-medium font-14 line-height-20 color-black caption-body-speaker text-center">Are you sure you
            want to remove {{details.userName}}
            from this Discussion?</h4>
    </div>
    <div class="col-12 text-center mt-4 mb-3" *ngIf="!isOk">
        <button type="button" class="btn-border-outline gibson_medium font-14 line-height-24" (click)="cancle();">No,Cancel</button>
        &nbsp;
        <button type="button" class="btn-common gibson_medium font-14 line-height-24" (click)="removeUserFromEvent()">Remove</button>
    </div>

    <div class="sucess-alert-speaker" *ngIf="isOk">
        <img src="assets/images/right-tick.png" alt="Tick" class="mr-3">
        <p class="font-14 gibson-book-font line-height-24 letter-spacing-0-2 mb-0">You’ve removed {{details.userName}} from this
            discussion</p>
    </div>

</div>