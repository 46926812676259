<div class="container" id="container">
  <div class="modal-header">
    <h4 class="pull-left">Join Speaker Request</h4>
  </div>
  <div class="modal-body px-4" class="pop-up-overflow">
    <div class="request-user-wrapper" *ngIf="audiencetospeakerdetails && audiencetospeakerdetails.length>0">
      <ng-container *ngFor="let item of audiencetospeakerdetails;let i=index">
        <div class="row mb-3" *ngIf="item.TypeofRequest=='Speaker'">
          <div class="col-lg-6">
            <div class="d-flex justify-flex-start align-items-center">
              <!-- <div class="req-user-image mr-2">
            <img src="${item?.ProfilePicture}" />
          </div> -->
              <div class="user-name-caption-req">
                <app-avatar-photo *ngIf="item !== undefined" [photoUrl]="item?.ProfilePicture" [name]="item?.Name"
                  round="false" class="avtar-listing-icon">
                </app-avatar-photo>
              </div>
              <div class="mx-3 grotesk-medium font-14 line-height-20 color-black">
                {{item?.Name}}
                <span class="circle-dot"></span>
              </div>
              <div class="req-user-position d-flex align-items-center">
                <span class="chat-dot"></span>
                <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">{{item?.CompanyName}}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-right p-0 d-flex justify-content-end align-items-center">
            <button type="button" class="btn-border-outline mr-2 font-12 line-height-24 gibson_medium"
              (click)="declineRequest()">Decline</button>
            &nbsp;
            <button type="button" class="btn-common font-12 line-height-24 gibson_medium"
              style="margin-right: 8px;" (click)="onacceptrequest(i)">Accept</button>
            <!-- Remove Button Condition Wise Manage -->
            <!-- <a href="#" class="font-14 line-height-14 red-light gibson_medium remove-btn">Remove</a> -->
            <!-- End -->
          </div>
        </div>
      </ng-container>

    </div>
    <div class="no-speaker-req" *ngIf="audiencetospeakerdetails.length==0">
      No Join Speaker Request Available Yet
    </div>

    <!-- <div class="request-user-wrapper mb-3">
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex justify-flex-start align-items-center">
          <div class="req-user-image mr-2">
            <img src="/assets/images/event-join2.png" />
          </div>
          <div class="grotesk-medium font-14 line-height-20 color-black">
            Greg S.
          </div>
          <div class="req-user-position d-flex align-items-center">
            <span class="chat-dot"></span>
            <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Maxwell Insurance</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 text-right p-0 d-flex justify-content-end align-items-center">
        <button type="button" class="btn-border-outline mr-2 font-12 line-height-24 gibson_medium">Decline</button>
        &nbsp;
        <button type="button" class="btn-common font-12 line-height-24 gibson_medium">Accept</button>
      </div>
    </div>
  </div> -->

    <!-- <div class="request-user-wrapper mb-3">
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex justify-flex-start align-items-center">
          <div class="req-user-image mr-2">
            <img src="/assets/images/event-join1.png" />
          </div>
          <div class="grotesk-medium font-14 line-height-20 color-black">
            Arthur Stephan
          </div>
          <div class="req-user-position d-flex align-items-center">
            <span class="chat-dot"></span>
            <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Samsung</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 text-right p-0 d-flex justify-content-end align-items-center">
        <button type="button" class="btn-border-outline mr-2 font-12 line-height-24 gibson_medium">Decline</button>
        &nbsp;
        <button type="button" class="btn-common font-12 line-height-24 gibson_medium">Accept</button>
      </div>
    </div>
  </div> -->

    <!-- <div class="request-user-wrapper mb-2">
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex justify-flex-start align-items-center">
          <div class="req-user-image mr-2">
            <img src="/assets/images/event-join2.png" />
          </div>
          <div class="grotesk-medium font-14 line-height-20 color-black">
            Martin Garix
          </div>
          <div class="req-user-position d-flex align-items-center">
            <span class="chat-dot"></span>
            <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">Discussion Box</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 text-right p-0 d-flex justify-content-end align-items-center">
        <button type="button" class="btn-border-outline mr-2 font-12 line-height-24 gibson_medium">Decline</button>
        &nbsp;
        <button type="button" class="btn-common font-12 line-height-24 gibson_medium">Accept</button>
      </div>
    </div>
  </div> -->

    <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="alert-layout-drp-layout" *ngIf="accepted==true">
    <div class="alert-body">
      <img src="assets/images/tick-green.svg" alt="Sucess" class="img-fluid">
      <p class="mb-0 ml-3 color-white gibson-book-font font-14 line-height-24 letter-spacing-0-2">Your
        request to join speakers has been accepted</p>
    </div>
  </div> -->
  </div>
</div>
