<!-- <div class="grid" [ngStyle]="{'width': isWidthForVideoScreen}">

  <div class="grid-item">
    <div class="box-profile">
      <div class="d-flex justify-content-between align-items-center">
        <div class="box-tooltip">
          <a href="#" class="text-decoration-none" data-toggle="tooltip" title="Moderator">
            <span><img src="assets/images/m-icon.svg" alt="Icon" class="img-fluid"></span>
          </a>
        </div>
        <div ngbDropdown class="user-layout-drp">
          <button class="btn-primary p-0 user-btn-drp p-0 bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
            <img src="assets/images/menu-white.svg" alt="Menu" class="rounded-0" />
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem>Unmute Audio
              <img src="assets/images/micro-black.png" alt="video" class="img-fluid w-auto" />
            </button>
            <button ngbDropdownItem>Unmute Video
              <img src="assets/images/video-back.png" alt="video" class="img-fluid w-auto" />
            </button>
            <button ngbDropdownItem>Make Moderator</button>
            <button ngbDropdownItem>Remove Speaker</button>
          </div>
        </div>
      </div>
      <div class="customer-profile-img">
        <img src="assets/images/video-user-1.png" alt="User" class="img-fluid">
      </div>
      <div class="custom-user-caption pb-2 pt-2 p-4 d-flex justify-content-between align-items-center">
        <div>
          <p class="font-14 grotesk-medium color-white mb-0">Sam Smith</p>
          <p class="font-12 gibson-book-font color-white mb-0">Marketing Manager</p>
          <p class="font-12 gibson-book-font color-white mb-0">Apple Co.</p>
        </div>
        <div><img src="assets/images/logo-user.png" class="img-fluid" alt="logo"></div>
      </div>
    </div>
  </div>

  <div class="grid-item">
    <div class="box-profile">
      <div class="customer-profile-img">
        <img src="assets/images/video-user-2.png" alt="User" class="img-fluid">
      </div>
    </div>
  </div>

  <div class="grid-item">
    <div class="li-stream-blank">
      <div class="coustom-user-video-none position-relative">
        <div class="user-name-caption mb-3">
          <app-avatar-photo [photoUrl]="null" [name]="'Sarah Miller'" round="false"
            class="avtar-listing-icon user-add-layout">
          </app-avatar-photo>
        </div>
        <div class="user-sub-info text-center">
          <h4 class="font-20 grotesk-medium line-height-26 color-white mb-0">Sarah Miller</h4>
          <p class="gibson-book-font font-16 color-white mt-1 mb-2 line-height-16">Frontend Engineer,</p>
          <h3 class="gibson_medium font-16 line-height-16">Virgin Atlantic</h3>
        </div>
      </div>
    </div>
  </div>

</div> -->


<div id="pgrid" class="pgrid" [ngStyle]="{'width': isWidthForVideoScreen}">
  <div id="grid" class="grid" style="flex-wrap: wrap;"></div>
  <!-- <div class="screen-share" id="screen-share">
    <div id="screen-preview" class="screen-preview"></div>
  </div> -->
</div>

<!-- commented this because it was not in demo 3 -->
<div class="appDragg-layout" id="appDragg-layout" appDraggable>
  <div id="appDragg-Box"></div>
</div>


<app-dialog [(visible)]="sessionCompletedDialog" [dialogClass]="'popup-width-516'" [closable]="false">
  <app-session-complete-dialog (sessionCompleteDialog)="sessionCompleteDialog()"></app-session-complete-dialog>
</app-dialog>

<app-dialog [(visible)]="removeSpeaker" [dialogClass]="'popup-width-618'" [closable]="true">
  <app-remove-speaker-dialog #removespeaker (removeSpeakerDialog)="removeSpeakerCloseDialog()">
  </app-remove-speaker-dialog>
</app-dialog>

<app-dialog [(visible)]="screensharepermission" [dialogClass]="'popup-width-618'" [closable]="true">
  <app-screensharepermission (screensharepermission)="screenSharePermission()" (screensharepermissionClose)="screenSharePermissionClose()"></app-screensharepermission>
</app-dialog>

<app-dialog [(visible)]="joinRequestDialog" [dialogClass]="'popup-width-618'">
  <join-event-request-dialog (joinRequestDialog)="requestdialog()"></join-event-request-dialog>
</app-dialog>

<app-dialog [(visible)]="broadCastStarted" [dialogClass]="'popup-width-618'">
  <app-connecting-dialog></app-connecting-dialog>
</app-dialog>

<app-dialog [(visible)]="openFeedbackDialog" [dialogClass]="'popup-width-516'">
  <app-feedback-content-dialog></app-feedback-content-dialog>
</app-dialog>

<app-dialog [(visible)]="opendeclineScreenshare" [dialogClass]="'popup-width-618'">
  <app-screensharedecline></app-screensharedecline>
</app-dialog>


<!-- <div (window:beforeunload)="doSomething($event)"></div> -->
