<div class="modal-header">
  <h4 class="pull-left line-height-20 font-14 secondary-font color-black">Event Information</h4>
</div>
<div class="modal-body event-info-bdy-layout pr-3">
  <div class="row event-info-body">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <p class="line-height-20 font-14 secondary-font color-black">
        Event Title: {{currentEvent?.Title}}
      </p>
      <p class="line-height-20 font-14 secondary-font color-black m-0 mt-3">
        Event Description:
      </p>
      <p class="mb-4 gibson-book-font font-14 line-height-20 color-black opacity-0-8 mt-1"
        [innerHTML]="currentEvent?.LongDescription">
      </p>
      <div class="speaker-wrapper">
        <!-- <p class="line-height-20 font-14 secondary-font color-black mb-3">
          Speakers
        </p> -->
        <div class="row text-center speakers-box" *ngIf="eventsetting?.isSpeakersEnabled || eventsetting?.isModeratorsEnabled " >
          <span class="line-height-20 font-14 secondary-font color-black mb-3 SpeakerFont">Speakers</span>
          <div *ngFor="let item of currentEvent?.SpeakersList" class="col-lg-4 col-md-6 col-sm-12">
            <div class="speaker-details" *ngIf="eventsetting?.isSpeakersEnabled">
              <div class="speaker-image">
                <app-avatar-photo *ngIf="item !== undefined" [photoUrl]="item?.SpeakersImage"
                  [name]="item?.SpeakersName" round="false" class="avtar-listing-icon"></app-avatar-photo>
              </div>
              <div class="text-left">
                <div class="font-14 gibson_medium line-height-14">
                  {{item?.SpeakersName}}
                </div>
                <div class="font-12 gibson-book-font line-height-17 color-black" style="margin-top: 4px;">
                  {{item?.SpeakersJobTitle}}
                </div>
                <div class="font-12 gibson_medium line-height-17 color-black">
                  {{item?.SpeakersCompanyName}}
                </div>
              </div>
            </div>
          </div>


          <div *ngFor="let item of currentEvent?.ModeratorList" class="col-lg-4 col-md-6 col-sm-12">
            <div class="speaker-details" *ngIf="eventsetting?.isModeratorsEnabled">
              <div class="speaker-image">
                <app-avatar-photo *ngIf="item !== undefined" [photoUrl]="item?.SpeakersImage"
                  [name]="item?.SpeakersName" round="false" class="avtar-listing-icon"></app-avatar-photo>
              </div>
              <div class="text-left">
                <div class="font-14 gibson_medium line-height-14">
                  {{item?.SpeakersName}}
                </div>
                <div class="font-12 gibson-book-font line-height-17 color-black" style="margin-top: 4px;">
                  {{item?.SpeakersJobTitle}}
                </div>
                <div class="font-12 gibson_medium line-height-17 color-black">
                  {{item?.SpeakersCompanyName}}
                </div>
              </div>
            </div>
          </div>
         <!-- </div> -->
        </div>
        <div class="row mt-2 event-sponsors">
          <div class="col-lg-5 col-sm-12 col-md-12"
            *ngIf="(currentEvent.SponsorsList.length !== 0) && (eventsetting?.isSponsorsEnabled || '')">
            <p class="mb-2 line-height-20 font-14 secondary-font color-black">
              Event Sponsors
            </p>
            <div class="row align-items-center">
              <div *ngFor="let item of currentEvent?.SponsorsList" class="col-lg-6">
                <div class="event-icon-box">
                  <img [src]="item?.SponsorsImage" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-1 col-sm-12 col-md-12" *ngIf="(currentEvent.SponsorsList.length !== 0) && (eventsetting?.isSponsorsEnabled || '')">
          </div>
          <div class="col-lg-4 col-sm-12 col-md-12"
            *ngIf="currentEvent?.EventSettings?.DisplayPartners ||  eventsetting?.isPartnersEnabled || ''">
            <p class="mb-2 line-height-20 font-14 secondary-font color-black">
              Event Hosts
            </p>
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-6 col-md-6">
                <div class="event-icon-box">
                  <app-avatar-photo *ngIf="currentEvent !== undefined" [photoUrl]="currentEvent?.ChannelIcon"
                  [name]="currentEvent?.ChannaleName" round="false" class="avtar-listing-icon"></app-avatar-photo>
                  <!-- <img [src]="currentEvent?.ChannelIcon" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="currentEvent.DocumentsAndNotesList">
          <div class="col-12">
            <p class="line-height-20 font-14 secondary-font color-black mt-4">Event Documents & Notes</p>
          </div>
          <div class="col-lg-5 mt-1" *ngFor="let item of currentEvent?.DocumentsAndNotesList">
            <div class="card event-modal-file-upload">
              <div class="">
                <img src="../../../../../assets/images/pdf.svg" />
              </div>
              <div class="event-card-caption">
                <p class="max-caption-text font-14 line-height-20 gibson-book-font color-black mb-0">
                  {{item?.DocName}}
                </p>
              </div>
              <div class="btn-download">
                <button (click)="downloadPdf(item?.DocURL,item?.DocName)">
                  <img src="../../../../../assets/images/download-btn.svg" alt="Download">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
