import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterContentInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as OT from '@opentok/client';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { pollQuestionAns } from 'src/app/shared/models/getpollquestionandans';
import { CommanService } from 'src/app/shared/services/comman.service';
import { RolesEnums } from '../../../core/enums';
import { UtilityService } from '../../../core/utilities';
import { GeneralService, HideShowService } from '../../../shared/services';
import { OptionVoteListDialogueComponent } from '../waiting-screen/option-vote-list-dialogue/option-vote-list-dialogue.component';
import * as _ from 'lodash';
import * as R from 'ramda';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-connected-users',
  templateUrl: './connected-users.component.html',
  styleUrls: ['./connected-users.component.scss'],
})
export class ConnectedUsersComponent implements OnInit, OnDestroy {
  navStyle: Observable<boolean>;
  navStyleSetting: Observable<boolean>;
  isConnectingDialog: Observable<boolean>;

  isVal: boolean = false;
  broadCastStarted: boolean = false;
  broadCastStartdate: Date | any;
  totaltimediferrence: any;
  isonLine: boolean = false;
  isWidthForVideoScreen: string = '100%';
  isWidthForNavRightScreen: string = '0';
  apiResponse: any;
  isNetworkRequestSent: boolean = false;
  session: OT.Session;
  connectionIdForOthersUsers: string;
  IDD: string;
  CONNECT: string;
  broadcast: any = { status: 'waiting', streams: 1, rtmp: false };
  boxid: any = 1;
  receivedQuestion: Array<any> = [];
  questionList: Array<any> = [];
  val = 100;
  insertOptions = {
    width: '100%',
    height: '100%',
    showControls: true,
    buttonDisplayMode: true,
    audioVolume: this.val,
    style: { nameDisplayMode: "off" }
  };
  subscriptionData: any;
  userCredentials: any;
  show: boolean = true;
  public option: any[] = [{
    Name: '',
    id: ''
  }];
  eventID: number = 0;
  question: any;
  adminPermission: boolean = false;
  adminAccess: boolean = false;
  withoutAdminPermission: boolean = false;
  options: any[] = [];
  deletePollView: boolean = false;
  addPollView: boolean = true;
  pollQuestionAns: pollQuestionAns[];
  newPollQuestionAns: pollQuestionAns
  polls: any[] = [];
  openJoinSpeakerDialog: any;
  questionId: any;
  buttonText: string = 'Save';
  userID: any;
  optionParse = []
  isModeratorOrSpeakerOrAdmin: boolean = true;
  isAudience: boolean = true;
  isAdmin: boolean = true;
  isAdminOrAudience: boolean;
  audienceData: any[] = [];
  counts: any;
  pollcount: any;
  currentmessage: any = '';
  messagelist: any;
  allmessages: any[] = [];
  audienceId: any;
  userIDforreq: number;

  isWidthForVideoScreenSetting: string;
  isWidthForNavRightScreenSetting: string;
  eventsetting: any;
  private unsubscribe$ = new Subject<void>();
  currenteventdata: any;
  isSettingSection: boolean = false;
  settingsDialog: boolean = false;
  // settingsdata = this.generalService.settingsdata$.asObservable()
  isModerator: boolean = false;
  selectedTab: number = 0;
  settingsMenu: boolean = true;
  settingusers: boolean = false;
  openaddons: boolean = false;
  isSpeaker: boolean;
  showEmojiPicker: boolean;
  showSuccessMsg: boolean = false;
  isEdit: boolean = false;
  allRequestsData: any[] = [];
  showRequestSentMsg: boolean = false;
  pollform!: FormGroup;
  pollOptionArrayValue: number = 0;
  pollSubmitted: boolean = false;

  isShowLoader: boolean = false;
  userName: string = '';
  showpollmessage: boolean = false;
  messageText: string = '';
  showMessageOfAcceptReq: boolean = false;
  isPollDeleted: boolean = false;
  noOptionAdded: boolean = false;
  oneOptionAdded: boolean = false;


  constructor(
    public hideShowService: HideShowService,
    public generalService: GeneralService,
    public utilityService: UtilityService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public commanService: CommanService
  ) {
    this.route.queryParams.subscribe(async (queryURL: Params) => {
      if (this.utilityService.isObjectNotEmpty(queryURL)) {
        this.userIDforreq = parseInt(queryURL['userId']);
      }
    })
    this.pollform = this.formBuilder.group({
      EventID: [''],
      PollId: [''],
      PollName: ['', Validators.required],
      PollOptionList: this.formBuilder.array([])
    });
  }

  // maxConsecutiveLetters(control: FormControl): { [key: string]: boolean } | null {
  //   const value = control.value;
  //   if (value && /[a-zA-Z]{60}/.test(value)) {
  //     return { 'maxConsecutiveLetters': true };
  //   }
  //   return null;
  // }


  fifteenCharAndSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /^(\w{15}\s)?(\w{15}\s)*\w{1,15}$/;
      if (!value.match(pattern)) {
        return { fifteenCharAndSpace: true };
      }
      return null;
    };
  }
  get PollOptionList(): FormArray {
    return this.pollform.get('PollOptionList') as FormArray;
  }

  async ngOnInit(): Promise<void> {
    //get roles from local storage
    this.userID = parseInt(sessionStorage.userID);
    this.isModeratorOrSpeakerOrAdmin = this.generalService.isUserModeratorOrSpeakerOrAdmin;
    this.isAdmin = this.generalService.isUserAdmin;
    this.isModerator = this.generalService.isUserModerator;
    this.isAudience = this.generalService.isUserAudience
    this.isAdminOrAudience = this.generalService.isUserAdminOrAudience;
    this.isSpeaker = this.generalService.isUserSpeaker;

    //get list of Audience
    await this.getListOfAudience();

    this.generalService.isEventExits$.subscribe(isEvent => {
      if (isEvent) {
        combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(async ([items]) => {
            this.currenteventdata = await items.Data;
            this.eventsetting = {
              isLiveChatEnabled: this.currenteventdata.EventSettings.AudienceChat,
              isQAEnabled: this.currenteventdata.EventSettings.CanAudienceAskQuestion
            }
            Object.assign(this.eventsetting, this.currenteventdata.EventSettings);
          });
      }
    });




    //get eventid
    this.eventID = this.generalService.isEventID;

    // To manage side bar of chat,QA, audience screen
    this.navStyle = this.hideShowService.getNavStyle();
    this.navStyle.subscribe((val): any => {
      if (val) {
        this.isWidthForVideoScreen = '100%';
        this.isWidthForNavRightScreen = '308';
        this.isSettingSection = false;
      }
    });

    this.navStyleSetting = this.hideShowService.getShowNavSetting();
    this.navStyleSetting.subscribe((val): any => {
      if (val) {
        this.isSettingSection = true;
      }
    });

    this.generalService.settingsdata$.subscribe(async data => {
      if (data) {
        this.eventsetting = await data;
        Object.assign(this.eventsetting, this.currenteventdata.EventSettings);
      }
    })

    // setInterval(() => {
    //   this.getAllQueOptionlist(this.eventID);
    // }, 5000)

    // To call API of start broad cast and opening connecting dialog, if broadcast start by admin only
    this.isConnectingDialog = this.hideShowService.getShowHideModal();
    this.isConnectingDialog.subscribe((isBroadCast): any => {
      if (isBroadCast) {
        this.startBroadCastByAdmin();
      }
    });

    //permission
    this.permissions();

    //get all question and option list
    this.getAllQueOptionlist(this.eventID);

    this.generalService.audienceDataSource$.subscribe((isaudience: any) => {
      if (isaudience && this.utilityService.isObjectNotEmpty(isaudience)) {
        let da = this.audienceData.filter(x => x.MemberID == isaudience.MemberID);
        if (da.length === 0) {
          this.audienceData.push(isaudience);
        }
      }
    });

    this.generalService.adhocSpeaker$.subscribe(adhoc => {
      if (adhoc) {
        let audData = this.generalService.adhocSpeaker$.value;
        let audIndex = this.audienceData.findIndex(m => m.userName == audData.Name)
        if (audIndex >= 0) {
          this.audienceData.splice(audIndex, 1);
        }
      }
    })

    this.utilityService.messageList$.subscribe(async data => {
      if (data) {
        this.messagelist = data;
        console.log('*****************', this.allmessages);
        this.allmessages.push(this.messagelist);
      }
    })

    this.generalService.audLeaveEvent.subscribe(response => {
      if (response) {
        this.audienceData = [];
        this.getListOfAudience();
      }
    })

    this.generalService.settabindex.subscribe(data => {
      this.selectedTab = data;
    })

  }

  ngOnDestroy() {
    this.utilityService.messageList$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.generalService.pollVoteDetails$.unsubscribe();
    this.generalService.audienceDataSource$.unsubscribe();
    this.generalService.settingsdata$.unsubscribe();
  }

  getListOfAudience() {
    this.generalService.listAllAudience(this.generalService.isEventID, this.userID).subscribe((audDetails: any) => {
      let audienceData: any[] = audDetails.Data.EventMemberList;
      for (let i = 0; i <= audienceData?.length; i++) {
        if (audienceData[i]?.ConnectionId) {
          // this.audienceData[i]['userName']=audienceData[i]['FirstName'] + " " + audienceData[i]['LastName']
          this.audienceData.push(audienceData[i]);
        }
      }
    })
  }

  /**=========================================== broad cast ===========================================*/

  /**
   * start broad cast by admin only
   */
  async startBroadCastByAdmin() {
    console.log("Broadcast Started")
    let sessiondata: any = await this.utilityService.getSessionConnection();
    console.log(sessiondata);
    this.generalService.isEventSessionExits$.subscribe(resp => {
      const sessionId = JSON.parse(resp);
      console.log(sessionId);
      this.generalService.startBroadCast(sessiondata.id, this.generalService.isEventID).subscribe(data => {
        this.generalService.GetBroadcastStartTime(this.generalService.isEventID)
          .subscribe((datas: any) => {
            if (datas.IsBroadcastStart) {
              this.broadCastStarted = true;
              console.log("Broadcast Started time" + datas.BroadcastStartOn);
              this.utilityService.broadCastStartdate = new Date(datas.BroadcastStartOn);
              this.utilityService.totaltimediferrence = datas.timeelapsed;
              this.utilityService.isonLine = true;
              const broadcastData = {
                id: R.path(['id'], data),
                session: sessiondata.id,
                rtmp: !!R.path(['broadcastUrls', 'rtmp'], data),
                url: R.path(['broadcastUrls', 'hls'], data),
                apiKey: R.path(['partnerId'], data),
                availableAt: R.path(['createdAt'], data)
              };
              this.utilityService.broadCastStartSignalForSM(sessiondata);
              this.hideShowService.setShowNav(this.broadCastStarted);
              this.broadcast = _.merge(this.broadcast, broadcastData);
              this.commanService.changeSession(this.broadCastStarted);
              this.generalService.brodcasstart.next(this.broadCastStarted);
              setTimeout(() => {
                this.broadCastStarted = false;
              }, 10000);
            }
          });
      })
    })
  }

  // Send Network Request
  async sendNetworkReqestAudience(requester: any) {
    let sessiondata: any = await this.utilityService.getSessionConnection()
    this.isNetworkRequestSent = true;
    let data = {
      requestsendbymemberid: this.generalService.isUserID,
      eventID: this.generalService.isEventID,
      requestsendtomemberid: requester.MemberID,
      requeststatus: 0
    };
    this.generalService.getConnectRequestListById(data.requestsendtomemberid, this.generalService.isEventID).subscribe((allrequests: any) => {
      let isrequestAlreadySent = allrequests.Data.filter(x => x.requestsendbymemberid == data.requestsendbymemberid);
      if (isrequestAlreadySent.length > 0) {
        this.showRequestSentMsg = true;
        setTimeout(() => {
          this.showRequestSentMsg = false;
        }, 3000);
      } else {
        this.generalService.getAllRequestsofMember(data.requestsendbymemberid, data.requestsendtomemberid, this.generalService.isEventID).subscribe((alldata: any) => {
          let dataOfUsers = alldata.Data.filter(x => x.requeststatus == 1);
          if (dataOfUsers.length > 0) {
            this.showMessageOfAcceptReq = true;
            setTimeout(() => {
              this.showMessageOfAcceptReq = false;
            }, 3000);
          } else {
            this.generalService.sendNetworkRequest(data).subscribe(response => {
              if (response) {
                this.showSuccessMsg = true;
                this.utilityService.sendNetworkRequestSignal(sessiondata, response);
              }

              setTimeout(() => {
                this.showSuccessMsg = false;
              }, 3000);
            });
          }
        })

      }
    })

  }

  RemovePollOption(i: number) {
    // this.option.splice(i, 1);
    this.PollOptionList.removeAt(i);
  }

  //push option1 input type
  addOption(): FormGroup {
    return this.formBuilder.group({
      Counts: [''],
      EventPollID: [''],
      ID: [''],
      // OptionName: ['', Validators.required]
      // OptionName: ['', Validators.required]
      OptionName: new FormControl('', [Validators.required, this.fifteenCharAndSpaceValidator()]),
    });
  }

  //Add Fields
  addOptionsForPoll(): void {
    this.pollOptionArrayValue = this.PollOptionList.length;
    this.PollOptionList.push(this.addOption());
  }


  //Fields Array
  // get PollOptionList(): FormArray {
  //   return <FormArray>this.pollform.get('PollOptionList');
  // }

  //Remove Fields
  removePollOption(index: number): void {
    this.PollOptionList.removeAt(index);
  }

  // post event poll question and option
  async postEventPoll() {
    let sessionData: any = await this.utilityService.getSessionConnection();
    this.pollSubmitted = true;
    let pollformdata = this.pollform.getRawValue();
    this.isShowLoader = true;
    if (!this.pollform.valid) {
      this.isShowLoader = false;
      // this.pollSubmitted = false;
      return
    } else {
      if (!this.isEdit) {
        if (pollformdata.PollOptionList.length >= 2) {
          pollformdata.PollOptionList.forEach(element => {
            this.options.push(element.OptionName);
          });
          var stringOption = this.options.join(",")
          let data = {
            PollName: pollformdata.PollName,
            EventId: this.eventID,
            OptionName: stringOption.split(',')
          }
          this.generalService.sendEventPoll(data).subscribe(async (res: any) => {
            if (res === true) {
              // res = data;
              // this.polls = (res);
              // this.utilityService.sendsignalofPoll(sessionId, data, this.polls.length, this.generalService.isUserID)
              await this.getAllQueOptionlist(this.eventID);
              this.deletePollView = false;
              this.addPollView = true;
              this.option = [{
                Name: '',
                id: ''
              }];
              this.options = [];
              this.pollSubmitted = false;
              this.isShowLoader = false;
              this.showpollmessage = true;
              this.messageText = 'added';
              setTimeout(() => {
                this.showpollmessage = false;
              }, 2000);
            }
          })
        } else if (pollformdata.PollOptionList.length == 0) {
          this.pollSubmitted = false;
          this.noOptionAdded = true;
          this.isShowLoader = false;
          setTimeout(() => {
            this.noOptionAdded = false;
          }, 3000);
          return
        } else if (pollformdata.PollOptionList.length == 1) {
          this.pollSubmitted = false;
          this.oneOptionAdded = true;
          this.isShowLoader = false;
          setTimeout(() => {
            this.oneOptionAdded = false;
          }, 3000);
          return
        }
      }
      //edit event poll question
      else if (this.isEdit) {
        if (pollformdata.PollOptionList.length >= 2) {
          this.option = [];
          let pollQuesdata = {
            PollName: pollformdata.PollName,
            EventId: this.eventID,
            id: this.questionId,
          }

          // for (let i = 0; i < this.option.length; i++) {
          //   if (this.option[i].id === undefined) {
          //     this.option[i].id = 0;
          //   }
          // }

          this.generalService.editEventPoll(pollQuesdata).subscribe(res => {
            if (res) {
              console.log(res);
            }
          })
          pollformdata.PollOptionList.forEach(element => {
            this.option.push({ Name: element.OptionName, id: element.ID });
          });
          let data1 = {
            PollId: this.questionId,
            OptionName: this.option
          }
          this.generalService.addPollOptions(data1).subscribe(async (response: any) => {
            console.log(response);
            this.option = [{
              Name: '',
              id: ''
            }];
            // this.options = [];
            await this.getAllQueOptionlist(this.eventID);
            this.question = '';
            this.deletePollView = false;
            this.addPollView = true;
            this.isEdit = false;
            this.isShowLoader = false;
            this.showpollmessage = true;
            this.messageText = 'updated';
            setTimeout(() => {
              this.showpollmessage = false;
            }, 2000);
          })
        } else if (pollformdata.PollOptionList.length == 0) {
          this.pollSubmitted = false;
          this.noOptionAdded = true;
          this.isShowLoader = false;
          setTimeout(() => {
            this.noOptionAdded = false;
          }, 3000);
          return
        } else if (pollformdata.PollOptionList.length == 1) {
          this.pollSubmitted = false;
          this.oneOptionAdded = true;
          this.isShowLoader = false;
          setTimeout(() => {
            this.oneOptionAdded = false;
          }, 3000);
          return
        }
      }

    }

  }

  //give permission to app admin
  permissions() {
    if (this.isModeratorOrSpeakerOrAdmin) {
      this.adminPermission = true;
    }
    else if (this.isAudience) {
      this.withoutAdminPermission = true;
    }
    if (this.generalService.isRole === RolesEnums.Roles.Admin || this.generalService.isRole === RolesEnums.Roles.Moderator) {
      this.adminAccess = true;
    }
  }

  //delete poll
  deletePoll() {
    this.deletePollView = false;
    this.addPollView = true;
  }

  //add poll
  addPoll() {
    // this.pollform.reset();
    this.pollform = this.formBuilder.group({
      EventID: [''],
      PollId: [''],
      // PollName: ['', Validators.required],
      // PollName: ['', Validators.required],
      PollName: new FormControl('', [this.fifteenCharAndSpaceValidator()]),
      PollOptionList: this.formBuilder.array([])
    });
    this.deletePollView = true;
    this.addPollView = false;
    this.pollSubmitted = false;
    this.option = [{
      Name: '',
      id: ''
    }];
    this.question = '';
    this.buttonText = 'Save'
  }

  //submit question answer
  async submitQuestionAnswer(args) {
    if (this.isAudience) {
      let data = {
        EventPollID: args.EventPollID,
        PollOptionId: args.ID,
        UserId: this.userID,
      };
      this.generalService.SubmitVoteToPoll(data).subscribe(res => {
        if (res) {
          this.getSinglePoll(args.EventPollID);
          this.getAllQueOptionlist(this.eventID);
        }
      });
    } else if (!this.isAudience) {
      this.getAllQueOptionlist(this.eventID);
    }

  }


  //get all que option list
  async getAllQueOptionlist(eventID) {
    let sessionData: any = await this.utilityService.getSessionConnection();
    if (this.generalService.isRole === RolesEnums.Roles.Audience) {
      var audienceId: any = this.route.snapshot.queryParams.userId;
    } else {
      var audienceId: any = 0;
    }
    var okpoll = this.generalService.obsForPoll.subscribe(async (pollList) => {
      this.generalService.GetPollListWithOptions(eventID, audienceId).subscribe((list: any) => {
        pollList = list.Data.EventPollList;
        // pollList.push(list.EventPollList);
        this.polls = list.Data.EventPollList;
        if (this.polls && this.polls.length > 0) {
          this.polls.forEach(element => {
            if (element.PollOptionList) {
              element.PollOptionList = JSON.parse(element.PollOptionList);
              // element.PollOptionList.forEach(data => {
              //   let pollData = {
              //     pollOptionId: '',
              //     pollId: '',
              //     count: 0
              //   };
              //   pollData.pollOptionId = data.EventPollID;
              //   pollData.pollId = data.ID;
              //   pollData.count = data.Counts;
              // })
            }
          });

          //commenting code as per discussion with mobile team
          if (sessionData) {
            if (this.isEdit && this.polls.length > 0) {
              this.utilityService.sendsignalofPollEdit(sessionData, this.polls, this.polls.length);
              // okpoll.unsubscribe();
            } else if (!this.isEdit) {
              this.utilityService.sendsignalofPollCreateOrDelete(sessionData, this.polls, this.polls.length, this.generalService.isUserID);
              okpoll.unsubscribe();
            }
          }

        }
      })


    })
  }

  //data binding on edit
  editPoll(arg) {
    // this.pollform.reset();
    this.pollform = this.formBuilder.group({
      EventID: [''],
      PollId: [''],
      // PollName: ['', Validators.required],
      PollName: ['', Validators.required],
      PollOptionList: this.formBuilder.array([])
    });
    this.questionId = [];
    this.question = [];
    this.option = [];
    this.deletePollView = true;
    this.addPollView = false;
    this.buttonText = 'Save';
    this.questionId = arg.PollId;
    this.question = arg.PollName;
    arg.PollOptionList.forEach(element => {
      // this.option.push({
      //   Name: element.OptionName,
      //   id: element.ID
      // })
      this.addOptionsForPoll();
    });
    this.pollform.patchValue(arg);
    this.isEdit = true;
  }

  // delete data
  async deleteData(args) {
    if (!this.isPollDeleted) {
      this.isPollDeleted = true;
      let sessionId: any = await this.utilityService.getSessionConnection();
      this.generalService.DeleteEventPoll(args.PollId).subscribe(res => {
        if (res == true) {
          let selelctedPollId = args.PollId
          this.getAllQueOptionlist(this.eventID);
          var allPollData = this.generalService.obsForPoll.subscribe(pollList => {
            pollList = this.polls;
            let updatedPolls = pollList.filter(x => selelctedPollId !== x.PollId);
            this.showpollmessage = true;
            this.messageText = 'deleted';
            setTimeout(() => {
              this.showpollmessage = false;
            }, 2000);
            this.isPollDeleted = false;
            this.utilityService.sendsignalofPollCreateOrDelete(sessionId, pollList, updatedPolls.length, this.generalService.isUserID)
            allPollData.unsubscribe();
          })
        }
      })
    }
  }


  openPollVoteUsersPopUp(value) {
    if ((this.isAdmin || this.isModerator) && value.Counts > 0) {
      const dialogRef = this.dialog.open(OptionVoteListDialogueComponent, {
        width: '500px',
        height: '350px',
        data: value,
        panelClass: 'option-modalbox-layout'
      });
      // dialogRef.afterClosed().subscribe(data => {
      //   console.log(data);
      // });
      this.generalService.pollVoteDetails$.next(value);
    }
  }


  // get single poll with options
  getSinglePoll(pollId) {
    this.generalService.GetSinglePollWithOptions(pollId).subscribe(res => {
      console.log(res);
    });
  }
  // Get Event Question ask by Auidence List
  getAllEventQuestionList(event) {
    this.receivedQuestion = event;
  }

  async sendMessage() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    let userdetails: any = await this.generalService.getUserDetail(this.userID, this.generalService.isRole)
    this.currentmessage;
    /* send message */
    if (this.currentmessage != '' && this.currentmessage != undefined) {
      var MessageData = {
        'UserId': this.userID,
        "UserName": userdetails.userName,
        "Message": this.currentmessage,
        "profile": userdetails.userProfilePicture,
        // "Company":userdetails.userCompanyName,
        // 'jobtitle':userdetails.userJobTitle
      }
      sessiondata.signal({ type: 'msg', data: JSON.stringify(MessageData) }, (error) => {
        if (error) {
          console.log('Error sending signal:', error.name, error.message);
        } else {
          this.currentmessage = '';
          // this.messagelist = (MessageData)
          // console.log(MessageData.Message)
          this.showEmojiPicker = false;
          console.log('Message Sent successfully');
        }
      });

    }
  }


  //For changing tab
  // changeTabForChat(){
  //   let x=document.getElementsByClassName('private-chat-list');
  //   console.log(x);
  // }

  settingsdialogclose() {
    this.isSettingSection = false;
    this.settingsMenu = true;
    this.settingusers = false;
    this.openaddons = false;
  }

  openSettingsMenu() {
    this.isSettingSection = true;
    this.settingusers = !this.settingusers;
    this.settingsMenu = true;
  }

  openAddonsMenu() {
    this.isSettingSection = true;
    this.openaddons = !this.openaddons;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }
  addEmoji(event) {
    console.log(this.currentmessage)
    const { currentmessage } = this;
    console.log(currentmessage);
    console.log(`${event.emoji.native}`)
    const text = `${currentmessage}${event.emoji.native}`;

    this.currentmessage = text;
    // this.showEmojiPicker = false;
  }

}
