import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class CoreHttpService {

  public showErrorMessages = new BehaviorSubject<any>(null);


  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * @template TResponse
   * @param url string
   * @param reqHeader httpHeaders
   * @returns {Observable<TResponse>}
   * @memberof CoreHttpService
   */
  httpGetRequest<TResponse>(url: string, reqHeader?: HttpHeaders): Observable<TResponse> {
    return this.httpClient.get(url, { headers: reqHeader })
      .pipe(map(res => {
        return <TResponse>res
      }), retry(1), shareReplay({ refCount: false, bufferSize: 1 }), catchError(this.handleError));
  }

  /**
   * @template TRequest
   * @template TResponse
   * @param {string} url
   * @param {TRequest} data
   * @param {HttpHeaders} [reqHeader]
   * @param {boolean} [showLoader]
   * @returns {Observable<TResponse>}
   * @memberof CoreHttpService
   */
  httpPostRequest<TRequest, TResponse>(url: string, data: TRequest, reqHeader?: any): Observable<TResponse> {
    return this.httpClient.post(url, data, { headers: reqHeader })
      .pipe(map(res => {
        return <TResponse>res
      }), retry(1), catchError(this.handleError))
  }

  httpPutRequest<TRequest, TResponse>(url: string, user: TRequest, header?: any): Observable<TResponse> {
    return this.httpClient.put(url, user, { headers: header })
        .pipe(
            map(res => {
                return <TResponse>res
            }),
            catchError(this.handleError))
}

  /**
   *
   * @param error HttpErrorResponse
   * @returns string
   * @memberof CoreHttpService
   */
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    this.showErrorMessages.next(errorMessage);
    console.log("errorMessage => ", errorMessage);
    return throwError(errorMessage);
  }
}
