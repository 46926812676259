<div class="slide-layout-connect">
  <div class="modal-header connect-header">
    <!-- <button class="menu-icon-btn">
          <img src="assets/images/menu-icon.png" alt="Menu Icon" class="img-fluid">
      </button> -->
  </div>
  <carousel class="carousel connect-md-layout" [(activeSlide)]="activeSlide">
    <slide class="slide" *ngFor="let item of connectRequestList;let i = index">
      <div class="modal-body connect-body p-0">
        <div class="main-report-layout text-center">
          <!-- <img src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg"
            alt="Report" class="img-fluid"> -->
          <img [src]="item.imageOfRequestSendPerson? item.imageOfRequestSendPerson :'assets/images/default-user.png'">
          <!-- imageOfRequestSendPerson -->
        </div>
        <div class="full-body-layout">
          <div class="profile-info-details">
            <h3 class="font-22 line-height-26 grotesk-medium color-black"> {{ item.RequestSendByName }}
              <span *ngIf="pronounOfUser" class="font-12 line-height-17 gibson-book-font opacity-0-8">({{this.pronounOfUser}})</span>
            </h3>
            <p class="font-14 line-height-17 color-black opacity-0-8 mb-0 gibson-book-font"> {{ item.jobtitle }}
              <span *ngIf="item.companyname && item.jobtitle">at</span><strong> {{ item.companyname }} </strong><br>
              <span *ngIf="item.cityname">{{item.cityname}},</span>
              <span>{{ item.countryname }}</span>
            </p>
            <hr>
          </div>
          <div class="user-details-box">
            <a class="color-black opacity-0-8 line-height-17 gibson_medium "> {{ item.website }} </a>
            <div class="mt-2 user-details-user-info">
              <div class="d-flex align-items-center mb-3 user-personal-info" *ngIf="item.email">
                <img src="../../../../../assets/images/mail-icon.svg" alt="Mail" class="img-fluid mr-2">
                <p class="mr-3 social-pera gibson-book-font font-16 line-height-17 color-black mb-0">
                  <a class="social-pera gibson-book-font font-16 line-height-17 color-black mb-0"
                    href="mailto:item.email">{{ item.email }} </a>
                </p>
              </div>
              <div class="d-flex align-items-center mb-3 user-personal-info">
                <img *ngIf="item.mobilenumber" src="../../../../../assets/images/call.svg" alt="Call" class="img-fluid mr-2">
                <p
                  class="cursor-pointer social-pera social-pera gibson-book-font font-16 line-height-17 color-black mb-0">
                  {{ item.mobilenumber }}
                </p>
              </div>
            </div>
            <p class="font-16 opacity-0-8 line-height-22 gibson-book-font color-black">
              {{ item.biography }} </p>
            <hr>
            <div class="interests-box">
              <p class="color-black opacity-0-8 line-height-17 gibson_medium" *ngIf="item.Interest">Interests</p>
              <div class="interests-button-box">
                <button *ngFor="let interest of item.Interest" class="def-cursor active mb-3"> {{ interest.Title }}
                </button>
              </div>
              <div class="social-icon-group mb-2">
                <a *ngIf="item.instagramurl" (click)="onNavigate(item.instagramurl)">
                  <img src="assets/images/insta-icon-black.png" alt="instagram" class="img-fluid mr-4">
                </a>
                <a *ngIf="item.linkedinurl" (click)="onNavigate(item.linkedinurl)">
                  <img src="assets/images/linked-icon.png" alt="linked" class="img-fluid mr-4">
                </a>
                <a *ngIf="item.twitterurl" (click)="onNavigate(item.twitterurl)">
                  <img src="assets/images/twitter-icon-black.png" alt="twitter" class="img-fluid mr-4">
                </a>
                <a *ngIf="item.facebookurl" (click)="onNavigate(item.facebookurl)">
                  <img src="assets/images/facebook-icon.png" alt="facebook" class="img-fluid mr-4">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide>
  </carousel>

  <div class="connect-footer-bdy pl-4 pr-4 pt-3 pb-3">
    <!-- Condition wise manage -->
    <p class="font-14 grotesk-medium line-height-20 color-primary" *ngIf="connectRequestList.length===0">Connection
      Request</p>
    <div class="d-flex align-items-center" *ngIf="connectRequestList.length>0">
      <p class="font-14 grotesk-medium line-height-20 color-primary mr-3" *ngIf="connectRequestList.length==0">Connection
        Request ({{activeSlide}}/{{
        connectRequestList.length}})</p>
      <p class="font-14 grotesk-medium line-height-20 color-primary mr-3" *ngIf="connectRequestList.length>=1">Connection
        Request ({{activeSlide+1}}/{{
        connectRequestList.length}})</p>
      <div class="arrow-group mb-3" *ngIf="connectRequestList.length>1">
        <a class="mr-2 {{activeSlide}}">
          <img src="assets/images/arrow-line-gray.svg" alt="Arrow" class="img-fluid">
        </a>
        <a>
          <img src="assets/images/arrow-line-blue.svg" alt="Arrow" class="img-fluid">
        </a>
      </div>
    </div>

    <div class="cursor-pointer footer-btn-group" *ngIf="connectRequestList.length>0">
      <button class="btn-border-outline font-14 line-height-24 gibson_medium mr-3"
        (click)="acceptDeclineConcection(connectRequestList[activeSlide].id, 2)">Decline</button>
      <button class="btn-common font-14 line-height-24 gibson_medium"
        (click)="acceptDeclineConcection(connectRequestList[activeSlide].id, 1)">Accept</button>
    </div>
    <div class="no-connect-req" *ngIf="connectRequestList.length===0">
      <p>No connection request yet</p>
    </div>
  </div>
</div>
