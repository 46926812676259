<div class="modal-header">
  <h4 class="pull-left">Digital Goodie Bag</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="grotesk-medium font-14 line-height-20 color-black mb-3">Here’s a little gift for you!</h2>
    </div>
  </div>
  <div class="row align-items-stretch m-0" *ngFor="let item of currenteventdata?.DocumentsAndNotesList">
    <div class="col-11 m-auto">
      <div class="card modal-file-upload">
        <div class="">
          <img src="../../../../../assets/images/pdf.svg"/>
        </div>
        <div class="">
          <p class="godies-caption-contents gibson-book-font font-14 line-height-20 color-black mb-0 ml-2">
            {{item?.DocName}}
          </p>
        </div>
        <div class="btn-download">
          <button (click)="downloadPdf(item?.DocURL,item?.DocName)">
            <img src="../../../../../assets/images/download-btn.svg" alt="Download">
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="gap mob-p-0 mb-10 gift-pdf-wrapper w-100">
      <div>
        <div class="card voucher-details">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center">
                <div class="voucher-sponsor-img pt-2">
                  <img src="/assets/images/amazon.png" />
                </div>
                <div class="gift-card-caption">
                  <h1 class="gibson_medium font-20 line-height-17 color-black mb-3">
                    $20.00
                  </h1>
                  <p class="gibson-book-font font-14 line-height-17 color-black mb-0">Gift Card</p>
                </div>
                <div class="card-brd-line">
                  <img src="assets/images/line.png" alt="Line">
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-6 col-6 pl-3">
                  <div class="about-voucher">
                    <label class="gibson_medium font-13 line-height-14 color-black">
                      About
                    </label>
                    <p class="m-0 caption-text-p gibson-book-font font-12 line-height-16">
                      This  gift card can be used 
                      with select items on Amazon. 
                      Valid till 30/10/2020
                    </p>
                  </div>
                  <div class="about-voucher">
                    <label class="gift-code mb-2 gibson_medium font-12 line-height-12 color-black mt-2">
                      Code : XX425D
                    </label>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div class="modal-footer text-center border-0 p-0 align-items-center justify-content-center mb-3">
  <button type="button" class="btn-common gibson_medium font-14 line-height-24">Leave Event</button>
</div>
