import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, interval, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/utilities';
import { CommanService } from 'src/app/shared/services/comman.service';
import { GeneralService, HideShowService } from '../../../shared/services';

@Component({
  selector: 'app-waiting-screen',
  templateUrl: './waiting-screen.component.html',
  styleUrls: ['./waiting-screen.component.scss']
})
export class WaitingScreenComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  currentEvent: any;
  fullName: string = '';
  isConnectingDialog: Observable<boolean>;
  runTimeSubscription!: Subscription;
  brodcasstarted = this.generalService.brodcasstart.asObservable();

  constructor(
    public generalService: GeneralService,
    public hideShowService: HideShowService,
    private router: Router,
    public utilityService: UtilityService,
    public commonService: CommanService
  ) { }

  async ngOnInit() {
    let role = this.generalService.isRole;
    if (role == 'speaker' || role == 'moderator') {
      let name = this.generalService.isUserDetails;
      this.fullName = name.FirstName + ' ' + name.LastName;
      this.generalService.isEventExits$.subscribe(isEvent => {
        if (isEvent) {
          combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([items]) => {
              this.currentEvent = items.Data;
            });
        }
      });
      setTimeout(() => {
        this.router.navigate(['/connected-users'], { queryParamsHandling: 'preserve' });
      }, 5000);
    } else {
      let userData: any = await this.generalService.getUserDetail(this.generalService.isUserID, this.generalService.isRole)
      // let name = this.generalService.isUserDetails;
      this.fullName = userData.userName;
      this.generalService.isEventExits$.subscribe(isEvent => {
        if (isEvent) {
          combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([items]) => {
              this.currentEvent = items.Data;
            });
        }
      });
      this.runTimeSubscription = interval(10000).subscribe(res => {
        this.generalService.GetBroadcastStartTime(this.generalService.isEventID).subscribe((data: any) => {
          if (data.IsBroadcastStart) {
            this.router.navigate(['/connected-users'], { queryParamsHandling: 'preserve' });
          }
        })
      })
    }
    // let userData: any = await this.generalService.getUserDetail(this.generalService.isUserID, this.generalService.isRole)
    // this.fullName = userData.userName;
    // this.generalService.isEventExits$.subscribe(isEvent => {
    //   if (isEvent) {
    //     combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
    //       .pipe(takeUntil(this.unsubscribe$))
    //       .subscribe(([items]) => {
    //         this.currentEvent = items.Data;
    //       });
    //   }
    // });
    // setTimeout(() => {
    //   this.router.navigate(['/connected-users'], { queryParamsHandling: 'preserve' });
    // }, 5000);

    // this.generalService.isEventExits$.subscribe(isEvent => {
    //   if (isEvent) {
    //     combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
    //       .pipe(takeUntil(this.unsubscribe$))
    //       .subscribe(([items]) => {
    //         this.currentEvent = items.Data;
    //       });
    //   }
    // });
    // setTimeout(() => {
    //   this.router.navigate(['/connected-users'], { queryParamsHandling: 'preserve' });
    // }, 5000);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
