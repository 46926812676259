<div class="modal-header">
  <h4 class="pull-left">People who answered in a {{list.OptionName}}</h4>
  <img src="../../../../../assets/images/close-btn-blue.svg" alt="Close" (click)="cancelData()" style="cursor: pointer;">

  <!-- Reported User Condition wise set -->
  <!-- <h4 class="pull-left">Reported Users</h4> -->
  <!-- End -->
</div>
<div class="modal-body">
  <div class="request-user-wrapper">
    <div class="row">
      <div class="col-lg-12">
        <div class="d-flex justify-flex-start align-items-center mb-2" *ngFor="let data of userData">
          <!-- <div class="req-user-image mr-2">
            <img src=${data?.Picture} />
          </div> -->
          <div class="user-name-option-list">
            <app-avatar-photo *ngIf="data !== undefined"
              [photoUrl]="data?.Picture" [name]="data?.NAME" round="false" class="avtar-listing-icon">
            </app-avatar-photo>
          </div>
          <div class="grotesk-medium font-14 line-height-20 color-black ml-3 mr-2">
            {{data?.NAME}}
          </div>
          <div class="req-user-position d-flex align-items-center">
            <span class="chat-dot"></span>
            <div class="gibson-book-font font-14 line-height-14 color-black opacity-0-8">{{data?.CompanyName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>