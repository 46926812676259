
export namespace RolesEnums {

  export enum Roles {
    Admin = 'admin',
    Moderator = 'moderator',
    Speaker = 'speaker',
    Audience = 'audience'
  }

  export enum RoleType {
    Admin = 1,
    Audience = 2,
    SpeakerOrModerator = 3
  }

  export enum RoleName {
    Admin = 'Admin',
    Moderator = 'Moderator',
    Speaker = 'Speaker',
    Audience = 'Audience'
  }

}

