import { RolesEnums } from 'src/app/core/enums';
import { Component, EventEmitter, Output } from '@angular/core';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-leave-event-dialog',
  templateUrl: './leave-event-dialog.component.html',
  styleUrls: ['./leave-event-dialog.component.scss']
})
export class LeaveEventComponent {
  @Output() closeLeaveEventDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeLeaveEventDialogBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  openFeedbackDialog: boolean;
  redirectsiteurl = environment.siteurl;

  constructor(
    public utilityService: UtilityService,
    public generalService: GeneralService
  ) { }

  closeFeedBackDialog() {
    this.openFeedbackDialog = false;
    this.closeLeaveEventDialog.emit();
  }

  closeFeedBackDialogC() {
    this.closeLeaveEventDialogBack.emit();
  }

  async leaveEvent() {
    let sessionData: any = await this.utilityService.getSessionConnection();
    let data = {
      UserID: this.generalService.isUserID,
      EventID: this.generalService.isEventID
    }
    if (this.generalService.isRole === RolesEnums.Roles.Audience) {
      this.generalService.audienceLeaveEvent(data).subscribe((res: any) => {
        if (res.Status == 200) {
          this.utilityService.audienceLeftEvent(sessionData, this.generalService.isUserID);
          this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, RolesEnums.Roles.Audience)
        }
      });
    } else if (this.generalService.isRole === RolesEnums.Roles.Speaker) {
      this.generalService.redirectionToFeedbackPage(this.generalService.isUserID, this.generalService.isEventID, RolesEnums.Roles.Speaker)
    }
    // this.utilityService.openLinkInSameTab(this.redirectsiteurl);
  }
}
