import { Component, ComponentFactory, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { RolesEnums } from 'src/app/core/enums';
import { GeneralService } from 'src/app/shared/services';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserVideoScreenComponent } from '../../connected-users/user-video-screen/user-video-screen.component';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/utilities';
import { CommanService } from 'src/app/shared/services/comman.service';
@Component({
  selector: 'app-remove-speaker-dialog',
  templateUrl: './remove-speaker-dialog.component.html',
  styleUrls: ['./remove-speaker-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})
export class RemoveSpeakerComponent implements OnInit {
  @Output() removeSpeakerDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() currentEvent: any;
  private unsubscribe$ = new Subject<void>();
  title: string;
  closeBtnName: string;
  list: any[] = [];
  adminDialogVar?: any;
  event: any;
  eventId: number;
  isOk: boolean = false;
  boxId: any;
  details: any;


  constructor(public generalService: GeneralService,
    public utilityservice: UtilityService,
    public router: Router,) { }

  isRemovedSpeaker: boolean = false;

  async ngOnInit() {
    this.generalService.isEventExits$.subscribe(isEvent => {
      if (isEvent) {
        combineLatest([this.generalService.cacheCommonSuccessForEvent$.pipe(map(event => event))])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(([items]) => {
            this.currentEvent = items.Data;
          });
      }
    });
    this.generalService.boxIdDetails$.subscribe(data => {
      if (data) {
        this.details = data;
      }
    })
  }


  async removeUserFromEvent() {
    this.isOk = true;
    this.generalService.boxIdforremove.subscribe(data => {
      if (data) {
        this.boxId = data
      }
    })
    const session: any = await this.utilityservice.getSessionConnection();
    this.generalService.forceDisconnect(this.generalService.isEventID, this.generalService.isUserID)
      .subscribe((res: any) => {
        console.log("forceDisconnectUser", res.Data);
        this.utilityservice.sendSignalToRemove(session, this.boxId);
      });
    setTimeout(() => {
      this.isOk = false;
    }, 3000)
  }

  cancle() {
    this.removeSpeakerDialog.emit();
  }

}
