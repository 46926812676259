<div class="nav-right-layout">
    <!-- side bar main wrapper -->
    <div class="side-nav-bar" [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }">

        <!-- overlay -->
        <div class="side-nav-bar-overlay"
            [ngStyle]="{ 'transition': 'background-color ' + onSidebarClose + 's, visibility ' + onSidebarClose + 's' }"
            [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }" (click)="onSidebarClose()"></div>

        <!-- side bar-->
        <div class="side-nav-bar-menu-container" [ngStyle]="getSideNavBarStyle((showSideNav | async))">

            <!-- close button -->
            <!-- <div><span class="material-icons side-nav-bar-close" (click)="onSidebarClose()">clear</span></div> -->

            <!-- side bar content -->
            <div class="side-nav-bar-content-container">
                <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
            </div>
        </div>
    </div>
</div>
