import { Injectable } from '@angular/core';
import { CoreHttpService } from '../../core/services';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RolesEnums } from '../../core/enums';
import { HttpHeaders } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  videourl = `${environment.apiUrl}Video`;
  eventpoll = `${environment.apiUrl}EventPoll`;
  coreUrl = `${environment.apiUrl}Video`;
  coreDetailUrl = `${environment.apiUrl}UserInfo`;
  newUserDetailUrl = `${environment.apiUrl}`;
  eventmember = `${environment.apiUrl}Event`;

  cacheCommonSuccessForEvent$: Observable<any>;
  cacheCommonSuccessForEventSession$: Observable<any>;
  cacheCommonSuccessForUser: Observable<any>;

  cacheCommanSuccessGetAllQuestionList: Observable<any>;
  cacheAudiencSuccesseRequest: Observable<any>

  cacheCommanSuccessForAskQuestion: Observable<any>;
  cacheCommanSuccessUserFeedback: Observable<any>;

  cacheCommanSuccessNetworkRequest: Observable<any>;

  public isEventExits$ = new BehaviorSubject<boolean>(false);
  public isEventSessionExits$ = new BehaviorSubject<any>(null);
  public isUserCredentialsExits$ = new BehaviorSubject<any>(null);
  public isUserShowDetails$ = new BehaviorSubject<any>(null);

  public pollDetails$ = new BehaviorSubject<any>(null);
  obsForPoll=this.pollDetails$.asObservable();

  public pollVoteDetails$ = new BehaviorSubject<any>(null);
  public isUserShowDetailsConnection$ = new BehaviorSubject<any>(null);
  // public isAudienceDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  public audienceDataSource$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  userData = this.audienceDataSource$.asObservable();
  public audienceToSpeakerlist$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public networkRequestList$ = new BehaviorSubject<any>(null);

  public questionCount = new BehaviorSubject<any>(null);
  public questionList$ = new BehaviorSubject<any>(null);
  public adhocSpeaker$ = new BehaviorSubject<any>(null);
  public settingsdata$ = new BehaviorSubject<any>(null);
  public boxId$ = new BehaviorSubject<any>(null);
  boxIdforremove = this.boxId$.asObservable();
  public boxIdDetails$ = new BehaviorSubject<any>(null);
  public brodcasstart = new BehaviorSubject<any>(null)
  public audLeaveEvent = new BehaviorSubject<boolean>(false);
  public settabindex = new Subject<any>();
  public isBroadcastStopped = new BehaviorSubject<boolean>(false);

  constructor(
    public coreHttpService: CoreHttpService
  ) { }

  /**
   * Gets whether is event id
   * @memberof GeneralService
   */
  get isEventID(): number {
    return JSON.parse(sessionStorage.getItem('eventID'));
  }

  get isUser(): boolean {
    return JSON.parse(sessionStorage.getItem('isUser'))
  }

  /**
   * Gets whether is user id
   * @memberof GeneralService
   */
  get isUserID(): number {
    return JSON.parse(sessionStorage.getItem('userID'));
  }

    /**
   * Gets whether is user id
   * @memberof GeneralService
   */
    get isChannelID(): number {
      return JSON.parse(sessionStorage.getItem('channelID'));
    }

  /**
   * Gets whether is role
   * @memberof GeneralService
   */
  get isRole(): string {
    return sessionStorage.getItem('role');
  }

  /**
   * Gets whether is user details
   */
  get isUserDetails(): any {
    return JSON.parse(sessionStorage.getItem('userDetails'));
  }

  /**
   * Gets whether is user admin
   * @memberof GeneralService
   */
  get isUserAdmin(): boolean {
    return this.isRole === RolesEnums.Roles.Admin;
  }

  get isUserModerator(): boolean {
    return this.isRole === RolesEnums.Roles.Moderator;
  }

  /**
   * Gets whether is user admin or audience
   * @memberof GeneralService
   */
  get isUserAdminOrAudience(): boolean {
    return this.isRole === RolesEnums.Roles.Admin || this.isRole === RolesEnums.Roles.Audience;
  }

  get isUserSpeaker(): boolean {
    return this.isRole === RolesEnums.Roles.Speaker
  }

  /**
   * Gets whether is user admin or speaker
   * @memberof GeneralService
   */
  get isUserAdminOrSpeaker(): boolean {
    return this.isRole === RolesEnums.Roles.Admin || this.isRole === RolesEnums.Roles.Speaker;
  }

  /**
   * Gets whether is user audience or speaker
   * @memberof GeneralService
   */
  get isUserAudienceOrSpeaker(): boolean {
    return this.isRole === RolesEnums.Roles.Audience || this.isRole === RolesEnums.Roles.Speaker;
  }

  /**
   * Gets whether is user audience or speaker
   * @memberof GeneralService
  */
  get isUserModeratorOrSpeakerOrAdmin(): boolean {
    return this.isRole === RolesEnums.Roles.Moderator || this.isRole === RolesEnums.Roles.Speaker
      || this.isRole === RolesEnums.Roles.Admin;
  }

  /**
   * Gets whether is user audience
   * @memberof GeneralService
  */
  get isUserAudience(): boolean {
    return this.isRole === RolesEnums.Roles.Audience;
  }

  get isVideoOn(): boolean {
    return JSON.parse(sessionStorage.getItem('isVideoOn'));
  }

  get isAudioOn(): boolean {
    return JSON.parse(sessionStorage.getItem('isAudioOn'));
  }

  /**
   * Gets event by id
   * @param eventID number
   * @memberof GeneralService
   */
  public cacheCommonForEvent(eventID: number) {
    if (!this.cacheCommonSuccessForEvent$) {
      this.cacheCommonSuccessForEvent$ = this.getEventDetailsById(eventID);
    }
    return this.cacheCommonSuccessForEvent$;
  }

  /**
   * Gets event details by id
   * @param eventID number
   * @memberof GeneralService
   */
  public getEventDetailsById(eventID: number) {
    const url = this.videourl + `/GetEventDetails?eventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Caches common for event
   * @param eventID number
   * @returns cache for event session
   * @memberof GeneralService
   */
  public cacheCommonForEventSession(eventID: number) {
    if (!this.cacheCommonSuccessForEventSession$) {
      this.cacheCommonSuccessForEventSession$ = this.toStartEventSession(eventID);
    }
    return this.cacheCommonSuccessForEventSession$;
  }

  /**
   * To start event session
   * @param eventId number
   * @returns for Start Event Session
   * @memberof GeneralService
   */
  toStartEventSession(eventId: number) {
    const url = this.videourl + `/StartEventSession?eventID=${eventId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * To stop event session
   * @param eventId number
   * @returns  for Stop Event Session
   * @memberof GeneralService
   */
  toStopEventSession(eventId: number, sessionId: string) {
    const url = this.videourl + `/DisconnectSession?eventID=${eventId}&sessionId=${sessionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Gets credential
   * @param eventId
   * @param userTypeId
   * @returns
   * @memberof GeneralService
   */
  toGenerateTokenForAllRoles(eventId: any, userTypeId: number) {
    const url = this.videourl + `/GenerateToken?eventId=${eventId}&role=${userTypeId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  // /**
  //  *
  //  * @param eventId
  //  * @returns
  //  */
  GetBroadcastStartTime(eventId) {
    var reqHeaders = new HttpHeaders({ "No-Auth": "true" });
    const url = this.coreUrl + `/GetBroadcastStartTime?eventId=${eventId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * To generate token based on role
   * @param eID number
   * @param roleNumber number
   * @returns
   * @memberof GeneralService
   */
  async getTokenBasedOnRole(eID: number, roleNumber: number) {
    var promise = new Promise((resolve, reject) => {
      this.toGenerateTokenForAllRoles(eID, roleNumber)
        .subscribe((response: any) => {
          let credentials = {
            ApiKey: response.ApiKey,
            SessionId: response.SessionId,
            Token: response.Token,
            roleNumber: roleNumber,
          };
          this.isUserCredentialsExits$.next(credentials);
          resolve(credentials);
        }, (error: any) => {
          // Swal.fire(
          //   'Let admin start the event!',
          //   '',
          //   'warning'
          // )
        })
    });
    return promise;
  }
  /**
   * Update audence
   * @param data
   */
  updateAudience(data) {
    this.audienceDataSource$.next(data);
  }
  /**
   * Add new audience in Array
   * @param dataObj
   */
  addNewAudience(dataObj) {
    const currentValue = this.audienceDataSource$.value;
    const updatedValue = [...currentValue, dataObj];
    this.audienceDataSource$.next(updatedValue);
  }
  /**
   * Delete audience From Audience Tab
   * @param dataarray
   * @param data
   */
  deleteaudiencedata(dataarray, data) {
    dataarray.splice(data, 1);
    this.audienceDataSource$.next(dataarray);
  }

  /**
   *
   * @param connectionDetails object for connection details
   * @returns connection Details
   * @memberof GeneralService
   */
  toSaveConnection(connectionDetails: { eventId: number; userId: number; connectionId: string; }) {
    const url = this.videourl + `/SaveConectionId?eventId=${connectionDetails.eventId}&userId=${connectionDetails.userId}&connectionId=${connectionDetails.connectionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * to Save connectionId of Audience on joining
   * @param connectionDetails
   * @returns connection Details
   * @memberof GeneralService
   */
  toSaveConnectionAudience(connectionDetails: { eventId: number; userId: number; connectionId: string; }) {
    const url = this.videourl + `/SaveAudienceConectionId?eventId=${connectionDetails.eventId}&userId=${connectionDetails.userId}&connectionId=${connectionDetails.connectionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Live audience connected in event
   * @param eventId
   * @returns
   */
  ToGetAudienceCount(eventId: any) {
    const url = this.videourl + `/GetLiveAudienceCount?eventId=${eventId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   *
   * @param broadcastSessionId
   * @param eventId
   * @returns
   */
  getBroadCast(broadcastSessionId: any, eventId: any) {
    const url = this.videourl + `/GetBroadcastDetail?sessionId=${broadcastSessionId.eventId}&eventId=${eventId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   *
   * @param broadcastSessionId
   * @param eventId
   * @returns
   */
  startBroadCast(broadcastSessionId: any, eventId: any) {
    const url = this.videourl + `/StartBroadCast?sessionId=${broadcastSessionId}&eventId=${eventId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   *
   * @param broadcastSessionId
   * @param eventId
   * @returns
   */
  stopBroadCast(eventId: any, broadcastSessionId: any) {
    const url = this.videourl + `/StopBroadCast?eventId=${eventId}&sessionId=${broadcastSessionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Disconnect user by user id and event id
   * @param eventId
   * @param userId
   * @returns
   */
  forceDisconnect = (eventId: number, userId: number) => {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });

    const url = this.coreUrl + `/DisconnectUser?eventId=${eventId}&userId=${userId}`;
    return this.coreHttpService.httpGetRequest(url, headers);
  }

  /**
   * Get User Details by id and role
   * @param userID number
   * @param role number
   * @returns for user details
   * @memberof GeneralService
  **/
  getUserDetailsByIdRole(userID: number, role: string) {
    const url = this.coreDetailUrl + `/GetUserDetails?UserID=${userID}&Role=${role}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * event poll add
   * @param eventId
   * @param question
   * @param option
   * @returns
   */
  public sendEventPoll(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.eventpoll + `/CreatePoll/`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }
  /* Get User Details by id and role
  * @param userID number
  * @param role number
  * @memberof GeneralService
 */
  async getUserDetail(userID: number, role: string) {
    var promise = new Promise((resolve, reject) => {
      this.getUserDetailsByIdRole(userID, role)
        .subscribe((response: any) => {
          let userData = {
            userName: response?.FirstName + ' ' + response?.LastName,
            userJobTitle: response?.JobTitle,
            userCompanyName: response?.CompanyName,
            userCompanyLogo: response?.Company_Logo,
            userID: response?.ID,
            userProfilePicture: response?.Profile
          };
          this.isUserShowDetails$.next(userData);
          resolve(userData);
        })
    });
    return promise;
  }

  /**
   *
   * @param eventID
   * @memberof GeneralService
   */

  userDetailsWithConnection(eventID) {
    const url = this.newUserDetailUrl + `Event?eventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  async getUserDetailWithConnection(eventID) {
    var promise = new Promise((resolve, reject) => {
      this.userDetailsWithConnection(eventID)
        .subscribe((response: any) => {
          for (let i = 0; i < response.length; i++) {
            let userData = {
              userName: response[i]?.firstname + ' ' + response[i]?.lastname,
              userJobTitle: response[i]?.JobTitle,
              userCompanyName: response[i]?.companyname,
              userCompanyLogo: response[i]?.Company_Logo,
              connectionid: response[i]?.ConnectionID
            };
            this.isUserShowDetails$.next(userData);
            resolve(userData);
            console.log(userData);
          }
        })

    });
    return promise;
  }

  /** Save Question ask by Audience
   * @param data any
   * @memberof GeneralService
  **/
  saveAudienceQustion(data: any) {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const url = this.coreDetailUrl + `/AskQuestion?UserID=${data.MemberID}&Question=${data.OpenQuestion}&EventID=${data.EventID}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  // Header for POST request
  private getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Post Audience & Member Feedback for Event
   * @param data any
   * @memberof GeneralService
  */
  public cacheCommanForUserFeedback(data: any) {
    if (!this.cacheCommanSuccessUserFeedback) {
      this.cacheCommanSuccessUserFeedback = this.userFeedbackForEvent(data);
    }
    return this.cacheCommanSuccessUserFeedback;
  }

  /**
   * Post Audience & Member Feedback for Event
   * @param data any
   * @memberof GeneralService
  **/
  public userFeedbackForEvent(data: any) {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const url = this.coreUrl + `/EventFeedback?EventId=${data.EventID}&QuestionID=${data.QuestionID}&AnswerID=${data.AnswerID}&Comment=${data.FeedbackComment}&UserID=${data.UserID}&TypeofUser=${data.TypeOfUser}&Rating=${data.Rating}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  /**
    * event poll edit
    *  * @param PollName
    * @param EventId
    * @param id
    * @returns
    */
  public editEventPoll(data) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.eventpoll + `/EditPollName`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  /**
   * add poll options
   *  * @param pollId
   * @param optionName
   * @returns
   */
  public addPollOptions(options) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.eventpoll + `/AddOptionsToPoll/`;
    return this.coreHttpService.httpPostRequest(url, options, headers);
  }

  /**
 * submit vote to poll
 */
  public SubmitVoteToPoll(options) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.eventpoll + `/SubmitVoteToPoll/`;
    return this.coreHttpService.httpPostRequest(url, options, headers);
  }


  getPollAnsCount(id: any) {
    const url = this.eventpoll + `/GetPollAnswersCountDetail?id=${id}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
 *get all que option list
 * @param eventId
 * @param audienceId
 * @returns
 */
  GetPollListWithOptions(id, audienceId) {
    const url = this.eventpoll + `/GetPollListWithOptions?eventId=${id}&audienceId=${audienceId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * delete question with option
   * @param pollId
   * @returns
   */
  DeleteEventPoll(PollId) {
    const body = {
      'PollId': PollId,
    };
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.eventpoll + `/DeleteEventPoll/`;
    return this.coreHttpService.httpPostRequest(url, body, headers);
  }

  /**
  *get poll option user vote list
  * @param OptionId
  * @returns
  */
  GetPollOptionUserVoteList(OptionId) {
    const url = this.eventpoll + `/GetPollOptionUserVoteList?OptionId=${OptionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   *getsingle poll with options
   * @param pollId
   * @returns
   */
  GetSinglePollWithOptions(pollId) {
    const url = this.eventpoll + `/GetSinglePollWithOptions?pollId=${pollId}`;
    return this.coreHttpService.httpGetRequest(url);
  }
  /* Gets event question by id
  * @param eventID number
  * @memberof GeneralService
  */
  getEventQuestionListById(eventID: number) {
    const url = this.coreDetailUrl + `/GetAllQuestions?EventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Post Audience & Member Feedback for Event
   * @param data any
   * @memberof GeneralService
  */
  public cacheCommanForNetworkRequest(data: any) {
    if (!this.cacheCommanSuccessNetworkRequest) {
      this.cacheCommanSuccessNetworkRequest = this.sendNetworkRequest(data);
    }
    return this.cacheCommanSuccessNetworkRequest;
  }

  /**
   * Post Audience Network Request
   * @param data any
   * @memberof GeneralService
  **/
  public sendNetworkRequest(data: any) {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const url = this.coreUrl + `/SendRequest?RequestSendByMemberID=${data.requestsendbymemberid}&RequestSendToMemberID=${data.requestsendtomemberid}&RequestStatus=${data.requeststatus}&EventId=${data.eventID}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }


  /**
   * Gets event question by id
   * @param eventID number
   * @memberof GeneralService
  */
  public cacheCommonForEventQuestion(eventID: number) {
    if (!this.cacheCommanSuccessGetAllQuestionList) {
      this.cacheCommanSuccessGetAllQuestionList = this.getEventQuestionListById(eventID);
    }
    return this.cacheCommanSuccessGetAllQuestionList;
  }


  /**
   * Get Connection Request by user ID
   * @param eventID number
   * @param userID number
   * @memberof GeneralService
  */
  getConnectRequestListById(userID: number, eventID: number) {
    const url = this.coreUrl + `/RequestList?ID=${userID}&EventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Change status of connection request by requestID and Status
   * @param data any
   * @memberof GeneralService
  */
  setConnectRequestStatus(data: any) {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const url = this.coreUrl + `/RequestStatusChange?ID=${data.requestID}&EventID=${data.eventID}&Status=${data.status}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  /* Gets event feedback question by id
  * @param eventID number
  * @memberof GeneralService
 */
  getFeedbackQuestionById(eventID: number) {
    const url = this.coreUrl + `/GetFeedbackQuestionList?EventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  getListOfScreenShareRequest(eventID) {
    const url = this.coreUrl + `/RequestScreenShareList?EventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * Add new poll in array
   * @param dataObj
   */
  addnewpoll(dataObj) {
    const currentValue = this.pollDetails$.value;
    const updatedValue = [...currentValue, dataObj];
    this.pollDetails$.next(updatedValue);
  }

  /**
   * Update poll in array
   * @param dataObj
   */
  updatepoll(data) {
    this.pollDetails$.next(data);
  }


  /*Save Audience Request
  * @param data
  * @memberof GeneralService
  **/
  saveAudienceRequest(data: any): Observable<unknown> {
    let headers = this.getHeader();
    headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    });
    const url = this.coreUrl + `/RequestScreenShare?Status=${data.Status}&RequestSendBy=${data.userId}&EventID=${data.eventID}&TypeOfRequest=${data.typeOfRequest}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  /**
   * To post/save data of Audience requests
   * @param data
   * @memberof GeneralService
   * @returns cacherequest
  */
  public cacheAudienceRequest(data) {
    if (!this.cacheAudiencSuccesseRequest) {
      this.cacheAudiencSuccesseRequest = this.saveAudienceRequest(data);
    }
    return this.cacheAudiencSuccesseRequest;

  }

  /**
   * @param eventID
   * @memberof GeneralService
   * @returns lisofaudiencerequests
   */
  listOfAudience(eventID: any) {
    const url = this.coreUrl + `/RequestScreenShareList?EventID=${eventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }

  /**
   * to update audience list
   * @param data
   */
  updateAudienceList(data) {
    this.audienceToSpeakerlist$.next(data);
  }

  /**
   * to add new incoming audience request
   * @param dataObj
   */
  addNewAudienceList(dataObj) {
    const currentValue = this.audienceToSpeakerlist$.value;
    const updatedValue = [...currentValue, dataObj];
    this.audienceToSpeakerlist$.next(updatedValue);
  }

  //Get all audiences/Members who have registered for Event
  listAllAudience(eventID: any, MemberID: any, connectionId: any = '') {
    const url = this.eventmember + `/GetEventMemberList?EventID=${eventID}&MemberId=${MemberID}&connectionId=${connectionId}`;
    return this.coreHttpService.httpGetRequest(url);
  }
  /**
   * Get boxId for Admin Side
   * @param boxid
   */
  boxIdAdmin(boxid) {
    this.boxId$.next(boxid);
  }

  public eventSettingChange(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.newUserDetailUrl + `Event/SaveEventSettings`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  public audienceLeaveEvent(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.videourl + `/IsAudienceRemovedOrLeft?memberId=${data.UserID}&EventId=${data.EventID}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  public isAdhocSpeaker(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const url = this.videourl + `/IsAdhocSpeaker?memberId=${data.UserID}&EventId=${data.EventID}`;
    return this.coreHttpService.httpPostRequest(url, data, headers);
  }

  redirectionToFeedbackPage(userId: number, eventId: number, role) {
    const url = `${environment.frontendSiteUrl}feedback?role=${role}&userId=${userId}&eventId=${eventId}`;
    window.location.href = url;
    // window.open(url,"_target");
  }

  getAllRequestsofMember(sendByID,sendToID,EventID){
    const url = this.coreUrl + `/GetAllRequestListOfMemberNetwork?sendbyid=${sendByID}&sendtoid=${sendToID}&EventId=${EventID}`;
    return this.coreHttpService.httpGetRequest(url);
  }



  //http://localhost:55959/api/Video/BecomespeakerToModerator?userid=1349&EventId=6848&channelID=2238
  //http://localhost:55959/api/video/IsAudienceRemovedOrLeft?memberId=3781&EventId=3608

}
