import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';

@Component({
  selector: 'app-report-user-dialog',
  templateUrl: './report-user-dialog.component.html',
  styleUrls: ['./report-user-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})
export class ReportUserComponent implements OnInit {

  title: string;
  closeBtnName: string;
  list: any[] = [];

  isReported: boolean = false;

  constructor() { }

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
}
