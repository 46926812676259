<div class="modal-header">
  <h4 class="pull-left">Session Closed</h4>
</div>
<div class="modal-body text-center">
  <div class="leave-modal-layout">
    <h4 class="font-14 line-height-20 grotesk-medium color-black">
      The discussion has now ended.
    </h4>
  </div>
  <div class="col-12 text-center mt-4 mb-2">
    <button type="button" class="btn-common font-14 line-height-24 gibson_medium" (click)="discussionCompleted()">
      Ok
    </button>
  </div>
</div>
