import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HideShowService {
  private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private navStyle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private navStyleSetting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showNavSetting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public router: Router) {
    // here any navigation events (i.e. clicking on the nav items to go to a separate page)
    // will be captured inside this subscription, then we hide the nav by setting
    // the showNav$ to false or in our case, calling the setShowNav to false, which does the same
    // router.events.subscribe(() => {
    //   this.setShowNav(false);
    // });
  }

  // For show/hide right side nav bar
  getShowNav() {
    return this.showNav$.asObservable();
  }

  getNavStyle() {
    return this.navStyle$.asObservable();
  }

  setShowNav(showHide: boolean) {
    this.showNav$.next(showHide);
  }

  setNavStyle(showHide: boolean) {
    this.navStyle$.next(showHide);
  }

  // // For show/hide right setting side a nav bar
  getShowNavSetting() {
    return this.showNavSetting$.asObservable();
  }

  setShowNavSetting(showHides: boolean) {
    this.showNavSetting$.next(showHides);
  }

  getNavStyleSetting() {
    return this.navStyleSetting$.asObservable();
  }

  setNavStyleSetting(showHides: boolean) {
    this.navStyleSetting$.next(showHides);
  }

  // For dialog open/close

  getShowHideModal() {
    return this.showModal$.asObservable();
  }

  setShowHideModal(showHide: boolean) {
    this.showModal$.next(showHide);
  }

}
