import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RolesEnums } from 'src/app/core/enums';
import { UtilityService } from 'src/app/core/utilities';
import { AuthService, GeneralService, HideShowService } from '../../../../shared/services';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-feedback-content-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})

export class FeedbackComponent implements OnInit {
  @Output() closeFeedBackDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  redirectsiteurl = environment.siteurl;
  openNextFeedbackDialog: boolean = false;

  feedbackForm: FormGroup;
  submmited: boolean = false;

  rateArray: number[] = [1, 2, 3, 4, 5];
  rateValueOfUser: number = 0;
  rateValueSelected: number;
  isUserCredentials: any;
  selectedItem: boolean = false;
  selected: any;
  feedbackQuestion: any;
  currentlyChecked: any[] = [];
  showRatingerror: boolean = false;
  isChecked: boolean = false;

  constructor(
    private fb: FormBuilder,
    public generalService: GeneralService,
    public hideShowService: HideShowService,
    public router: Router,
    public authService: AuthService,
    public utilityService: UtilityService
  ) { }

  /**
   * speaker force disconnect
  */
  forceDisconnectUser() {
    this.generalService.forceDisconnect(this.generalService.isEventID, this.generalService.isUserID)
      .subscribe((res: any) => {
        this.closeFeedBackDialog.emit();
        this.authService.logout();
        // this.utilityService.openLinkInSameTab(this.redirectsiteurl);
      });
  }

  ngOnInit() {
    // this.openNextFeedbackDialog = false;
    this.getFeedbackQuestion();
    this.getFeedbackForm();
    // To fetching the logged in user token
    this.generalService.isUserCredentialsExits$.subscribe((isUserCredentials: any) => {
      if (isUserCredentials) {
        this.isUserCredentials = isUserCredentials;
      }
    });
  }

  // feedback form
  getFeedbackForm() {
    this.feedbackForm = this.fb.group({
      EventID: [''],
      QuestionID: [''],
      AnswerID: [null, Validators.required],
      FeedbackComment: ['', Validators.required],
      UserID: [''],
      TypeOfUser: [''],
      Rating: ['']
    });
  }

  openDialogforNextFeedback() {
    if (this.rateValueOfUser !== 0) {
      this.openNextFeedbackDialog = true;
    } else {
      this.showRatingerror = true;
    }
  }

  // Getter method to access formcontrols
  get feedbackFormControl() {
    return this.feedbackForm.controls;
  }

  // Get User Selected rate for Event
  onClickRate(rateValue) {
    if (rateValue == 1) {
      this.rateValueOfUser = rateValue;
    } else if (rateValue == 2) {
      this.rateValueOfUser = rateValue;
    } else if (rateValue == 3) {
      this.rateValueOfUser = rateValue;
    } else if (rateValue == 4) {
      this.rateValueOfUser = rateValue;
    } else if (rateValue == 5) {
      this.rateValueOfUser = rateValue;
    } else if (rateValue == undefined || '' || null) {
      this.rateValueOfUser = 0;
    } else {
      this.rateValueOfUser = this.rateValueSelected;
    }
    if (this.showRatingerror) {
      this.showRatingerror = false;
    }
  }

  // save Feedback
  async onSubmit() {
    let sessiondata: any = await this.utilityService.getSessionConnection();
    this.submmited = true;
    if (!this.feedbackForm.valid) {
      return false;
    } else {
      let sessionData: any = await this.utilityService.getSessionConnection();
      let data = this.feedbackForm.getRawValue();
      data['Rating'] = this.rateValueOfUser;
      data['EventID'] = this.generalService.isEventID;
      if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.SpeakerOrModerator) {
        data['TypeOfUser'] = RolesEnums.RoleType.SpeakerOrModerator;
        data['UserID'] = this.generalService.isUserID;
      } else if (this.isUserCredentials.roleNumber === RolesEnums.RoleType.Audience) {
        data['TypeOfUser'] = RolesEnums.RoleType.Audience;
        data['UserID'] = this.generalService.isUserID;
      }
      data['QuestionID'] = this.feedbackQuestion[0].QuestionID;
      data['AnswerID'] = this.currentlyChecked;


      this.generalService.cacheCommanForUserFeedback(data).subscribe(response => {
        if (response) {
          if (this.generalService.isRole === RolesEnums.Roles.Audience) {
            this.generalService.audienceLeaveEvent(data).subscribe((res: any) => {
              if (res.Status == 200) {
                this.utilityService.audienceLeftEvent(sessiondata,this.generalService.isUserID);
                this.forceDisconnectUser();
                // this.utilityService.sendSignalForAdhocSpeakerLeft(sessiondata);
              }
            });
          } else {
            this.utilityService.sendSignalForAdhocSpeakerLeft(sessionData);
            this.forceDisconnectUser();
          }
          // this.generalService.audienceLeftEvent(data).subscribe((res: any) => {
          //   if (res.Status == 200) {
          //     this.utilityService.audienceLeftEvent(sessionData, data);
          //     this.forceDisconnectUser();
          //   }
          // });
        }
      });
    }

  }

  // on select rate add class isActive
  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };

  // Get Feedback questions
  getFeedbackQuestion() {
    this.generalService.getFeedbackQuestionById(this.generalService.isEventID).subscribe((response: any) => {
      this.feedbackQuestion = response.Data;
    });
  }

  // select on one Answer of feedback question and change value of selected answerID
  isAllSelected(item?: any) {
    this.currentlyChecked = []
    // console.log(this.currentlyChecked)
    this.feedbackQuestion[0]?.['Answer'].forEach(val => {
      if (val.AnswerID == item) {
        val.isSelected = !val.isSelected;
        this.currentlyChecked.push(val.AnswerID);
      } else {
        val.isSelected = false;
        for (var i = 0; i < this.currentlyChecked.length; i++) {
          if (this.currentlyChecked[i] === val.AnswerID) {
            this.currentlyChecked.splice(i, 1);
          }
        }
      }
    });
    // this.currentlyChecked = [];
    // const checked = event.target.checked;
    // if (checked) {
    //   this.feedbackQuestion[1]?.['Answer'].forEach((item: any) => {
    //     if (item.AnswerID == answerID) {
    //       item.isSelected = true;
    //       this.currentlyChecked.push(item.AnswerID);
    //     }
    //   });
    // } else {
    //   this.feedbackQuestion[1]?.['Answer'].forEach((item: any) => {
    //     if (item.AnswerID == answerID) {
    //       item.isSelected = false;
    //       this.currentlyChecked.splice(this.currentlyChecked.indexOf(item.AnswerID), 1)
    //     }
    //   });
    // }
  }

}
