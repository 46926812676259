<div class="full-wrapper-layout">
  <app-user-video-screen class="w-100" [isWidthForVideoScreen]="isWidthForVideoScreen"
    (questionList)="getAllEventQuestionList($event)"></app-user-video-screen>
  <app-nav-right *ngIf="(navStyle | async)" [sidenavTemplateRef]="navContent" [direction]="'right'"
    [navWidth]="isWidthForNavRightScreen" [duration]="0.5"></app-nav-right>
</div>

<ng-template #navContent>
  <!-- Contents -->
  <mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="!isSettingSection" animationDuration="0ms"
    class="custome-tabing-box">
    <mat-tab label="Chat" *ngIf="eventsetting?.isLiveChatEnabled">
      <div>
        <div class="d-flex justify-content-center navigation-buttons common-tab-shadow">
          <ul class="nav nav-pills chat-types" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                aria-controls="pills-home" aria-selected="true">Public</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                aria-controls="pills-profile" aria-selected="false">Private</a>
            </li>
          </ul>
          <div class="divider-bg"></div>
        </div>
        <div *ngIf="isModerator||isSpeaker" class="d-flex justify-content-center common-tab-shadow speaker-mod">
          Speakers & Moderators
        </div>
      </div>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="public-chat common-scroll-layout scroller scrollbar-width-thin chat-user-list">
            <div class="public-chat-details">
              <div class="user-img">
                <img class="rounded-img" src="/assets/images/user-38.png" />
              </div>
              <div class="public-user-details">
                <div class="chat-user-name">
                  Moderators & Speakers
                  <span class="private-chat-time">15m</span>
                </div>
                <div class="public-chats">
                  It's a new company.
                </div>
              </div>
            </div>
            <div class="public-chat-details">
              <div class="user-img">
                <img class="rounded-img" src="/assets/images/user-38.png" />
              </div>
              <div class="public-user-details">
                <div class="chat-user-name">
                  Arthur Stephan
                  <span class="private-chat-time">Typing</span>
                </div>
                <div class="public-chats">
                  I've heard about it.
                </div>
              </div>
            </div>
            <div class="public-chat-details">
              <div class="user-img">
                <img class="rounded-img" src="/assets/images/user-38.png" />
              </div>
              <div class="public-user-details">
                <div class="chat-user-name">
                  Adarsh Gupta
                  <span class="private-chat-time">40Mins</span>
                </div>
                <div class="public-chats">
                  A friend works there!
                </div>
              </div>
            </div>
            <div class="public-chat-details">
              <div class="user-img">
                <img class="rounded-img" src="/assets/images/user-38.png" />
              </div>
              <div class="public-user-details">
                <div class="chat-user-name">
                  Arthur Stephan
                  <span class="private-chat-time">1h</span>
                </div>
                <div class="public-chats">
                  I can get his contact soon.
                </div>
              </div>
            </div>
            <div class="public-chat-details">
              <div class="user-img">
                <img class="rounded-img" src="/assets/images/user-38.png" />
              </div>
              <div class="public-user-details">
                <div class="chat-user-name">
                  Martin Garix
                </div>
                <div class="public-chats">
                  It's a new company.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="private-user-wrapper polls-question-layout">
            <div class="private-user-details text-center chat-user-box">
              <div class="arrow-back-chat">
                <img src="../../../../assets/images/right-arrow-chat.svg" alt="Arrow Right" />
              </div>
              <div class="main-chat-user-info">
                <p class="chat-user-name caption-heading-user m-0">
                  Arthur Stephan
                </p>
                <p class="user-postion caption-sub-heading m-0">Product Manager,
                  <span>Samsung Inc.</span>
                </p>
              </div>
              <div class="menu-user-image mr-2">
                <img src="/assets/images/menu.png" />
              </div>
              <div class="private-user-image">
                <img src="../../../../assets/images/chat-user.svg" />
              </div>
            </div>
            <div class="private-chat-list common-scroll-layout scroller scrollbar-width-thin"
              *ngIf="allmessages && allmessages.length>0">
              <div class="private-chat-layout" *ngFor="let x of allmessages">
                <div class="mb-3">
                  <div class="private-chat-content left-chat" *ngIf="userID!=x.UserId">
                    <div class="chat-image">
                      <img class="rounded-img" style="height: 25px;width: 25px;" [src]="x.profile" />
                    </div>
                    <div class="chat-container">
                      <img src="assets/images/bg-chat-l.png" alt="Chat" class="img-fluid left-chat-bg">
                      <div class="chat-text">
                        {{x.Message}}
                      </div>
                    </div>
                  </div>
                  <div class="private-chat-content right-chat" *ngIf="userID==x.UserId">
                    <div class="chat-container">
                      <img src="assets/images/bg-chat-R.png" alt="Chat" class="img-fluid right-chat-bg">
                      <div class="chat-text">
                        {{x.Message}}
                      </div>
                    </div>
                    <div class="chat-image">
                      <img class="rounded-img" style="height: 25px;width: 25px;" [src]="x.profile" />
                    </div>
                  </div>
                </div>

                <!-- <div class="mb-3">
                  <div class="private-chat-content left-chat">
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                    <div class="chat-container">
                      <img src="assets/images/bg-chat-l.png" alt="Chat" class="img-fluid left-chat-bg">
                      <div class="chat-text">
                        Yes, I do. A lot.
                      </div>
                    </div>
                  </div>
                  <div class="private-chat-content left-chat">
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                    <div class="chat-container">
                      <img src="assets/images/bg-chat-l.png" alt="Chat" class="img-fluid left-chat-bg">
                      <div class="chat-text">
                        I started recently and it has been a little difficult learning.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="private-chat-content right-chat">
                    <div class="chat-container">
                      <img alt="Chat" class="img-fluid right-chat-bg" src="assets/images/bg-chat-R.png">
                      <div class="chat-text">
                        Seems difficult!
                      </div>
                    </div>
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                  </div>
                  <div class="private-chat-content right-chat">
                    <div class="chat-container">
                      <img alt="Chat" class="img-fluid right-chat-bg" src="assets/images/bg-chat-R.png">
                      <div class="chat-text">
                        I suggest you invest for long term benefits, not short term.
                      </div>
                    </div>
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                  </div>
                  <div class="private-chat-content right-chat">
                    <div class="chat-container">
                      <img alt="Chat" class="img-fluid right-chat-bg" src="assets/images/bg-chat-R.png">
                      <div class="chat-text">
                        If you want, I could get some mentors for you.
                      </div>
                    </div>
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="private-chat-content left-chat">
                    <div class="chat-image">
                      <img class="rounded-img" src="/assets/images/chat-user-2.png" />
                    </div>
                    <div class="chat-container">
                      <img src="assets/images/bg-chat-l.png" alt="Chat" class="img-fluid left-chat-bg">
                      <div class="chat-text">
                        Yes, I do. A lot.
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="bottom-chat-typing">
            <div class="mt-2 type-a-message">
              <textarea [(ngModel)]="currentmessage" (keyup.enter)="sendMessage()"
                placeholder="Type your message"></textarea>
              <div class="chat-box-layout">
                <button>
                  <img src="../../../../assets/images/smile.svg" alt="Emoji" />
                </button>
                <button class="send-btn-icon">
                  <img src="../../../../assets/images/send-icon.svg" alt="Send" (click)="sendMessage()" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isModerator||isSpeaker" class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="public-chat common-scroll-layout scroller scrollbar-width-thin">
            <div *ngFor="let x of allmessages">
              <div class="mb-3">
                <div class="private-chat-content left-chat" *ngIf="userID!=x.UserId">
                  <div class="chat-image">
                    <img class="rounded-img" style="height: 25px;width: 25px;" [src]="x.profile" />
                  </div>
                  <div class="chat-container">
                    <img src="assets/images/bg-chat-l.png" alt="Chat" class="img-fluid left-chat-bg">
                    <div class="chat-text">
                      {{x.Message}}
                    </div>
                  </div>
                </div>
                <div class="private-chat-content right-chat" *ngIf="userID==x.UserId">
                  <div class="chat-container">
                    <img src="assets/images/bg-chat-R.png" alt="Chat" class="img-fluid right-chat-bg">
                    <div class="chat-text">
                      {{x.Message}}
                    </div>
                  </div>
                  <div class="chat-image">
                    <img class="rounded-img" style="height: 25px;width: 25px;" [src]="x.profile" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="bottom-chat-typing" *ngIf="!isAdminOrAudience">
        <div class="mt-2 type-a-message">
          <textarea [(ngModel)]="currentmessage" (keyup.enter)="sendMessage()"
            placeholder="Type your message"></textarea>
          <div class="chat-box-layout">
            <button>
              <img src="../../../../assets/images/smile.svg" (click)="toggleEmojiPicker()" alt="Emoji" />
              <emoji-mart class="emo-hw" title="Pick your emoji…" emoji="point_up" *ngIf="showEmojiPicker"
                (emojiSelect)="addEmoji($event)"></emoji-mart>
            </button>
            <button class="send-btn-icon">
              <img src="../../../../assets/images/send-icon.svg" alt="Send" (click)="sendMessage()" />
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Questions">
      <div>
        <div class="d-flex justify-content-center navigation-buttons common-tab-shadow pb-1">
          <ul class="nav nav-pills chat-types" id="pills-que-tab" role="tablist">
            <li class="nav-item" role="presentation" *ngIf="eventsetting?.isQAEnabled || ''">
              <a class="nav-link active" id="pills-home-Quetab" data-toggle="pill" href="#pills-Ques" role="tab"
                aria-controls="pills-home" aria-selected="true">Questions</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-profile-polltab" data-toggle="pill" href="#pills-poll" role="tab"
                aria-controls="pills-profile" aria-selected="false">Polls</a>
            </li>
          </ul>
        </div>
        <div class="tab-content mt-2 polls-layout-box" id="pills-que-tabContent">
          <div class="tab-pane fade show active common-scroll-layout scroller scrollbar-width-thin" id="pills-Ques"
            role="tabpanel" aria-labelledby="pills-home-Quetab" *ngIf="eventsetting?.isQAEnabled || ''">
            <div class="question-layout" *ngFor="let item of receivedQuestion">
              <div class="question-part-caption">
                <h5> {{ item.NAME }} </h5>
                <span></span>
                <h5>{{item.CompanyName}}</h5>
              </div>
              <div class="ans-part-caption">
                <p> {{ item.Question }} </p>
              </div>
            </div>
            <div class="no-question-asked" *ngIf="receivedQuestion.length==0">
              No questions
            </div>
            <!-- <div class="question-layout">
              <div class="question-part-caption">
                <h5>Joseph Atada</h5>
                <span></span>
                <h5>Facebook</h5>
              </div>
              <div class="ans-part-caption">
                <p>What does ROI mean in forex?</p>
              </div>
            </div> -->
          </div>
          <div class="tab-pane fade" id="pills-poll" role="tabpanel" aria-labelledby="pills-profile-polltab">
            <div class="public-chat pt-3" *ngIf="isModeratorOrSpeakerOrAdmin">
              <div class="user-question-wrapper" href="#edit">

                <form [formGroup]="pollform">
                  <div class="user-question" *ngIf="deletePollView">
                    <div class="question-caption-layout">
                      <h5 class="common-question-heading">Type your question here.</h5>
                      <a class="curser-po delete_poll cursor-pointer" (click)="deletePoll()">Delete Poll</a>
                    </div>
                    <textarea formControlName="PollName"></textarea>
                    <div class="error" *ngIf="pollform.controls.PollName.errors?.fifteenCharAndSpace && pollform.controls.PollName.dirty ">
                      Please add at least one space
                    </div>
                    <!-- <div *ngIf="pollform.controls.PollName.errors?.fifteenCharWithSpace || pollform.controls.PollName.errors?.fifteenCharWithSpace">Please enter 15 characters, followed by a space, and then another 15 characters</div> -->
                    <div class="error" *ngIf="pollSubmitted && pollform.controls.PollName.errors">
                      Poll question is required.
                    </div>
                    <div class="option-panel-layout">
                      <h5 class="common-question-heading mt-3 mb-1">Add options here.</h5>

                      <form formArrayName="PollOptionList">
                        <div *ngFor="let emp of PollOptionList.controls; let i = index" [formGroupName]="i"
                          class="employee">
                          <ul>
                            <li>
                              <input type="text" placeholder="Option {{i+1}}" formControlName="OptionName" />
                              <a class="cursor">
                                <img src="../../../../assets/images/close-black.svg" alt="Close"
                                  (click)="removePollOption(i)" />
                              </a>
                            </li>
                          </ul>
                          <div class="error"
                            *ngIf="pollSubmitted && pollform.controls.PollOptionList['controls'][i]['controls'].OptionName.errors">
                            Poll Option is required.
                          </div>
                          <div class="error" *ngIf="pollform.controls.PollOptionList['controls'][i]['controls'].OptionName.errors?.fifteenCharAndSpace && pollform.controls.PollOptionList['controls'][i]['controls'].OptionName.dirty">
                          Please add at least one space
                        </div>
                        </div>
                      </form>
                      <div class="add-option-btn">
                        <button type="button" (click)="addOptionsForPoll()"><img
                            src="../../../../assets/images/add-option.svg" alt="Add Option" class="mr-2" />
                          Add another option</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="error" *ngIf="noOptionAdded">
                Enter at least two options
              </div>
              <div class="error" *ngIf="oneOptionAdded">
                Please add at least two options
              </div>
              <!-- <button type="button">Add Another Question</button> -->
              <button class="d-flex align-items-center justify-content-center p-0 gibson_medium mt-3" type="submit"
                *ngIf="deletePollView && !isShowLoader" (click)="postEventPoll()">
                <span class="btn-text text-capitalize">{{buttonText}} Poll</span>
              </button>
              <button class="d-flex align-items-center justify-content-center p-0 gibson_medium mt-3" type="submit"
                *ngIf="deletePollView && isShowLoader">
                <span class="btn-loader">
                  {{buttonText}} Poll
                  &nbsp;
                  <div class="text-center loader-wrapper2 btn-text btn-loader">
                    <div class="loaderSpinner"></div>
                  </div>
                </span>
              </button>
            </div>
            <div class="poll-question-layout">
              <div class="private-user-wrapper polls-question-layout" *ngFor="let data of polls; let i  = index">
                <div class="poll-chat-list">
                  <div class="poll-list-wrapper">
                    <div class="chat-user-name pt-3 p-0">
                      <div class="row">
                        <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                          <h6 class="main-position-name">
                            Q.
                            <span class="position"> {{data.PollName}}</span>
                          </h6>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 d-flex align-items-center"
                          *ngIf="isAdmin || isModerator">
                          <img src="assets/images/editpoll.svg" id="edit" class="editIcon mt-1 cursor"
                            alt="Edit Question" (click)="editPoll(data)" />
                          <img src="assets/images/delete.svg" class="editIcon mt-1 cursor ml-1" alt="Delete Question"
                            (click)="deleteData(data)" [ngClass]="{'my_class': isPollDeleted}" />
                        </div>
                      </div>

                      <div class="poll-list-option p-0">
                        <ul>
                          <div *ngIf="isModeratorOrSpeakerOrAdmin">
                            <li *ngFor="let item of data.PollOptionList" (click)="submitQuestionAnswer(item)">
                              <div class="progress progress_custom ">
                                <div class="progress-bar bar polls-bar-layout">
                                  <a role="progressbar" style="z-index: 1;" [style.width.%]="item.Counts" aria-valuemin="0"
                                  aria-valuemax="100">
                                  </a>
                                  <div class="poll-layout-section" *ngIf="!isSpeaker">
                                    <div class="">
                                      <p class="poll-vote black">{{item.OptionName}}</p>
                                    </div>
                                    <div class="progress-content">
                                      <span class="position vote_color"
                                        (click)="openPollVoteUsersPopUp(item)">{{item.Counts}}
                                        Votes</span>
                                    </div>
                                  </div>
                                  <div class="poll-layout-section cursor-default" *ngIf="isSpeaker">
                                    <div class="progress-contentt">
                                      <p class="poll-vote black">{{item.OptionName}}</p>
                                    </div>
                                    <div class="progress-contentt">
                                      <span class="position vote_color cursor-default">{{item.Counts}}
                                        Votes</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                          <div *ngIf="isAudience">
                            <li *ngFor="let item of data.PollOptionList"
                              (click)="data.IsAnswered==1?false:submitQuestionAnswer(item)">
                              <div *ngIf="item.Counts == 0">
                                <div class="poll-layout-section">
                                  <div class="progress progress_custom">
                                    <div class="progress-bar bar polls-bar-layout">
                                      <div class="poll-layout-section">
                                        <a role="progressbar" [style.width.%]="item.Counts" aria-valuemin="0"
                                          aria-valuemax="100"></a>
                                        <span class="poll-vote">{{item.OptionName}}</span>
                                        <div class="progress-content">
                                          <span class="position">{{item.Counts}} Votes</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li *ngFor="let item of data.PollOptionList"
                              (click)="data.IsAnswered==1?false:submitQuestionAnswer(item)">
                              <div *ngIf="item.Counts > 0">
                                <div class="poll-layout-section">
                                  <div class="progress progress_custom">
                                    <div class="progress-bar bar polls-bar-layout">
                                      <div class="poll-layout-section">
                                        <a role="progressbar" [style.width.%]="item.Counts" aria-valuemin="0"
                                          aria-valuemax="100"></a>
                                        <span class="poll-vote">{{item.OptionName}}</span>
                                        <div class="progress-content">
                                          <span class="position">{{item.Counts}} Votes</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-polls" *ngIf="!polls || polls.length == 0">
                No polls
              </div>
            </div>
            <div class="public-chat new-public-btn">
              <button *ngIf="addPollView && adminAccess" (click)="addPoll()">Add New
                Poll</button>
            </div>
            <div class="poll-deleted" *ngIf="showpollmessage">
              <img src="../../../../assets/images/green-tick.svg" alt="Tick" class="img-fluid mr-2">
              <p class="mb-0">Poll {{messageText}} successfully</p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Audience" *ngIf="!isSpeaker">
      <div id="audiance-list" class="audiance-list common-scroll-layout scroller scrollbar-width-thin"
        *ngIf="audienceData && audienceData.length > 0">
        <div *ngFor="let item of audienceData" class="audiance-wrapper">
          <div class="audiance-profile-img">
            <img [src]="item?.Photo ? item?.Photo : 'assets/images/default-user.png'" alt="Audiance" class="img-fluid">
            <!-- <app-avatar-photo *ngIf="item !== undefined" [photoUrl]="item?.Photo"  round="false"
              class="avtar-listing-icon">
            </app-avatar-photo> -->
          </div>
          <div class="audiance-box-layout">
            <div class="group-caption-box">
              <div class="audiance-name">
                {{item?.FirstName + " " + item?.LastName}}
                <!-- {{item?.userName}} -->
              </div>
              <div class="audiance-position mb-0">
                {{item?.CompanyName}}
              </div>
            </div>
            <div class="chat-btn">
              <a class="mx-1">
                <img src="../../../../assets/images/message-icon.svg" alt="Message Box" />
              </a>
              <a *ngIf="isAudience && userID!=item.MemberID" (click)="sendNetworkReqestAudience(item)">
                <img src="../../../../assets/images/white-plush.svg" alt="Plush" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="audienceData.length==0">
        <div class="no-aud">
          No audiences have joined event
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>


  <div  class="custome-tabing-box">
    <div *ngIf="!settingusers && !openaddons && settingsMenu">
      <div class="customize-heading-div">
        <div class="box">
          <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
        </div>
        <h5 class="ms-3 secondary-font">Settings</h5>
        <img style="margin-left: auto;" src="../assets/images/close2.png" (click)="settingsdialogclose()">
      </div>
      <div class="blank-div"></div>
      <div class="content">
        <div class="blank-div"></div>
        <div (click)="openSettingsMenu()">
          <div class="secondary-font">Customize Event</div>
          <p class="para">Make changes to event layout and select
            what your audience is able to see.</p>
        </div>
        <div (click)="openAddonsMenu()">
          <div class="secondary-font">Add-Ons</div>
          <p class="para">Running out of time or need extra space for
            your audience? Pay for extra here.</p>
        </div>
      </div>

    </div>
    <app-settings-users *ngIf="!openaddons && settingusers" (settingsdialogClose)="settingsdialogclose()">
    </app-settings-users>
    <app-addons *ngIf="openaddons && !settingusers" (settingsdialogClose)="settingsdialogclose()"></app-addons>
  </div>

  <!-- End -->
</ng-template>

<app-dialog [(visible)]="broadCastStarted" [dialogClass]="'popup-width-618'">
  <app-connecting-dialog></app-connecting-dialog>
</app-dialog>

<div class="req-sent-success" *ngIf="showSuccessMsg">
  <img src="../../../../assets/images/green-tick.svg" alt="Tick" class="img-fluid mr-2">
  <p class="mb-0">Connection request has been sent successfully</p>
</div>

<div class="req-sent-success" *ngIf="showRequestSentMsg">
  <img src="../../../../assets/images/green-tick.svg" alt="Tick" class="img-fluid mr-2">
  <p class="mb-0">Connection request is already sent</p>
</div>

<div class="req-sent-success" *ngIf="showMessageOfAcceptReq">
  <img src="../../../../assets/images/green-tick.svg" alt="Tick" class="img-fluid mr-2">
  <p class="mb-0">Your request is accepted please check you network</p>
</div>
