<!-- First Footer Bar -->
<div class="d-flex justify-content-between align-center banner video-footer-bar">
  <!-- column 1 ( left side ) -->
  <div class="column1 d-flex w-auto">

    <!-- Sound Outline Settings -->
    <div class="dark md-show-click">
      <button [matMenuTriggerFor]="voiceLayout" class="p-0 border-0 bg-transparent">
        <img src="../../../../assets/images/sound-outline.svg" alt="Sound" class="img-fluid" />
      </button>
      <mat-menu #voiceLayout="matMenu" class="p-0 grey-shade1 dropdown" yPosition="above">
        <div class="modal-body audio-voice-body p-0 grey-shade1">
          <ul class="audio-box-layout p-0 m-0 pt-2 pb-2">
            <li mat-menu-item class="audio-list" *ngFor="let audiooutput of audioOutputs;let i=index">
              <!-- <span *ngIf="!audiooutput">No audio</span> -->
              <p class="color-white ml-2 font-14 line-height-14 gibson-book-font">
                {{audiooutput.label}}
              </p>
            </li>
          </ul>
        </div>
      </mat-menu>
    </div>


    <!-- Event Information -->
    <div class="dark md-show-click " (click)="showEventInfoDialog = !showEventInfoDialog">
      <a><img src="../../../../assets/images/info.svg" alt="Info" class="img-fluid" /></a>
    </div>

    <!-- Audience Count -->
    <!-- commenting this code according to D-1-I726 as digital goodie bag will be considered in next phase -->
    <!-- <div
      *ngIf="isAudience && (eventsetting?.isAudienceCountEnabled)"
      class="dark md-show-click " (click)="digitalGoodiesDialog = !digitalGoodiesDialog">
      <a><img src="../../../../assets/images/user.svg" alt="Users" class="img-fluid" />
        <span class="count-number color-white">{{audienceCount}}</span></a>
    </div> -->
    <div *ngIf="eventsetting?.isAudienceCountEnabled && !isSpeaker" class="dark md-show-click "
      (click)="openaudiencetab(2)">
      <a><img src="../../../../assets/images/user.svg" alt="Users" class="img-fluid" />
        <span class="count-number color-white">{{audienceCount}}</span></a>
    </div>

    <!-- <div class="">
      <p class="text-trasnform-upper font-12 white-fg montserrat-font line-height-44 d-inline my-0 ml-2">
        <span class="live-status m-0 mr-1 recording-color"></span>recording
      </p>
    </div> -->
  </div>
  <!-- column 2 ( middle ) -->
  <div class="column2 d-flex w-auto">
    <!-- Screen Icon -->
    <div *ngIf="isAdminOrSpeaker && !isAdmin" class="dark md-show-click " (click)="shareyourscreen = !shareyourscreen">
      <a><img src="../../../../assets/images/computer.svg" alt="Computer" class="img-fluid"></a>
    </div>
    <div *ngIf="isAdmin || isModerator" class="dark md-show-click "
      (click)="screensharepermission = !screensharepermission">
      <a><img src="../../../../assets/images/computer.svg" alt="Computer" class="img-fluid"></a>
    </div>
    <!-- Add users Icon -->
    <div *ngIf="isAdmin || isModerator" class="dark md-show-click ">
      <div ngbDropdown class="alert-layout-drp" (click)="joinrequestdialog()">
        <button class="p-0 alert-btn-drp p-0 bg-transparent" id="dropdownBasic1" ngbDropdownToggle>
          <img src="../../../../assets/images/add-person.svg" alt="Add Person" class="img-fluid">
        </button>
        <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="alert-layout-drp-layout">
          <div class="alert-body">
            <img src="assets/images/tick-green.svg" alt="Sucess" class="img-fluid">
            <p class="mb-0 ml-3 color-white gibson-book-font font-14 line-height-24 letter-spacing-0-2">
              Your
              request to join speakers has been accepted</p>
          </div>
        </div> -->
      </div>
    </div>
    <!-- Ask Questions -->
    <div *ngIf="eventsetting?.isQAEnabled">
      <div *ngIf="isAudience && !isSpeaker" class="dark md-show-click" (click)="askQuestionDialog = !askQuestionDialog">
        <a style="position: relative;">
          <img src="../../../../assets/images/question.svg" alt="Question" class="img-fluid" />
        </a>
      </div>
      <div *ngIf="isAdmin || isModerator" class="dark md-show-click position-relative" (click)="openaudiencetab(1)">
        <div class="info-layout-md question-count">
          <p class="question-count-number">{{questioncount}}</p>
        </div>
        <a style="position: relative;">
          <img src="../../../../assets/images/question.svg" alt="Question" class="img-fluid" />
        </a>
      </div>
    </div>

    <!-- Report Users -->
    <div *ngIf="isAdmin|| isModerator" class="dark md-show-click" (click)="openreportuserlist = !openreportuserlist">
      <a><img src="../../../../assets/images/report-icon.svg" alt="Report" class="img-fluid"></a>
    </div>
    <!-- <div *ngIf="isAudienceOrSpeaker" class="dark md-show-click" (click)="openReportUserDialog = !openReportUserDialog">
      <a><img src="../../../../assets/images/report-icon.svg" alt="Report" class="img-fluid"></a>
    </div> -->
    <div *ngIf="isAudience" class="dark md-show-click" (click)="openReportUserDialog = !openReportUserDialog">
      <a><img src="../../../../assets/images/report-icon.svg" alt="Report" class="img-fluid"></a>
    </div>
    <!-- Video Icon -->
    <div class="dark md-show-click" *ngIf="isMuteOrUnmuteVideo && !isAdmin && !isAudience">
      <a (click)="muteVideo()">
        <img src="../../../../assets/images/video.svg" alt="Video Chat" class="img-fluid vedio-main-bg" />
      </a>
    </div>
    <div class="dark md-show-click" *ngIf="!isMuteOrUnmuteVideo && !isAdmin">
      <a (click)="unMuteVideo()">
        <img src="../../../../assets/images/no-video-icon.svg" alt="Video Chat" class="img-fluid vedio-main-bg" />
      </a>
    </div>
    <!-- Mic Icon -->
    <div class="dark md-show-click" *ngIf="isMuteOrUnmuteAudio && !isAdmin && !isAudience">
      <a (click)="muteAudio()"><img src="../../../../assets/images/micro.svg" alt="Microphone" class="img-fluid" /></a>
    </div>
    <div class="dark md-show-click" *ngIf="!isMuteOrUnmuteAudio && !isAdmin">
      <a (click)="unMuteAudio()"><img src="../../../../assets/images/mute-audio.svg" alt="Microphone"
          class="img-fluid" /></a>
    </div>

    <div *ngIf="!broadcaststarted && !isAudienceOrSpeaker">
      <a class="montserrat-font broadcast-btn white-fg bg-primary btn-primary-color mr-2"
        (click)="startBroadcastByAdmin()">
        Start Broadcast
      </a>
    </div>

    <div ngbDropdown class="dropdown" *ngIf="broadcaststarted && !isAudienceOrSpeaker">
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="montserrat-font broadcast-btn white-fg" id="dropdownBasic1" ngbDropdownToggle>Stop
          Broadcast</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drp-custom">
          <button class="montserrat-font broadcast-btn white-fg" ngbDropdownItem (click)="stopBroadcastByAdmin()">Stop
            Broadcast</button>
          <button class="montserrat-font broadcast-btn white-fg" ngbDropdownItem (click)="closeSessionByAdmin()">End
            Event</button>
        </div>
      </div>
    </div>

    <!-- Leave Event Button -->
    <div *ngIf="isAudienceOrSpeaker" id="openLeaveEventModel" (click)="leaveEventDialog = !leaveEventDialog">
      <a class="montserrat-font broadcast-btn white-fg">Leave Event</a>
    </div>

    <!--Audience to Speaker-->
    <div *ngIf="isAudience" id="openLeaveEventModel">
      <a class="montserrat-font broadcast-btn white-fg ml-3" (click)="audiencetospeaker()">Request to be Speaker</a>
    </div>

    <!-- This dialog will render based on condition & it depend on respective parent dialog -->
    <!--
    <div class="ml-2" (click)="openReportUserDialog = !openReportUserDialog">
      <a class="montserrat-font broadcast-btn white-fg">Report User</a>
    </div>
    <div class="ml-2" (click)="admitSpeakerDialog = !admitSpeakerDialog">
      <a class="montserrat-font broadcast-btn white-fg">Admit Speaker</a>
    </div>
    <div class="ml-2" (click)="removeSpeakerDialog = !removeSpeakerDialog">
      <a class="montserrat-font broadcast-btn white-fg">Remove</a>
    </div>
    <div class="ml-2" (click)="admitSpeakerDialog = !admitSpeakerDialog">
      <a class="montserrat-font broadcast-btn white-fg">Thank</a>
    </div> -->
  </div>
  <!-- column 3 ( right side ) -->
  <div class="column3 d-flex w-auto">
    <div class="dark md-show-click ">
      <a style="position: relative;" [matMenuTriggerFor]="Thumblike">
        <img src="../../../../assets/images/white-thumbs-up.svg" alt="Thumbs Up" class="img-fluid" />
      </a>
      <mat-menu #Thumblike="matMenu" class="thumb-like" yPosition="above">
        <!-- <button mat-menu-item>Item 1</button>
                <button mat-menu-item>Item 2</button> -->
        <a>
          <div class="thumb-box-layout">
            <img alt="Thumbs Up" class="img-fluid" src="assets/images/thumbs-up.png">
          </div>
          <p>
            Like
          </p>
        </a>
        <a>
          <div class="thumb-box-layout">
            <img alt="Thumbs Up" class="img-fluid" src="assets/images/icon3-heart.png">
          </div>
          <p>
            Love
          </p>
        </a>
        <a>
          <div class="thumb-box-layout">
            <img alt="Thumbs Up" class="img-fluid" src="assets/images/icon2-applause.png">
          </div>
          <p>
            Bravo
          </p>
        </a>
        <a>
          <div class="thumb-box-layout">
            <img alt="Thumbs Up" class="img-fluid" src="assets/images/icon1-diamond.png">
          </div>
          <p>Gem</p>
        </a>
      </mat-menu>

      <!-- <like-vinpm deo class="like-bubble"></like-video> -->
    </div>
    <!-- <div class="dark md-show-click ">
            <a>
              <img src="assets/images/sound.png" alt="sound" class="img-fluid" />
            </a>
          </div> -->
    <div class="dark md-show-click" (click)="clickMenu()">
      <a class="">
        <img src="../../../../assets/images/message.svg" alt="Message" class="img-fluid" />
      </a>
    </div>
    <div *ngIf="isAudience" class="dark md-show-click " (click)="connectDialog = !connectDialog">
      <a style="position: relative;">
        <!-- <span class="counts white-fg montserrat-font font-12 count-aud">8</span> -->
        <img src="../../../../assets/images/exchange-people.svg" alt="Exchange People" class="img-fluid" />
      </a>
    </div>

  </div>
</div>

<!-- Second Footer Bar -->
<div *ngIf="currentEvent" class="main-footer-layout">
  <div class="d-flex justify-content-between align-center sponsor-wrapper">
    <div class="d-flex align-items-center" style="width: 50%;">
      <p class="mr-2 mb-0 ml-0 font-14 line-height-20 grotesk-regular">
        This event is brought to you by
      </p>
      <div class="max-small-icon">
        <!-- <img [src]="currentEvent?.ChannelIcon" [alt]="currentEvent?.ChannaleName" class="img-fluid" /> -->
        <app-avatar-photo *ngIf="currentEvent !== undefined" [photoUrl]="currentEvent?.ChannelIcon"
          [name]="currentEvent?.ChannaleName" round="false" class="avtar-listing-icon"></app-avatar-photo>
      </div>
    </div>
    <!-- <div class="d-flex align-items-center justify-center">
      <p class="gap-0 mb-0 font-14 line-height-20 grotesk-regular">
        This event is sponsored by
      </p>
      <div *ngFor="let item of currentEvent?.SponsorsList" class="max-small-icon ml-2">
        <img [src]="item?.SponsorsImage" class="img-fluid" />
      </div>
    </div> -->
    <div class="d-flex align-items-center justify-end">
      <p class="mr-2 mb-0 font-14 line-height-20 grotesk-regular">
        Powered by
      </p>
      <img class="" src="../../../../assets/images/logo-black1.svg" alt="Logo" aria-colcount="img-fluid" />
    </div>
  </div>
</div>
<!-- End -->



<!-- Modal Design List -->
<app-dialog [(visible)]="showEventInfoDialog" [dialogClass]="'popup-width-737'">
  <event-information-dialog *ngIf="currentEvent" [currentEvent]="currentEvent"></event-information-dialog>
</app-dialog>

<app-dialog [(visible)]="askQuestionDialog" [dialogClass]="'popup-width-485'">
  <ask-question-dialog (closeaskQuestionDialog)="askQuestiondialogClose()"></ask-question-dialog>
</app-dialog>

<app-dialog [(visible)]="leaveEventDialog" [dialogClass]="'popup-width-618'">
  <app-leave-event-dialog (closeLeaveEventDialog)="closeLeaveEventDialog()"
    (closeLeaveEventDialogBack)="closeLeaveEventDialogClose()"></app-leave-event-dialog>
</app-dialog>

<app-dialog [(visible)]="connectDialog" [dialogClass]="'popup-width-437'">
  <app-connect-dialog (connectRequestDialogc)="connectRequestClose()"></app-connect-dialog>
</app-dialog>

<app-dialog [(visible)]="joinRequestDialog" [dialogClass]="'popup-width-618'">
  <join-event-request-dialog (joinRequestDialog)="joinrequestdialog()" (joinRequestDialogc)="joinrequestdialogc()">
  </join-event-request-dialog>
</app-dialog>

<app-dialog [(visible)]="openReportUserDialog" [dialogClass]="'popup-width-420'">
  <app-report-user-dialog></app-report-user-dialog>
</app-dialog>

<app-dialog [(visible)]="openreportuserlist" [dialogClass]="'popup-width-420'">
  <app-report-user-list></app-report-user-list>
</app-dialog>

<app-dialog [(visible)]="shareyourscreen" [dialogClass]="'popup-width-618'">
  <app-shareyourscreen (sharescreen)="sharescreenclose()"></app-shareyourscreen>
</app-dialog>

<app-dialog [(visible)]="screensharepermission" [dialogClass]="'popup-width-618'" [closable]="true">
  <app-screensharepermission></app-screensharepermission>
</app-dialog>

<app-dialog [(visible)]="openJoinSpeakerDialog" [dialogClass]="'popup-width-618'">
  <app-join-speaker-dialog></app-join-speaker-dialog>
</app-dialog>

<app-dialog [(visible)]="digitalGoodiesDialog" [dialogClass]="'popup-width-420'">
  <app-digital-goodie-bag-dialog></app-digital-goodie-bag-dialog>
</app-dialog>

<app-dialog [(visible)]="admitSpeakerDialog" [dialogClass]="'popup-width-536'">
  <app-admit-speaker-dialog></app-admit-speaker-dialog>
</app-dialog>
<!-- <app-dialog [(visible)]="removeSpeakerDialog" [dialogClass]="'popup-width-618'">
  <app-remove-speaker-dialog [currentEvent]="currentEvent"></app-remove-speaker-dialog>
</app-dialog> -->

<!-- End -->
