import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioVideoSettingsDialogComponent } from './audio-video-permission-dialog/audio-video-settings-dialog.component';
import { EventInformationComponent } from './event-information-dialog/event-information-dialog.component';
import { AskQuestionComponent } from './ask-question-dialog/ask-question-dialog.component';
import { LeaveEventComponent } from './leave-event-dialog/leave-event-dialog.component';
import { ConnectComponent } from './connect-dialog/connect-dialog.component';
import { JoinEventRequestComponent } from './join-event-request-dialog/join-event-request-dialog.component';
import { FeedbackComponent } from './feedback-dialog/feedback-dialog.component';
import { ReportUserComponent } from './report-user-dialog/report-user-dialog.component';
import { HowToJoinComponent } from './join-speaker-dialog/join-speaker-dialog.component';
import { GiftDownloadComponent } from './digital-goodie-bag-dialog/digital-goodie-bag-dialog.component';
import { AdmitSpeakerComponent } from './admit-speaker-dialog/admit-speaker-dialog.component';
import { RemoveSpeakerComponent } from './remove-speaker-dialog/remove-speaker-dialog.component';
import { ConnectingDialogComponent } from './connecting-dialog/connecting-dialog.component';
import { SharedModule } from '../../../shared/shared.module';
import { SessionCompleteDialogComponent } from './session-complete-dialog/session-complete-dialog.component';
import { SessionConfirmDialogComponent } from './session-confirm-dialog/session-confirm-dialog.component';
import { NotificationsDialogComponent } from './notifications-dialog/notifications-dialog.component';
import { OptionVoteListDialogueComponent } from '../waiting-screen/option-vote-list-dialogue/option-vote-list-dialogue.component';
import { ShareyourscreenComponent } from './shareyourscreen/shareyourscreen.component';
import { ScreensharepermissionComponent } from './screensharepermission/screensharepermission.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ReportUserListComponent } from './report-user-list/report-user-list.component';
import { ScreensharedeclineComponent } from './screen-share-decline/screensharedecline.component';
import { EventNotStartedDialogComponent } from './event-not-started-dialog/event-not-started-dialog.component';
import { CameraInformationDialogComponent } from './camera-information-dialog/camera-information-dialog.component';

@NgModule({
  declarations: [
    AudioVideoSettingsDialogComponent,
    EventInformationComponent,
    AskQuestionComponent,
    LeaveEventComponent,
    ConnectComponent,
    JoinEventRequestComponent,
    FeedbackComponent,
    ReportUserComponent,
    HowToJoinComponent,
    GiftDownloadComponent,
    AdmitSpeakerComponent,
    RemoveSpeakerComponent,
    ConnectingDialogComponent,
    SessionCompleteDialogComponent,
    SessionConfirmDialogComponent,
    NotificationsDialogComponent,
    OptionVoteListDialogueComponent,
    ShareyourscreenComponent,
    ScreensharepermissionComponent,
    ReportUserListComponent,
    ScreensharedeclineComponent,
    EventNotStartedDialogComponent,
    CameraInformationDialogComponent
  ],
  exports: [
    AudioVideoSettingsDialogComponent,
    EventInformationComponent,
    AskQuestionComponent,
    LeaveEventComponent,
    ConnectComponent,
    JoinEventRequestComponent,
    FeedbackComponent,
    ReportUserComponent,
    HowToJoinComponent,
    GiftDownloadComponent,
    AdmitSpeakerComponent,
    RemoveSpeakerComponent,
    ConnectingDialogComponent,
    SessionCompleteDialogComponent,
    SessionConfirmDialogComponent,
    NotificationsDialogComponent,
    OptionVoteListDialogueComponent,
    ShareyourscreenComponent,
    ScreensharepermissionComponent,
    ReportUserListComponent,
    ScreensharedeclineComponent,
    EventNotStartedDialogComponent,
    CameraInformationDialogComponent
  ],
  imports: [
    SharedModule,
    CarouselModule.forRoot(),
  ]
})
export class PopupsModule { }
