import { Component, Input, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { Observable } from 'rxjs';
import { GeneralService } from 'src/app/shared/services';

declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'event-information-dialog',
  templateUrl: './event-information-dialog.component.html',
  styleUrls: ['./event-information-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class EventInformationComponent implements OnInit, OnDestroy {
  @Input() currentEvent: any;
  eventsetting: any;
  settingsEnabled: any[] = [];
  settings: any;
  // isDocumentsAndNotesEnabled: boolean = false;
  // settingsdata=this.generalService.settingsdata$.asObservable()

  constructor(
    public generalService: GeneralService
  ) { }


  ngOnInit() {
    // this.isDocumentsAndNotesEnabled = this.currentEvent.EventSettings.DisplayDocumentsAndNotes
    console.log('EventInformationComponent', this.currentEvent);
    this.eventsetting = {
      isSpeakersEnabled: this.currentEvent.EventSettings.DisplayViewersto_Speakers,
      isModeratorsEnabled: this.currentEvent.EventSettings.DisplayViewersto_Moderator,
      isPartnersEnabled: this.currentEvent.EventSettings.DisplayPartners,
      isSponsorsEnabled: this.currentEvent.EventSettings.DisplaySponsors,
    }
    Object.assign(this.eventsetting, this.currentEvent.EventSettings);
    this.generalService.settingsdata$.subscribe(async data => {
      if (data) {
        this.eventsetting = await data;
        Object.assign(this.eventsetting, this.currentEvent.EventSettings);
      }
    })
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  ngOnDestroy(): void {
    this.generalService.settingsdata$.unsubscribe();
  }

}
