<div class="modal-header">
  <h4 class="pull-left">Adjust Audio and Video Settings</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p class="grotesk-medium font-14 line-height-20 color-black mb-3">
        Discussion Box would love to use your camera and microphone
      </p>
    </div>
    <div class="col-lg-7 col-md-12 col-sm-12">
      <div class="preview-aud-video1" id="preview-aud-video1">
        <p class="no-preview" style="text-align:center; color:white" *ngIf="!isCameraSelected">Preview Unavailable</p>
        <!-- <div class="joinspeaker-layout1" id="joinspeaker-layout1">
        </div> -->
      </div>

      <div class="progress mt-2" id="audio-meter">
        <progress class="progress-bar" role="progressbar" [value]="logLevel" style="width: 100%;"></progress>
        <!-- <div id="audio-meter"><label for="audio-meter">Audio Level:</label>
          <meter [value]="logLevel" min="0" max="1"></meter>
        </div> -->
      </div>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12 pl-4 mob-p-0">
      <div class="mb-4">
        <p class="mb-2 gibson_medium line-height-14 font-14 color-black">
          Camera
        </p>
        <select class="form-control" (change)="displaypreview()" style="cursor: pointer;">
          <option value="" disabled selected>Select Camera</option>
          <option value="" *ngIf="videoinputs ==''">No Camera Selected</option>
          <option *ngFor="let camera of videoinputs">{{camera.label}}</option>
        </select>
      </div>
      <div class="mb-2">
        <p class="mb-2 gibson_medium line-height-14 font-14 color-black">
          Microphone
        </p>
        <select class="form-control" style="cursor: pointer;">
          <option value="" disabled selected>Select Microphone</option>
          <!-- <option *ngIf="audioInputs==''">No mic Selected</option> -->
          <option *ngFor="let mic of audioInputs">{{mic.label}}</option>
        </select>
      </div>
      <div class="mb-4" style="cursor: pointer;">
        <a class=" gibson-book-font font-14 line-height-14 color-primary" (click)="setupaudiometer()">
          <img src="assets/images/micro-blue.png" alt="Microphone" class="img-fluid mr-2">
          Test Microphone
          <!-- <img src="assets/images/menu-icon-outline.png" alt="Menu" class="img-fluid ml-2"> -->
        </a>
      </div>
      <div class="mb-1">
        <p class="mb-2 gibson_medium line-height-14 font-14 color-black">
          Audio Output
        </p>
        <select class="form-control" style="cursor: pointer;">
          <option value="" disabled selected>Select Speaker</option>
          <!-- <option *ngIf="audiooutputs=='' ">No Speaker Selected</option> -->
          <option *ngFor="let speaker of audiooutputs">{{speaker.label}}</option>
          <!-- <option>option 2</option>
          <option>option 3</option> -->
        </select>
      </div>
      <div class="audio-rec">
        <audio id="myAudio" #audio src="assets/audiorecording/sample-12s.mp3" (play)="onPlay()" (ended)="onEnded()"></audio>
        <a class="gibson-book-font font-14 line-height-14 color-primary" *ngIf="!isAudioPlaying" (click)="onPlayClick(audio)">
          <img src="assets/images/sound-outline-blue.png" alt="Microphone" class="img-fluid mr-2">
          Test Speaker
        </a>
        <a class="gibson-book-font font-14 line-height-14 color-primary" *ngIf="isAudioPlaying" (click)="onPlayClick(audio)">
          <img src="assets/images/sound-outline-blue.png" alt="Microphone" class="img-fluid mr-2">
         {{playButtonText}}
        </a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="toRedirectToWaitingPage()">Continue</button>
  </div>
