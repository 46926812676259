import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { GeneralService } from 'src/app/shared/services';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-digital-goodie-bag-dialog',
  templateUrl: './digital-goodie-bag-dialog.component.html',
  styleUrls: ['./digital-goodie-bag-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class GiftDownloadComponent implements OnInit {
  currenteventdata: any;

  constructor(
    public generalService: GeneralService
  ) { }

  ngOnInit() {
    this.generalService.cacheCommonForEvent(this.generalService.isEventID).subscribe(data => {
      this.currenteventdata = data.Data;
    })
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }
}
