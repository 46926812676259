import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { RolesEnums } from 'src/app/core/enums';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';

@Component({
  selector: 'ask-question-dialog',
  templateUrl: './ask-question-dialog.component.html',
  styleUrls: ['./ask-question-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})

export class AskQuestionComponent implements OnInit {
  @Output() closeaskQuestionDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  title: string;
  closeBtnName: string;
  list: any[] = [];
  @Input() visible: boolean;
  session: OT.Session;

  submitted: boolean = false;
  isSubmittedQues: boolean = false;
  questionasked: string;
  askQuestionForm: FormGroup;

  constructor(private fb: FormBuilder,
    public generalService: GeneralService,
    public utilityService: UtilityService,
  ) { this.getFormControl(); }

  ngOnInit() {
    this.getFormControl();
  }

  getFormControl() {
    this.askQuestionForm = this.fb.group({
      OpenQuestion: ['', Validators.required],
      EventID: [''],
      MemberID: [''],
    });
  }

  // Getter method to access formcontrols
  get askQuestion() {
    return this.askQuestionForm.controls;
  }

  // Submit question of audience
  OnSubmit() {
    this.submitted = true;
    if (!this.askQuestionForm.valid) {
      return false;
    } else {
      let question = this.askQuestionForm.getRawValue();
      if (this.generalService.isRole === RolesEnums.Roles.Audience) {
        question['MemberID'] = this.generalService.isUserID;
      } else {
        question['MemberID'] = this.generalService.isUserDetails.ID;
      }
      question['EventID'] = this.generalService.isEventID;
      this.generalService.saveAudienceQustion(question).subscribe(async (response: any) => {
        if (response) {
          this.isSubmittedQues = true;
          let credentials: any = await this.utilityService.getSessionConnection();
          this.utilityService.sendSignalToHost(credentials);
        }
        setTimeout(() => {
          if (this.isSubmittedQues) {
            this.questionasked = '';
            this.submitted = false;
            this.isSubmittedQues = false;
            this.closeaskQuestionDialog.emit();
          }
        }, 1000);
      });
    }

  }

}
