<div *ngIf="isShowLoader" class="loader-nav-bar">
  <div class="text-center loader-wrapper2 btn-text btn-loader">
    <div class="loaderSpinner"></div>
  </div>
</div>
<div *ngIf="!isShowLoader" class="Customize-Event-div">
  <div class="customize-heading-div">
    <div class="box">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </div>
    <h5 class="ms-3">Customize Event</h5>
  </div>
  <div class="blank-div"></div>
  <div class="content">
    <h6 class="">Customize Event</h6>
    <p class="para">Make changes to event layout and select
      what your audience is able to see.</p>
    <div class="contents-layout">
      <form class="example-form" [formGroup]="formGroup">

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Live
            Chat</label>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
              formControlName="isLiveChatEnabled" [ngModel]="eventdata?.EventSettings?.AudienceChat" [checked]="eventdata?.EventSettings?.AudienceChat">
          </div>

        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Audience Count</label>
          <div class="form-check form-switch">
            <input formControlName="isAudienceCountEnabled" class="form-check-input" type="checkbox" role="switch"
              id="flexSwitchCheckChecked" [ngModel]="eventdata?.EventSettings?.DisplayPeopleAttendingCount"
              [checked]="eventdata?.EventSettings.DisplayPeopleAttendingCount">
          </div>
        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Sponsors</label>
          <div class="form-check form-switch">
            <input formControlName="isSponsorsEnabled" class="form-check-input"
              [ngModel]="eventdata?.EventSettings?.DisplaySponsors" type="checkbox" role="switch"
              id="flexSwitchCheckChecked" [checked]="eventdata?.EventSettings.DisplaySponsors">
          </div>
        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Q&A by Audience</label>
          <div class="form-check form-switch">
            <input formControlName="isQAEnabled" [ngModel]="eventdata?.EventSettings?.CanAudienceAskQuestion"
              class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
              [checked]="eventdata?.EventSettings.CanAudienceAskQuestion">
          </div>
        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Speakers</label>
          <div class="form-check form-switch">
            <input formControlName="isSpeakersEnabled" [ngModel]="eventdata?.EventSettings?.DisplayViewersto_Speakers"
              class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
              [checked]="eventdata?.EventSettings?.DisplayViewersto_Speakers">
          </div>
        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Moderators</label>
          <div class="form-check form-switch">
            <input formControlName="isModeratorsEnabled"
              [ngModel]="eventdata?.EventSettings?.DisplayViewersto_Moderator" class="form-check-input" type="checkbox"
              role="switch" id="flexSwitchCheckChecked" [checked]="eventdata?.EventSettings.DisplayViewersto_Moderator">
          </div>
        </div>

        <div class="d-flex py-2">
          <label class="form-check-label me-3 $f-14" for="flexSwitchCheckChecked">Display Partners</label>
          <div class="form-check form-switch">
            <input formControlName="isPartnersEnabled" [ngModel]="eventdata?.EventSettings?.DisplayPartners"
              class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
              [checked]="eventdata?.EventSettings.DisplayPartners">
          </div>
        </div>
      </form>
    </div>
    <div class="contents-layout-button">
      <div class="buttons">
        <button class="cancel" (click)="cancel()">
          Cancel
        </button>
        <button class="save" type="submit" (click)="onsave()">
          <span *ngIf="!isShowLoader" class="btn-text text-capitalize">Save</span>
          <span *ngIf="isShowLoader" class="btn-loader">
            Save
            &nbsp;
            <div class="text-center loader-wrapper2 btn-text btn-loader">
              <div class="loaderSpinner"></div>
            </div>
          </span>
          <div class="req-sent-success" *ngIf="showSuccessMsg">
            <img src="../../../../assets/images/green-tick.svg" alt="Tick" class="img-fluid mr-2">
            <p class="mb-0">Settings saved successfully!</p>
          </div>
        </button>
      </div>
    </div>
  </div>


</div>
