import { Component, Input, OnInit } from '@angular/core';
import { Attachments } from '../../../core/enums';
import { UtilityService } from '../../../core/utilities';

@Component({
  selector: 'app-avatar-photo',
  templateUrl: './avatar-photo.component.html',
  styleUrls: ['./avatar-photo.component.scss']
})
export class AvatarPhotoComponent implements OnInit {
  @Input() photoUrl: any;
  @Input() name: any = '';

  showInitials = false;
  initials!: string;
  circleColor!: string;
  avatarURL: any;

  private colors = [
    '#1a09f4', // theme
    // '#468547', // green
    // '#FFD558', // yellow
    // '#3670B2', // blue
  ];
  constructor(private utilityService: UtilityService) { }

  async ngOnInit() {
    if (this.photoUrl == null || undefined) {
      this.showInitials = true;
      this.createInitials();
      const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomIndex];
    } else if (this.photoUrl == '') {
      this.showInitials = true;
      this.createInitials();
      const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomIndex];
    }
    else {
      this.avatarURL = this.photoUrl;
      // let getImgURL: any = await this.utilityService.getAttachments(Attachments.Icon, this.photoUrl);
      // this.avatarURL = getImgURL;
    }
  }

  private async createInitials(): Promise<void> {
    let initials = "";
    let fullName: any = await this.titleCase(this.name);
    for (let i = 0; i < fullName.length; i++) {
      if (fullName.charAt(i) === ' ') {
        continue;
      }
      if (fullName.charAt(i) === fullName.charAt(i).toUpperCase()) {
        initials += fullName.charAt(i);
        if (initials.length == 2) {
          break;
        }
      }
    }
    this.initials = initials;
  }

  async titleCase(str: string) {
    var promise = new Promise((resolve, reject) => {
      var splitStr = str !== null ? str.toLowerCase().split(' ') : [];
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      resolve(splitStr.join(' '));
    });
    return promise;
  }
}
