import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-connecting-dialog',
  templateUrl: './connecting-dialog.component.html',
  styleUrls: ['./connecting-dialog.component.scss']
})
export class ConnectingDialogComponent implements OnInit {

  title: string;
  closeBtnName: string;
  list: any[] = [];
  broadCast:any;
  constructor() {}

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
}
