import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { RolesEnums } from './core/enums';
import { UtilityService } from './core/utilities';
import { AuthService, GeneralService } from './shared/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DBSaaS Video';
  userData: any;
  alljoined: any[] = [];
  isAdhocUser: any[];

  constructor(
    private activeRoute: ActivatedRoute,
    public authService: AuthService,
    public utilityService: UtilityService,
    public generalService: GeneralService
  ) { }

  ngOnInit(): void {
    // To identify this URL for admin/speaker/moderator/audience
    this.activeRoute.queryParams.subscribe(async (queryURL: Params) => {
      console.log("queryURL", queryURL);
      if (this.utilityService.isObjectNotEmpty(queryURL)) {
        let eventID = parseInt(queryURL['eventId']);
        let role = queryURL['role'];
        let userID = parseInt(queryURL['userId']);
        this.generalService.listAllAudience(eventID, userID).subscribe((res: any) => {
          this.alljoined = res.Data.EventMemberList;
          this.isAdhocUser = this.alljoined?.filter(x => x.isAdhoc == true)
        })
        if (role === RolesEnums.Roles.Admin) {
          await this.authService.setItemsIntoLocalStorage(true, eventID, role, userID);
          // Fetching event details based on current event id
          this.getEventDetails(eventID, role, userID);
        } else if (role === RolesEnums.Roles.Audience) {
          await this.authService.setItemsIntoLocalStorage(true, eventID, role, userID);
          await this.generalService.getUserDetail(this.generalService.isUserID, this.generalService.isRole)
          // Fetching event details based on current event id
          this.getEventDetails(eventID, role, userID);
        } else {
          await this.authService.setItemsIntoLocalStorage((this.authService.isLoggedIn ? this.authService.isLoggedIn : false), eventID, role, userID);
          // Fetching event details based on current event id
          this.getEventDetails(eventID, role, userID);
        }
        // // Fetching event details based on current event id
        // this.getEventDetails(eventID, role);


      }
    });

  }

  /**
   * Gets event details
   * @param eventID number
   */
  getEventDetails(eventID: number, role: string, userId: number) {
    this.generalService.cacheCommonForEvent(this.generalService.isEventID)
      .subscribe(async res => {
        console.log("AppComponent", res);
        sessionStorage.setItem('channelID',res.Data.ChennelID);
        this.generalService.isEventExits$.next(true);
        if (role === RolesEnums.Roles.Admin) {
          // Start session for current event using admin role only
          await this.startEventSession(eventID);

        } else if (role === RolesEnums.Roles.Speaker || role === RolesEnums.Roles.Moderator) {
          if (this.isAdhocUser && this.isAdhocUser.length > 0) {
            let isthisAdhocSpeaker = this.isAdhocUser.filter(x => x.MemberID == this.generalService.isUserID);
            if (role === RolesEnums.Roles.Speaker && this.isAdhocUser.length > 0 && isthisAdhocSpeaker.length > 0) {
              await this.generalService.getTokenBasedOnRole(eventID, RolesEnums.RoleType.SpeakerOrModerator);
            }
          } else {
            if (res && this.utilityService.isObjectNotEmpty(res)) {
              // Get credentials for current event using Speaker Or Moderator role only
              // No need Start Session if already started by Admin
              await this.generalService.getTokenBasedOnRole(eventID, RolesEnums.RoleType.SpeakerOrModerator);
            }
          }
          // if (res && res.isStartByAdmin) {
          // if (res && this.utilityService.isObjectNotEmpty(res)) {

        } else if (role === RolesEnums.Roles.Audience) {
          // Get credentials for current event using Audience role only
          // No need Start Session if already started by Admin
          await this.generalService.getTokenBasedOnRole(eventID, RolesEnums.RoleType.Audience);
        }
      });
  }

  /**
   * Starts event session
   * @param eID number
   * @returns
   */
  async startEventSession(eID: number) {
    var promise = new Promise((resolve, reject) => {
      this.generalService.cacheCommonForEventSession(eID).subscribe(async (response: any) => {
        this.generalService.isEventSessionExits$.next(response);
        await this.generalService.getTokenBasedOnRole(eID, RolesEnums.RoleType.Admin);
        resolve('');
      });
    });
    return promise;
  }


}
