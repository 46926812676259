import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarPhotoComponent } from './avatar-photo.component';

@NgModule({
  declarations: [AvatarPhotoComponent],
  exports: [AvatarPhotoComponent],
  imports: [
    CommonModule
  ]
})
export class AvatarPhotoModule { }
