<!-- After Login -->
<!-- Header Layout -->
<div class="main-header-layout background-black height-44">
  <div class="row no-gutters align-items-center height-100 padding-left-right-40">
    <div class="col-5 text-left">
      <h5 class="font-14 line-height-20 color-white mb-0 grotesk-regular">{{currentEvent?.Title}}</h5>
    </div>
    <div class="col-7 text-right">
      <div class="d-flex align-items-center justify-content-end">
        <!-- show run time to speaker/moderator/audience -->
        <!-- <h5 *ngIf="showTimeLeft && !isAdmin" class="font-14 line-height-20 color-white mb-0 grotesk-regular">
          Run Time: {{ runTime }}
        </h5> -->
        <h5 *ngIf="!isAdmin" class="font-14 line-height-20 color-white mb-0 grotesk-regular">
          <!-- Run Time: 02:25:06 -->
          Run Time: {{ runTime }}
        </h5>

        <!-- Show Broadcast status -->
        <!-- <div *ngIf="sessionLive; else offLineStatus" class="live-box color-white font-12 line-height-12 gibson-book-font">
          <span class="live-status"></span>
          LIVE
        </div>
        <ng-template #offLineStatus>
          <div class="live-box color-white font-12 line-height-12 gibson-book-font">
            <span class="offline-status"></span>
            Offline
          </div>
        </ng-template> -->
        <!-- As per figma Live status shown when anyone join event -->
        <div class="live-box color-white font-12 line-height-12 gibson-book-font">
          <div *ngIf="broadcastStarted">
            <span class="live-status"></span>
            <span>LIVE</span>
          </div>
          <div *ngIf="!broadcastStarted">
            <span class="live-status"></span>
            <span>OFFLINE</span>
          </div>
        </div>

        <!-- show time left and progress bar for Admin -->
        <h5 *ngIf="isAdmin" class="font-14 line-height-20 color-white mb-0 grotesk-regular ml-3">
          Time Left
        </h5>
        <div *ngIf="isAdmin" class="progress ml-3">
          <mat-progress-bar mode="determinate" value="{{ progressBarValue }}"></mat-progress-bar>
        </div>

        <h5 *ngIf="isAdmin && showTimeLeft; else noShowTimeLeft"
          class="font-14 line-height-20 color-white mb-0 grotesk-regular ml-3">
          {{ minutesConverted }} Mins
        </h5>
        <ng-template #noShowTimeLeft>
          <h5 *ngIf="isAdmin" class="font-14 line-height-20 color-white mb-0 grotesk-regular ml-3">
            00:00 Mins
          </h5>
        </ng-template>

        <div class="full-screen-layout ml-3 cursor-pointer" (click)="fullScreenVideoPlatForm()">
          <img src="../../../../assets/images/full-screen.svg" alt="Full Screen" class="img-fluid">
        </div>
        <div *ngIf="isAdmin" class="ml-3" (click)="notificationsDialog = !notificationsDialog">
          <img src="assets/images/header-bell.svg" alt="Bell" class="img-fluid">
        </div>
        <!-- <div *ngIf="isAdmin" class="ml-3" (click)="settingsDialog=!settingsDialog">
          <img src="assets/images/header-settings.svg" alt="Settings" class="img-fluid">
        </div> -->
        <div *ngIf="isAdmin||isModerator" class="ml-3" (click)="clickMenu()">
          <a class="">
            <img src="assets/images/header-settings.svg" alt="Settings" class="img-fluid" />
            <!-- <app-settings-users></app-settings-users> -->
          </a>
        </div>
        <div *ngIf="isAdmin || isModerator" class="ml-3"
          (click)="closeSessionByAdminDialog = !closeSessionByAdminDialog">
          <img src="../../../../assets/images/close-md.svg" alt="Close" class="img-fluid" style="cursor: pointer;">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->
<!-- End -->


<app-dialog [(visible)]="closeSessionByAdminDialog" [dialogClass]="'popup-width-618'">
  <app-session-confirm-dialog (callSessionAPIByAdminDialog)="closeSessionByAdmin()"
    (closeSessionByAdminDialog)="closeSessionDialog()">
  </app-session-confirm-dialog>
</app-dialog>

<!-- <app-dialog [(visible)]="settingsDialog" [dialogClass]="'popup-width-618'">
  <app-settings-users (settingsdialogClose)="settingsdialogclose()"></app-settings-users>
</app-dialog> -->