import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/services';

@Component({
  selector: 'app-session-complete-dialog',
  templateUrl: './session-complete-dialog.component.html',
  styleUrls: ['./session-complete-dialog.component.scss']
})
export class SessionCompleteDialogComponent implements OnInit {

  @Output() sessionCompleteDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  discussionCompleted() {
    // this.authService.logout();
    // this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
    this.sessionCompleteDialog.emit();
  }

}
