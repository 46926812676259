import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, GeneralService } from '../../../shared/services';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  isLoggedIn$: Observable<boolean>;
  currentRoute: any;

  constructor(
    public authService: AuthService,
    public generalService: GeneralService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event && event.url) {
        this.currentRoute = event.url.includes("join-event");
      }
    });
    this.isLoggedIn$ = combineLatest([
      this.authService.isLoggedInOrNot,
      this.generalService.isEventExits$.asObservable()])
      .pipe(map(([user, event]) => ((user && event) ? true : false)));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
