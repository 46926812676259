import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../shared/services";
import { RolesEnums } from "../enums";

@Injectable({ providedIn: "root" })
export class LoggedInAuthGuard implements CanActivate {

  constructor(public authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    setTimeout(() => {
      if (this.authService.isLoggedIn) {
        if (next && next.queryParams &&
          (next.queryParams.role !== RolesEnums.Roles.Admin || next.queryParams.role !== RolesEnums.Roles.Audience)) {
          this.router.navigate(['/waiting-screen'], { queryParamsHandling: 'preserve' });
        } else {
          this.router.navigate(['/connected-users'], { queryParamsHandling: 'preserve' });
        }
      }
    }, 1000);
    return true;
  }
}
