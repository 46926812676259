<div>
    <div *ngIf="stepone">
        <div class="customize-heading-div">
            <div class="box">
                <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
                <img src="../../../../../assets/images/arrow.svg">
            </div>
            <h5 class="ms-3">Add-Ons</h5>
        </div>
        <div class="blank-div"></div>
        <div class="content">
            <h6 class="">Add-Ons</h6>
            <p class="para">Running out of time? Pay for extra time here.</p>
            <form>
                <div class="bg-box add-on-box2 mr-2">
                    <div class="row">
                        <div>
                            <div class="f-14 font-weight-500 ">Additional Time for
                                Broadcast</div>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option selected disabled value="">Additional Time for Broadcast</option>
                                <option value="1">1 Hr</option>
                                <option value="2">2 Hr</option>
                                <option value="3">3 Hr</option>
                                <option value="4">4 Hr</option>
                                <option value="5">5 Hr</option>
                            </select>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div>
                            <div class="f-14 mt-2 font-weight-500">Additional Audience</div>
                            <input type="text" class="form-control" placeholder="Additional Audience">
                        </div>
                    </div> -->
                    <div class="contents-layout-button">
                        <form action="">
                            <div class="buttons">
                                <input type="button" class="cancel" value="Cancel" (click)="cancel()">
                                <input type="button" class="save" value="Continue" (click)="savestepone()">
                            </div>
                        </form>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div *ngIf="stepTwo">
        <div class="customize-heading-div">
            <div class="box">
                <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
                <img src="../../../../../assets/images/arrow.svg">
            </div>
            <h5 class="ms-3">Checkout</h5>
        </div>
        <div class="content">
            <div class="table-responsive" style="font-size: 14px;">
                <table>
                    <thead>
                        <tr>
                            <th class="label-dark-title gibson-book-font">Price</th>
                            <th class="label-dark-title text-center gibson-book-font">Cost</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font-weight-600">Additional Time - 2hrs X 2</td>
                            <td style="text-align: center" class="font-weight-600 gibson-book-font"><b>$100</b></td>
                            <td>
                                <img src="../../../../../assets/images/close-black.svg">
                              </td>
                        </tr>
                        <!-- <tr>
                            <td class="font-weight-600">Additional Audience - 2 X 1</td>
                            <td style="text-align: center" class="font-weight-600"><b>$200</b></td>
                            <td>
                                 <img src="../../../../../assets/images/close-black.svg">
                            </td>
                        </tr> -->
                    </tbody>
                    <tfoot class="table">
                        <tr>
                            <td class="f-14 text-right font-weight-600 gibson-book-font"><b>Total</b></td>
                            <td class="f-14 text-center font-weight-600 gibson-book-font">
                                <b>$300</b>
                            </td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="row">
                <div>
                    <div class="f-14 mt-2 font-weight-500">Discount Code</div>
                    <input type="text" class="form-control" placeholder="Discount Code">
                    <button class="primary-color font-weight-600 p-0 bg-transparent border-0"
                    style="">
                    <b>Apply</b>
                  </button>
                </div>
            </div>
        </div>
        <div class="contents-layout-buttonn">
            <form action="">
                <div class="buttons">
                    <input type="button" class="cancel" value="Cancel" (click)="cancelsteptwo()">
                    <input type="button" class="save" value="Checkout" (click)="savesteptwo()">
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="stepthree && !stepTwo">
        <div class="customize-heading-div">
            <div class="box">
                <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
            </div>
            <h5 class="ms-3">Checkout</h5>
            <img style="margin-left: auto;" src="../assets/images/close2.png" (click)="cancel()">
        </div>
        <div class="content-checkout text-align-center">
            <img src="../assets/images/purchase.png">
            <div class="purchasee">Purchase Successful</div>
            <div class="row">
                You’ve successfully purchased an add-on of
                30 minutes time for your event
            </div>
        </div>
    </div>
</div>