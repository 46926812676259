import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class CommanService {

  private sessionStart = new BehaviorSubject(false);
  isSessionStart = this.sessionStart.asObservable();

  private isAudioMute = new BehaviorSubject(false);
  isAudioMuteByAdmin = this.isAudioMute.asObservable();

  public isvideoMute = new BehaviorSubject(false);
  isVideoMuteByAdmin = this.isvideoMute.asObservable();

  public isVideoOffFromStart=new BehaviorSubject(false);
  public isAudioOffFromStart=new BehaviorSubject(false);

  constructor() { }

  changeSession(session: boolean) {
    this.sessionStart.next(session)
  }

  muteAudioByAdmin(isAudio: boolean) {
    this.isAudioMute.next(isAudio);
  }

  muteVideoByAdmin(isVideo: boolean) {
    this.isvideoMute.next(isVideo);
  }

}
