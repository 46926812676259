import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';

@Component({
  selector: 'app-admit-speaker-dialog',
  templateUrl: './admit-speaker-dialog.component.html',
  styleUrls: ['./admit-speaker-dialog.component.scss'],
  providers: [{ provide: ProgressbarConfig }],
  encapsulation: ViewEncapsulation.None
})
export class AdmitSpeakerComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];

  constructor() { }

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
}
