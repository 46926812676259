<div class="modal-header">
  <h4 class="pull-center">Report User</h4>
</div>
<div class="modal-body report-user-body">
  <div class="main-report-layout text-center mb-3">
    <img src="assets/images/report-logo.png" alt="Report">
  </div>
  <div class="radio-button-wrapper1 col-lg-12 col-md-12 col-sm-12 p-0">
    <p class="font-14 line-height-20 grotesk-medium color-black">
      Why do you want to report this user?
    </p>
    <div class="form-group">
      <input type="checkbox" id="speech">
      <label class="gibson-book-font font-14 line-height-14 color-black opacity-0-8" for="speech">Hateful speech</label>
    </div>
    <div class="form-group">
      <input type="checkbox" id="community">
      <label class="gibson-book-font font-14 line-height-14 color-black opacity-0-8" for="community">Refusal to obey
        community guidelines.</label>
    </div>
    <div class="form-group">
      <input type="checkbox" id="refusal">
      <label class="gibson-book-font font-14 line-height-14 color-black opacity-0-8" for="refusal">Refusal to obey
        community guidelines.</label>
    </div>
    <div class="form-group">
      <input type="checkbox" id="abusive">
      <label class="gibson-book-font font-14 line-height-14 color-black opacity-0-8" for="abusive">Abusive
        words.</label>
    </div>
  </div>
  <div class="row">
    <div class="report-btn-group text-center mt-2 mb-2">
      <button type="button" class="btn-border-outline font-14 line-height-24 mr-3 gibson_medium min-width-117">Cancel</button>
      <button type="button" class="btn-common font-14 line-height-24 gibson_medium min-width-117">Report</button>
    </div>
  </div>
</div>

<div class="sucess-alert-user mb-3" *ngIf="isReported">
  <img src="assets/images/right-tick.png" alt="Tick" class="mr-3">
  <p class="font-14 gibson-book-font line-height-24 letter-spacing-0-2 mb-0">You’ve successfully reported this user</p>
</div>