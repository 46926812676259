import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Session } from '@opentok/client';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { UtilityService } from 'src/app/core/utilities';
import { GeneralService } from 'src/app/shared/services';

@Component({
  selector: 'app-shareyourscreen',
  templateUrl: './shareyourscreen.component.html',
  styleUrls: ['./shareyourscreen.component.scss'],
  providers: [{ provide: ProgressbarConfig, }],
  encapsulation: ViewEncapsulation.None
})
export class ShareyourscreenComponent implements OnInit {

  session: OT.Session;
  publisher: OT.Publisher;
  sessiondata: any
  stream: OT.Stream;
  IsscreenShare: boolean = false
  IsHorizontal: any;
  menus: any;
  count = 0;
  IsfullScreen = false;
  broadcast: any;
  speakerData: any;
  speakerdata: any;
  requestSendById: any;
  speakername: any;
  // buttonchecked: boolean = false
  @Output() sharescreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public utilityservice: UtilityService,
    public generalService: GeneralService
  ) { }

  ngOnInit() {

  }
  async adminpermission() {
    const session: any = await this.utilityservice.getSessionConnection();
    let data = {
      Status: 0,
      userId: this.generalService.isUserID,
      eventID: this.generalService.isEventID,
      typeOfRequest: 1
    }
    this.generalService.cacheAudienceRequest(data).subscribe(response => {
      console.log("audience list api data-==--=-=-=", response);
    })
    let details = await this.generalService.getUserDetail(data.userId, 'speaker');
    this.utilityservice.sendSignalToScreenshare(session,details);
    this.sharescreen.emit();
  }

  requestaudio(buttonchecked) {
    if (buttonchecked) {
      this.utilityservice.publisher.publishAudio(true);
    }
  }

  cancel(){
    this.sharescreen.emit();
  }
}
